<template>
    <div class="body-wrap dashboard-page">
        <section class="dashboard">
            <MainSidebar/>

            <div class="dashboard__white_container">
                <div class="dashboard__white_nav">
                    <div class="dashboard__white_nav_left">
                        <div class="dashboard__white_nav_heading">
                            <span>Finance</span>
                        </div>
                        <div class="dashboard__white_nav_subheading">
                            Here you can view and withdraw finances
                        </div>
                    </div>
                    <div class="dashboard__white_nav_right">
                        <div class="dashboard__white_nav_price">
                            <span>Your balance:</span><span>{{ balance?.currentBalance }}$</span>
                        </div>

                        <button
                           class="dashboard__finance_payment_funds_btn"
                           @click="createRequest"
                        >
                            <span>Create payment request</span>
                        </button>
                    </div>
                </div>

                <div class="dashboard__finance_tabs_name">
                    <div
                        class="dashboard__finance_tab_item tab-name"
                        :class="currentTab === 'tab_3' ? 'active' : ''"
                        @click="setTab('tab_3')"
                        data-tab="#tab_3"
                    >
                        <span>Payment history</span>
                    </div>
                    <div
                        class="dashboard__finance_tab_item tab-name"
                        :class="currentTab === 'tab_2' ? 'active' : ''"
                        @click="setTab('tab_2')"
                        data-tab="#tab_2"
                    >
                        <span>Billing details</span>
                    </div>
                </div>

                <div class="dashboard__finance_tabs_content">
                    <div
                        class="dashboard__finance_tabs_conten_item tab-content"
                        :class="currentTab === 'tab_2' ? 'active' : ''"
                        id="tab_2"
                    >
                        <div class="dashboard__billing_top">
                            <div class="dashboard__billing_text_block">
                                <div class="dashboard__billing_text_1">
                                    <span>Billing info</span>
                                </div>
                                <div class="dashboard__billing_text_2">
                                    <span>Update billing details here.</span>
                                </div>
                            </div>
                            <div class="dashboard__billing_btns">
                                <button class="dashboard__billing_btn" @click="$router.go()">
                                    <span>Cancel</span>
                                </button>
                                <button class="dashboard__billing_btn blue" @click.prevent="sendFormData">
                                    <ButtonSpinner v-if="requestLoading" />
                                    <span v-else>Save</span>
                                </button>
                            </div>
                        </div>
                        <div class="dashboard__billing_line">
                            <div class="dashboard__billing_line_text">
                                <span>Fiscal status</span>
                            </div>

                            <div class="dashboard__billing_line_radios">
                                <div class="dashboard__billing_line_radio_btn">
                                    <input
                                        class="checkboxCustom custom-checkbox radio-blue"
                                        id="individual"
                                        type="radio"
                                        name="billing"
                                        value="individual"
                                        v-model="this.formData.type"
                                    />
                                    <label for="individual">Individual</label>
                                </div>
                                <div class="dashboard__billing_line_radio_btn">
                                    <input
                                        class="checkboxCustom custom-checkbox radio-blue"
                                        id="company"
                                        type="radio"
                                        name="billing"
                                        value="company"
                                        v-model="this.formData.type"
                                    />
                                    <label for="company">Company</label>
                                </div>
                            </div>
                        </div>

                        <div class="dashboard__billing_line" v-if="this.formData.type === 'company'">
                            <div class="dashboard__billing_line_text">
                                <span>Company Name</span>
                            </div>
                            <div class="dashboard__billing_line_input">
                                <input
                                    class="your-company"
                                    id="yourCompany"
                                    type="text"
                                    name="your-company"
                                    placeholder="Your company"
                                    v-model="this.formData.companyname"
                                />
<!--                                    :disabled=""-->
                            </div>
                        </div>
                        <div class="dashboard__billing_line" v-if="this.formData.type === 'individual'">
                            <div class="dashboard__billing_line_text"><span>Name</span></div>
                            <div class="dashboard__billing_line_input double">
                                <input
                                    class="first-name"
                                    id="firstName"
                                    type="text"
                                    name="first-name"
                                    placeholder="First Name"
                                    v-model="this.formData.firstname"
                                />
                                <input
                                    class="last-name"
                                    id="LastName"
                                    type="text"
                                    name="last-name"
                                    placeholder="Last Name"
                                    v-model="this.formData.lastname"
                                />
                            </div>
                        </div>

                        <div class="dashboard__billing_line" v-if="this.formData.type === 'company'">
                            <div class="dashboard__billing_line_text">
                                <span>Reg.Number</span>
                            </div>
                            <div class="dashboard__billing_line_input">
                                <input
                                    class="reg-number"
                                    id="regNumber"
                                    type="text"
                                    name="reg-number"
                                    placeholder="Reg.Number"
                                    v-model="this.formData.regnumber"
                                />
                            </div>
                        </div>
                        <div class="dashboard__billing_line" v-if="this.formData.type === 'company'">
                            <div class="dashboard__billing_line_text">
                                <span>VAT Number</span>
                            </div>
                            <div class="dashboard__billing_line_input">
                                <input
                                    class="vat-number"
                                    id="vatNumber"
                                    type="text"
                                    name="vat-number"
                                    placeholder="VAT Number"
                                    v-model="this.formData.vatnumber"
                                />
                            </div>
                        </div>
                        <div class="dashboard__billing_line">
                            <div class="dashboard__billing_line_text">
                                <span>Country</span>
                            </div>
                            <div class="dashboard__billing_line_input">
                                <div class="dashboard__filter_select">
                                    <select id="selectCountry" name="selectCountry" v-model="formData.country">
                                        <option v-for='country in countriesKeys' :key="country" :value="country">
                                            {{ countriesData[country] }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="dashboard__billing_line">
                            <div class="dashboard__billing_line_text">
                                <span>Address</span>
                            </div>
                            <div class="dashboard__billing_line_input">
                                <input
                                    class="reg-number"
                                    id="regNumber"
                                    type="text"
                                    name="reg-number"
                                    placeholder="Reg.Number"
                                    v-model="this.formData.address"
                                />
                            </div>
                        </div>
                        <div class="dashboard__billing_line">
                            <div class="dashboard__billing_line_text">
                                <span>City</span>
                            </div>
                            <div class="dashboard__billing_line_input">
                                <input
                                    class="reg-number"
                                    id="regNumber"
                                    type="text"
                                    name="reg-number"
                                    placeholder="Reg.Number"
                                    v-model="this.formData.city"
                                />
                            </div>
                        </div>
                        <div class="dashboard__billing_line">
                            <div class="dashboard__billing_line_text">
                                <span>Phone</span>
                            </div>
                            <div class="dashboard__billing_line_input">
                                <input
                                    class="reg-number"
                                    id="regNumber"
                                    type="text"
                                    name="reg-number"
                                    placeholder="Reg.Number"
                                    v-model="this.formData.phone"
                                />
                            </div>
                        </div>
                        <div class="dashboard__billing_line">
                            <div class="dashboard__billing_line_text">
                                <span>Email</span>
                            </div>
                            <div class="dashboard__billing_line_input">
                                <input
                                    class="reg-number"
                                    id="regNumber"
                                    type="text"
                                    name="reg-number"
                                    placeholder="Reg.Number"
                                    v-model="this.formData.email"
                                />
                            </div>
                        </div>

                        <div class="dashboard__billing_line">
                            <div class="dashboard__billing_line_text">
                                <span>Payment methods</span>
                            </div>

                            <div>
                                <div class="dashboard__billing_line_radios">
                                    <div class="dashboard__billing_line_radio_btn"
                                         v-for="(typeTitle, typeName) in wallets.walletTypes"
                                         v-bind:key="typeName"
                                    >
                                        <input
                                            class="checkboxCustom custom-checkbox radio-blue"
                                            type="radio"
                                            :id="typeName"
                                            :value="typeName"
                                            @change="() => walletsFormData.walletDetails = wallets.userWallets[typeName]"
                                            v-model="walletsFormData.walletType"
                                        />
                                        <label :for="typeName">
                                            {{typeTitle}}
                                        </label>
                                    </div>
                                </div>

                                <div class="dashboard__billing_line_input mt-1">
                                    <input
                                        class="reg-number"
                                        id="regNumber"
                                        type="text"
                                        name="reg-number"
                                        placeholder="Payment hash"
                                        v-model="walletsFormData.walletDetails"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="dashboard__create_item_btns">
                            <button class="dashboard__billing_btn" @click="$router.go()">
                                <span>Cancel</span>
                            </button>
                            <button class="dashboard__billing_btn blue" @click.prevent="sendFormData">
                                <ButtonSpinner v-if="requestLoading" />
                                <span v-else>Save</span>
                            </button>
                        </div>

                    </div>

                    <div
                        class="dashboard__finance_tabs_conten_item tab-content"
                        :class="currentTab === 'tab_3' ? 'active' : ''"
                        id="tab_3"
                    >

                        <template v-if="paymentsList.length === 0">
                            <div class="dashboard__no_matches">
                                <div class="dashboard__no_matches_icon"><img src="img/statistic/nomatches.svg" alt=""></div>

                                <div class="dashboard__no_matches_heading"><span>No payments found</span></div>
                            </div>
                        </template>

                        <template v-else>
                            <div class="dashboard__finance_payment_heading">
                                <span>Payments request list</span>
                            </div>
                            <div class="dashboard__finance_items">
                                <div class="dashboard__finance_item name">
                                    <div class="dashboard__finance_text_name">
                                        <span>DATE</span>
                                    </div>
                                    <div class="dashboard__finance_text_name">
                                        <span>AMOUNT</span>
                                    </div>
                                    <div class="dashboard__finance_text_name">
                                        <span>STATUS</span>
                                    </div>
                                    <div class="dashboard__finance_text_name">
                                        <span>INFORMATION</span>
                                    </div>
                                </div>

                                <div class="dashboard__finance_item"
                                     v-for="payment in paymentsList"
                                     v-bind:key="payment?.paymentInfo"
                                >
                                    <div class="payment_text">
                                        <span>{{ payment?.date }}</span>
                                    </div>
                                    <div class="payment_text"><span>{{ payment?.amount }}$</span></div>
                                    <div class="payment_text"><span>{{ payment?.status }}</span></div>
                                    <div class="payment_text info copy">
                                        <button @click="e => copyInfo(e.target.innerText)">
                                            <span>{{ payment?.paymentInfo }}</span>
                                            <span>{{ payment?.operationId }}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </template>

                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import MainSidebar from "../../../components/Sidebar/MainSidebar.vue";
import {mapState} from "vuex";
import ButtonSpinner from "@/components/UI/ButtonSpinner.vue";
import {COUNTRIES} from "@/Constants";

export default {
    components: {
        ButtonSpinner,
        MainSidebar
    },
    data() {
        return {
            copied: false,

            currentTab: "tab_3",

            requestLoading: false,

            walletsFormData: {
                walletType: "paytype_2",
                walletDetails: '2myhash'
            },

            formData: {
                type: "",
                companyname: "",
                regnumber: "",
                vatnumber: "",
                country: "",
                address: "",
                city: "",
                email: "",
                phone: "",
                company_firstname: "",
                company_lastname: "",
                firstname: "",
                lastname: "",
            }
        }
    },

    computed: {
        ...mapState({
            balance: state => state.balance.balance,
            // paymentsList
            paymentsList: state => state.finances.paymentsList,
            // billingdetails
            billingdetails: state => state.billingdetails.billingdetails,
            // wallets
            wallets: state => state.finances.wallets,
        }),

        countriesData() {
            return COUNTRIES;
        },

        countriesKeys() {
            return Object.keys(COUNTRIES)
        },
    },

    methods: {
        setTab(tabName) {
            this.currentTab = tabName;
        },

        setFormdata(responseData) {
            this.formData = {
                address: responseData?.address,
                city: responseData?.city,
                company_firstname: responseData?.company_firstname,
                company_lastname: responseData?.company_lastname,
                companyname: responseData?.companyname,
                country: responseData?.country,
                email: responseData?.email,
                firstname: responseData?.firstname,
                lastname: responseData?.lastname,
                phone: responseData?.phone,
                regnumber: responseData?.regnumber,
                type: responseData?.type,
                vatnumber: responseData?.vatnumber,
            }
        },

        sendFormData() {
            this.requestLoading = true;

            this.setWallets();

            this.$store.dispatch('updateBillingdetails', {
                data: this.formData
            }).then(() => {
                setTimeout(() => {
                    this.requestLoading = false;
                }, 1000)
            })
        },

        createRequest() {
            this.$store.dispatch('createPaymentRequest')
        },

        setWallets() {
            this.$store.dispatch('setWallet', {data: this.walletsFormData});
        },

        copyInfo(copyText) {
            // Copy the text inside the text field
            navigator.clipboard.writeText(copyText);

            this.copied = true;
            setTimeout(() => { this.copied = false; }, 1000)
        },
    },

    created() {
        this.$store.dispatch('getBalance')

        this.$store.dispatch('getPaymentsList');
        this.$store.dispatch('getBillingdetails').then(response => {
            this.setFormdata(response)
        });

        this.$store.dispatch('getWallets');

        // this.setWallets();
    }
};
</script>

<style scoped lang="scss">
.dashboard-page {
    background: #89123e;
}

#tab_1 {
    .dashboard__billing_line {
        border-bottom: 0;
        padding-bottom: 0.875em;

        &_input {
            margin-left: 0;
            max-width: 36.375em;
        }
    }
}


.disabled {
    pointer-events: none;
    cursor: auto;
    filter: grayscale(1);
}

.dashboard__create_item_btns {
    margin-top: 2em;
}

.dashboard__finance_tabs_content {
    margin-top: 2em;
}

.dashboard__white_nav_right .dashboard__white_nav_price {
    width: auto;
    min-width: auto;
    margin-right: 1em;
}

.dashboard__finance_items {
    width: 100%;
}

.dashboard__finance_item {

    .payment_text {
        width: 16.625em;
        padding-left: 2em;
    }
}

.mt-1 {
    margin-top: 1em;
}

.payment_text {
    position: relative;

    &.copy {
        color: #175CD3;
        font-weight: 600;

        button {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            position: relative;
            background: transparent;
            transition: opacity .1s ease-in-out;
            cursor: pointer;
            text-align: left;
            padding: 0;

            &:active {
                opacity: .4;
            }
        }
    }
}
</style>
