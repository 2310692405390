import { createWebHashHistory, createRouter } from "vue-router";

import Error_404 from "@/views/Error_404/Error_404";

import LoginPage from "@/views/LoginPage/LoginPage";
import ForgotPasswordPage from "@/views/ForgotPasswordPage/ForgotPasswordPage";
import RegistrationPage from "@/views/RegistrationPage/RegistrationPage";
import HomeWrapper from "@/views/Home/HomeWrapper";

import Dashboard from "@/views/Dashboard/Dashboard";
import Statistic from "@/views/Statistic/Statistic";
import Finance from "@/views/Finance/Finance";
import TrafficChart from "@/views/TrafficChart/TrafficChart";
import AdvertisingCampaigns from "@/views/AdvertisingCampaigns/AdvertisingCampaigns";
import AdvertisingCampaignsNew from "@/views/AdvertisingCampaignsNew/AdvertisingCampaignsNew";
import AdvertisingCampaign from "@/views/AdvertisingCampaign/AdvertisingCampaign";
import ProfileSettings from "@/views/ProfileSettings/ProfileSettings";
import Support from "@/views/Support/Support";
import Terms from "@/views/Terms/Terms";
import PrivacyPolicy from "@/views/PrivacyPolicy/PrivacyPolicy";
import Referral from "@/views/Referral/Referral";
import Sites from "@/views/Sites/Sites";
import SitesNew from "@/views/SitesNew/SitesNew";
import Site from "@/views/Site/Site";

const routes = [
  {
    path: "/404",
    name: "Error_404",
    component: Error_404,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/",
    name: "Home",
    component: HomeWrapper,
    meta: {
      requiresAuth: false
    }
  },

  {
    path: "/login",
    name: "Login",
    component: LoginPage,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/forgot",
    name: "ForgotPassword",
    component: ForgotPasswordPage,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/registration",
    name: "Registration",
    component: RegistrationPage,
    meta: {
      requiresAuth: false
    }
  },

  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/statistic",
    name: "Statistic",
    component: Statistic,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/finance",
    name: "Finance",
    component: Finance,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/traffic-chart",
    name: "TrafficChart",
    component: TrafficChart,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/advertising-campaigns",
    name: "AdvertisingCampaigns",
    component: AdvertisingCampaigns,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/advertising-campaigns/new",
    name: "AdvertisingCampaignsNew",
    component: AdvertisingCampaignsNew,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/advertising-campaign/:id",
    name: "AdvertisingCampaign",
    component: AdvertisingCampaign,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/profile-settings",
    name: "ProfileSettings",
    component: ProfileSettings,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/support",
    name: "Support",
    component: Support,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/referral",
    name: "Referral",
    component: Referral,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/sites",
    name: "Sites",
    component: Sites,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/sites/new",
    name: "SitesNew",
    component: SitesNew,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/site/:id",
    name: "Site",
    component: Site,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/terms",
    name: "Terms",
    component: Terms,
    meta: {
      requiresAuth: false
    }
  },
  { path: '/:catchAll(.*)', component: Error_404, hidden: true }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (localStorage.getItem('auth') === null) {
      next({ path: '/login' })
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
})

export default router;
