import sendRequest from '../../sendRequest'

export default {
    actions: {
        sendFeedback(context, {data}) {
            sendRequest({method: 'post', endpoint: `/feedback`, data}).then(response => {
                console.log(response.data, data)
            })
        },
    }
}
