<template>
    <div class="date-time-picker" :class="open && 'open'">
        <DTPHeader
            @all="selectedValuesFilter = 'all'"
            @weekends="selectedValuesFilter = 'weekends'"
            @work="selectedValuesFilter = 'work'"
            @business="selectedValuesFilter = 'businessHours'"
            @clear="selectedValuesFilter = 'clear'"
        />

        <div class="date-time-picker__container">
            <DTPDays />

            <DTPDayNumbers
                :defaultValues="defaultValues"
                :selectedValuesFilter="selectedValuesFilter"
                @change="onChange"
            />

            <div></div>
        </div>

        <DTPFooter
            @cancel="cancel"
            @apply="apply"
        />
    </div>
</template>

<script>
import DTPHeader from "./components/DTPHeader.vue";
import DTPDays from "./components/DTPDays.vue";
import DTPDayNumbers from "./components/DTPDayNumbers.vue";
import DTPFooter from "./components/DTPFooter.vue";

export default {
    name: 'DateTimePicker',
    components: {
        DTPHeader,
        DTPDays,
        DTPDayNumbers,
        DTPFooter
    },

    props: [
        'open',
        'defaultValues'
    ],

    data() {
        return {
            selectedValues: [],
            selectedValuesFilter: '',
        }
    },

    methods: {
        onChange(values) {
            this.selectedValues = [...values];
        },

        cancel() {
            this.$emit('cancel')
        },

        apply() {
            this.$emit('apply', this.selectedValues)
            this.cancel();
        }
    },
}
</script>

<style lang="scss" scoped>
.date-time-picker {
    position: absolute;
    top: 0;
    right: 0;
    width: 60em;
    background: #FFFFFF;
    box-shadow: 0px 1.5em 2em -.5em rgba(16, 24, 40, 0.08), 0px .7em .7em -.5em rgba(16, 24, 40, 0.03);
    border-radius: 10.6667px;
    z-index: 999;
    border: .1em solid #EAECF0;
    pointer-events: none;
    opacity: 0;
    transition: opacity .2s ease-in-out;

    &.open {
        opacity: 1;
        pointer-events: auto;
    }

    &__container {
        display: flex;
        border-bottom: .1em solid #EAECF0;
    }

    @media(max-width: 1024px) and (orientation: portrait) {
        width: 100%;
        max-width: 100%;
        max-height: 50em;
        overflow: auto;
        top: -.5em;

        &__container {
            flex-direction: column;
        }
    }
}
</style>
