<template>
    <div class="body-wrap dashboard-page">
        <section class="dashboard">
            <MainSidebar/>


            <div class="dashboard__white_container">
                <div class="dashboard__white_nav">
                    <div class="dashboard__white_nav_left refferal">
                        <div class="dashboard__white_nav_heading"><span>Referral Program</span></div>
                        <div class="dashboard__white_nav_subheading" v-if="!userDataLoading"><span>For each referred affiliate, get {{ user.referal_rate }}% of their monthly income at the beginning of the month</span>
                        </div>
                    </div>
                    <div class="dashboard__white_nav_right refferal" v-if="!userDataLoading">
                        <div class="dashboard__white_nav_price">
                            <span>Your balance:</span>
                            <span>{{ balance?.currentBalance }}$</span>
                        </div>
                    </div>
                </div>

                <DataLoader v-if="userDataLoading" />

                <div class="dashboard__create_campaign" v-else>
                    <div class="dashboard__create_item reff-link">
                        <div class="dashboard__create_item_name"><span>Use the referral link</span></div>
                        <div class="dashboard__profile_settings_link" @click.prevent="copyLink">
                            <input class="reff-link" id="reffLink" type="text" ref="refRefferal" name="reff-link"
                                   placeholder="Enter your link" :value="user.referal_link">


                            <CopiedModal
                                :active="copiedLink"
                                message="Link copied"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import MainSidebar from "../../components/Sidebar/MainSidebar.vue";
import CopiedModal from "@/components/UI/CopiedModal.vue";
import DataLoader from "@/components/UI/DataLoader.vue";

export default {
    components: {
        DataLoader,
        MainSidebar,
        CopiedModal
    },

    data() {
        return {
            userDataLoading: true,
            copiedLink: false,
        };
    },

    computed: {
        ...mapState({
            user: state => state.user.user,
            // balance
            balance: state => state.balance.balance,
        }),
    },

    methods: {
        copyLink() {
            let copyText = this.$refs.refRefferal;

            // Select the text field
            copyText.select();
            copyText.setSelectionRange(0, 99999); // For mobile devices

            // Copy the text inside the text field
            navigator.clipboard.writeText(copyText.value);

            this.copiedLink = true;

            setTimeout(() => {
                this.copiedLink = false;
            }, 1000)
        },
    },

    created() {
        const $this = this;
        Promise.all([
            $this.$store.dispatch('getUser'),
            $this.$store.dispatch('getBalance'),
        ]).then(() => {
            setTimeout(() => {
                $this.userDataLoading = false;
            }, 1000)
        })
    },
};
</script>

<style scoped lang="scss">
.dashboard-page {
    background: #89123e;
}

.dashboard__profile_settings_password_text {
    li.active {
        color: #28c700;
    }
}

.dashboard__billing_btn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.dashboard__profile_settings_link {
    cursor: pointer;

    &::after {
        will-change: transform;
        transform: scale(.9);
        transition: opacity .2s ease-in-out, transform .2s ease-in-out;
    }

    &:hover::after {
        opacity: .8;
        transform: scale(1);
    }

    input {
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
    }
}

.copiedModal {
    background: #101828;
    padding: .73em 1em;
    border-radius: .8em;
    position: absolute;
    right: 0;
    bottom: -2em;
    z-index: 888;
    pointer-events: none;
    transition: opacity .2s ease-in-out;
    opacity: 0;

    &.active {
        opacity: 1;
    }

    span {
        color: #fff;
    }
}
</style>
