<template>
    <div class="sidebar">
        <router-link class="sidebar__logo" to="/dashboard">
            <img src="img/dashboard/logo.svg" alt="" />
        </router-link>
        <div class="sidebar__menu_block_1">
            <div class="sidebar__menu">
                <router-link
                    v-for="(route, index) in routs"
                    :key="route.path"
                    :to="route.path"
                    class="sidebar__menu_item"
                    :class="{ active: $route.fullPath.includes(route.path) }"
                >
                    <img :src="`img/sidebar/item-${index+1}.svg`" alt=""/>
                    <span>{{ route.name }}</span>
                </router-link>
            </div>
        </div>
        <div class="sidebar__menu_block_2">
            <div class="sidebar__menu">
                <router-link
                    v-for="(route, index) in staticRouts"
                    :key="route.path"
                    :to="route.path"
                    class="sidebar__menu_item"
                    :class="{ active: $route.fullPath === route.path }"
                >
                    <img :src="`img/sidebar/item-${index+7}.svg`" alt=""/>
                    <span>{{ route.name }}</span>
                </router-link>
            </div>
            <div class="sidebar__zoom_block">
                <div class="sidebar__zoom_minus" @click="zoomMinus"><img src="img/dashboard/zoom-out.svg" alt=""></div>
                <div class="sidebar__zoom_current_text"><span>UI scale</span><span class="pers">{{ zoom * 100 }}%</span>
                </div>
                <div class="sidebar__zoom_plus" @click="zoomPlus"><img src="img/dashboard/zoom-in.svg" alt=""></div>
            </div>
            <div class="sidebar__logout_block">
                <div class="sidebar__logout_avatar">
                    <img src="img/sidebar/avatar.png" alt=""/>
                </div>
                <div class="sidebar__logout_name">
                    <span>{{ user?.firstName ?? user?.login }}</span>
                </div>
                <button class="sidebar__logout_btn" @click.prevent="exit">
                    <img src="img/sidebar/exit.svg" alt=""/>
                </button>
            </div>
        </div>
        <div class="sidebar__mob_menu">
            <div id="burger" @click='toggleMenu' :class="menuActive && 'open'">
                <span> </span><span class="ultinate"></span><span> </span>
            </div>
            <nav class="nav" id="nav" :class="menuActive && 'nav-open'">
                <div class="nav__menu_block">
                    <div class="sidebar__menu_block_1_mob">
                        <div class="sidebar__balance_mob">
                            <div class="sidebar__balance_mob_text">
                                <span>Your balance:</span><span class="count">$1423</span>
                            </div>
                            <div class="sidebar__balance_mob_btn">
                                <img src="img/dashboard/plus.svg" alt=""/>
                            </div>
                        </div>
                        <div class="sidebar__menu">
                            <router-link
                                v-for="(route, index) in routs"
                                :key="route.path"
                                :to="route.path"
                                class="sidebar__menu_item"
                                :class="{ active: $route.fullPath === route.path }"
                            >
                                <img :src="`img/sidebar/item-${index+1}.svg`" alt=""/>
                                <span>{{ route.name }}</span>
                            </router-link>
                        </div>
                    </div>
                    <div class="sidebar__menu_block_2_mob">
                        <div class="sidebar__menu">
                            <router-link
                                v-for="(route, index) in staticRouts"
                                :key="route.path"
                                :to="route.path"
                                class="sidebar__menu_item"
                                :class="{ active: $route.fullPath === route.path }"
                            >
                                <img :src="`img/sidebar/item-${index+7}.svg`" alt=""/>
                                <span>{{ route.name }}</span>
                            </router-link>
                        </div>
                        <div class="sidebar__logout_block">
                            <div class="sidebar__logout_avatar">
                                <img src="img/sidebar/avatar.png" alt=""/>
                            </div>
                            <div class="sidebar__logout_name">
                                <span>{{ user?.firstName ?? user?.login }}</span>
                            </div>
                            <button class="sidebar__logout_btn" @click.prevent="exit">
                                <img src="img/sidebar/exit.svg" alt=""/>
                            </button>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
    data() {
        return {
            mainRouts: {
                advertiser: [
                    {
                        name: "Dashboard",
                        path: "/dashboard",
                    },
                    {
                        name: "Statistic",
                        path: "/statistic",
                    },
                    {
                        name: "Finance",
                        path: "/finance",
                    },
                    {
                        name: "Traffic Chart",
                        path: "/traffic-chart",
                    },
                    {
                        name: "Advertising campaigns",
                        path: "/advertising-campaigns",
                    },
                    {
                        name: "Profile settings",
                        path: "/profile-settings",
                    },
                ],
                webmaster: [
                    {
                        name: "Statistic",
                        path: "/dashboard",
                    },
                    {
                        name: "Finance",
                        path: "/finance",
                    },
                    {
                        name: "Sites",
                        path: "/sites",
                    },
                    {
                        name: "Referral program",
                        path: "/referral",
                    },
                    {
                        name: "Profile settings",
                        path: "/profile-settings",
                    },
                ],

            },

            staticRouts: [
                // {
                //   name: "Support",
                //   path: "/support",
                // },
                {
                    name: "Terms and conditions",
                    path: "/terms",
                },
                {
                    name: "Privacy policy",
                    path: "/privacy-policy",
                },
            ],

            zoom: 1,

            menuActive: false
        };
    },

    computed: {
        ...mapState({
            user: state => state.user.user
        }),

        routs() {
            return this.mainRouts[this.user?.usertype];
        }
    },

    methods: {
        exit() {
            localStorage.removeItem('auth');
            this.$router.push('/login')
        },
        zoomPlus() {
            this.zoom = (this.zoom > 1) ? this.zoom : this.zoom + .25;

            localStorage.setItem('zoom', this.zoom);
            window.document.querySelector('html').style.zoom = this.zoom
        },

        zoomMinus() {
            this.zoom = (this.zoom < 1) ? this.zoom : this.zoom - .25;

            localStorage.setItem('zoom', this.zoom);
            window.document.querySelector('html').style.zoom = this.zoom
        },

        checkZoomValue() {
            const zoomValue = JSON.parse(localStorage.getItem('zoom'));

            if (zoomValue !== null) {
                this.zoom = zoomValue;
                window.document.querySelector('html').style.zoom = zoomValue;
            }
        },

        toggleMenu() {
            this.menuActive = !this.menuActive;
        }
    },

    created() {
        this.$store.dispatch('getUser');

        this.checkZoomValue();
    }
};
</script>
