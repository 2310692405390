<template>
    <div class="body-wrap dashboard-page">
        <section class="dashboard">

            <MainSidebar/>

            <div class="dashboard__white_container">
                <div class="dashboard__white_nav">
                    <div class="dashboard__white_nav_left">
                        <div class="dashboard__white_nav_heading">
                            <span>Sites</span>
                        </div>
                        <div class="dashboard__white_nav_subheading">
                            <span>Select period and filters to view data</span>
                        </div>
                    </div>
                    <div class="dashboard__white_nav_right">
                        <div class="dashboard__white_nav_price">
                            <span>Your balance:</span>
                            <span>{{ balance?.currentBalance }}$</span>
                        </div>
                    </div>
                </div>
                <div class="dashboard__search_block">
                    <div class="dashboard__search">
                        <input class="search"
                               type="text"
                               name="search"
                               placeholder="Search for ID or name"
                               v-model="searchValue"
                        >
                    </div>
                    <div class="dashboard__filter_block">
                        <router-link to="/sites/new" class="dashboard__date_btn create-campaign">
                            <span>Add new site</span>
                        </router-link>

<!--                        <RangePicker @change="filter"/>-->
                    </div>
                </div>

                <DataLoader v-if="requestLoading" />

                <template v-else>
                    <EmptyMessage v-if="searchFilteredList.length === 0" @clear="initSitesList" />

                    <div class="dashboard__sites" v-else>
                        <div class="dashboard__sites_item">
                            <div class="dashboard__sites_item_nameid name"><span>Site ID/NAME OF SITE</span></div>
                            <div class="dashboard__sites_item_format name"><span>FORMAT</span></div>
<!--                            <div class="dashboard__sites_item_cpc name"><span>CPC</span></div>-->
                            <div class="dashboard__sites_item_status name"><span>STATUS</span></div>
<!--                            <div class="dashboard__sites_item_spent name"><span>SPENT</span></div>-->
                            <div class="dashboard__sites_item_change name"><span>Change</span></div>
                            <div class="dashboard__sites_item_code name"><span>Advertising code</span></div>
                        </div>
                        <div class="dashboard__sites_item" v-for="siteItem in itemsList"
                             :key="siteItem.id">
                            <div class="dashboard__sites_item_nameid">
                                <span class="first">{{ siteItem.id }}</span>
                                <span>{{ siteItem.site }}</span>
                            </div>
                            <div class="dashboard__sites_item_format">
                                <span>{{ siteItem.format }}</span>
                            </div>
                            <div class="dashboard__sites_item_status" :class="{
                                active: siteItem.active === 'active',
                                pause: siteItem.active === 'paused' || siteItem.active === 'moderation',
                                stop: siteItem.active === 'stop',
                            }">
                                <span>{{ siteItem.active }}</span>
                            </div>
                            <div class="dashboard__sites_item_change">
<!--                                <router-link :to="`/site/${siteItem.id}`" class="dashboard__sites_item_change_icon" >-->
<!--                                    <img src="img/advertising-campaigns/edit.svg" alt="">-->
<!--                                </router-link>-->
                                <button class="dashboard__sites_item_change_icon" @click.prevent="editCode(siteItem.id)">
                                    <img src="img/advertising-campaigns/edit.svg" alt="">
                                </button>
<!--                                <div class="dashboard__sites_item_change_icon">-->
<!--                                    <img src="img/advertising-campaigns/edit.svg" alt="">-->
<!--                                </div>-->
<!--                                <div class="dashboard__sites_item_change_icon">-->
<!--                                    <img src="img/advertising-campaigns/edit.svg" alt="">-->
<!--                                </div>-->
                            </div>
                            <div class="dashboard__sites_item_change">
                                <button class="dashboard__pages_btn next" @click.prevent="getCode(siteItem.id)">
                                    <span>Get code</span>
                                </button>
                            </div>
                        </div>

                        <Pagination
                            :currentPage="currentPage"
                            :totalItems="searchFilteredList.length"
                            :pageSize="pageSize"
                            @update="updatePage"
                        />
                    </div>
                </template>

                <Pagination
                    :mobile="true"
                    :currentPage="currentPage"
                    :totalItems="searchFilteredList.length"
                    :pageSize="pageSize"
                    @update="updatePage"
                />
            </div>

            <EditCodeModal
                :open="editCodeModalOpen"
                :codeValue="editCodeValue"
                @cancel="closeEditModal"
                @saveCode="saveCode"
            />

            <GetCodeModal
                :open="getCodeModalOpen"
                :codeValue="codeValue"
                @cancel="closeModal"
            />
        </section>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import MainSidebar from "../../components/Sidebar/MainSidebar.vue";
// import RangePicker from "@/components/UI/RangePicker.vue";
import moment from 'moment';
import Pagination from "@/components/Pagination/Pagination.vue";
import DataLoader from "@/components/UI/DataLoader.vue";
import EmptyMessage from "@/components/UI/EmptyMessage.vue";
import GetCodeModal from "@/components/Modal/GetCodeModal.vue";
import EditCodeModal from "@/components/Modal/EditCodeModal.vue";

export default {
    components: {
        EmptyMessage,
        Pagination,
        // RangePicker,
        MainSidebar,
        DataLoader,
        GetCodeModal,
        EditCodeModal,
    },

    data() {
        return {
            activeFilter: false,
            requestLoading: false,

            currentPage: 1,
            pageSize: 10,

            searchValue: '',

            filterData: {
                from: '',
                to: '',
            },

            editCodeModalOpen: false,
            getCodeModalOpen: false,
            editCodeSiteId: null,
            editCodeValue: '',
            codeValue: '',
        }
    },

    computed: {
        ...mapState({
            // sites
            sitesList: (state) => state.sites.sitesList,
            // user
            user: state => state.user.user,
            // balance
            balance: state => state.balance.balance,
        }),

        // searchFilteredList
        searchFilteredList() {
            if (this.searchValue === '') return this.sitesList;
            return this.sitesList.filter(c => c.id?.toLowerCase().includes(this.searchValue.toLowerCase()) || c.site?.toLowerCase().includes(this.searchValue.toLowerCase()));
        },

        itemsList() {
            return this.searchFilteredList.slice(this.pageSize * (this.currentPage - 1 ?? 0), this.pageSize * this.currentPage)
        }
    },

    methods: {
        initSitesList() {
            this.searchValue = '';

            this.requestLoading = true;

            this.$store.dispatch('getSites', {
                data: {
                    ...this.filterData,

                    from: moment().date(-30 * 2).format('DD.MM.YYYY'), // current date - 2 month
                    to: moment().format('DD.MM.YYYY'), // current date
                }
            }).finally(() => {
                setTimeout(() => {
                    this.requestLoading = false;
                    this.currentPage = 1;
                }, 1000)
            })
        },

        // filter([from, to]) {
        filter() {
            this.searchValue = '';

            // this.filterData.from = from.format('DD.MM.YYYY');
            // this.filterData.to = to.format('DD.MM.YYYY');

            this.requestLoading = true;

            this.$store.dispatch('getSites', {
                data: this.filterData
            }).finally(() => {
                setTimeout(() => {
                    this.requestLoading = false;
                    this.currentPage = 1;
                }, 1000)
            })
        },

        updatePage(current) {
            this.requestLoading = true;
            this.currentPage = current;

            setTimeout(() => {
                this.requestLoading = false;
            }, 1000)
        },

        saveCode(value) {
            this.$store.dispatch('saveSite', {
                data: {
                    default_code: value
                },
                id: this.editCodeSiteId,
            }).finally(() => {
                this.editCodeSiteId = null;
            })

            this.editCodeModalOpen = false;
        },

        editCode(siteId) {
            this.editCodeSiteId = siteId;

            this.$store.dispatch('getSite', {data: {id: siteId}}).then((response) => {
                this.editCodeModalOpen = true;
                this.editCodeValue = response.default_code;
            })
        },

        getCode(siteId) {
            this.getCodeModalOpen = true;

            this.$store.dispatch('getSiteCode', {
                data: {
                    id: siteId
                }
            }).then((response) => this.codeValue = response.code)
        },

        closeEditModal() {
            this.editCodeModalOpen = false;
            this.editCodeValue = '';
        },

        closeModal() {
            this.getCodeModalOpen = false;
            this.codeValue = '';
        },
    },

    created() {
        this.$store.dispatch('getUser');
        this.$store.dispatch('getBalance');

        this.filter();
    }
};
</script>

<style scoped lang="scss">
.dashboard-page {
    background: #89123e;
}

.dashboard__sites_item_change.name {
    width: 9em;
}

.dashboard__sites_item_change, .dashboard__sites_item_code {
    width: 10em;
}
//
//.dashboard__sites_item_change {
//    display: flex;
//    align-items: center;
//    flex-direction: row;
//    width: 12.75em;
//}
//
//.dashboard__sites_item_change > div {
//    margin: 0 .2em;
//}
</style>
