<template>
    <div class="body-wrap full-height-page">
        <section class="login">
            <div class="login__text_block">
                <a class="login__logo" href="#"
                ><img src="img/header/logo.svg" alt=""
                /></a>
                <div class="login__form_block">
                    <div class="login__form_text_1"><span>Log in</span></div>
                    <div class="login__form_text_2">
                        <span>Welcome back! Please enter your details.</span>
                    </div>
                    <div class="form" id="loginForm" action="#">
                        <div class="form__wrapper">
                            <label for="email">Email</label>
                            <input
                                class="form__input"
                                id="email"
                                type="email"
                                name="email"
                                placeholder="you@company.com"
                                data-name="email"
                                data-valid="false"
                                v-model="email"
                            /><span class="error-item">Invalid format of email</span>
                        </div>
                        <div class="form__wrapper">
                            <div class="password-eye" data-status="Показать пароль"
                                 @click="passwordVisible = !passwordVisible"
                            >
                                <img
                                    class="password-img password_hidden"
                                    :class="passwordVisible && 'active'"
                                    id="newPassword"
                                    src="img/eye.svg"
                                    alt=""
                                />
                                <img
                                    class="password-img password_visible"
                                    :class="!passwordVisible && 'active'"
                                    id="newPassword1"
                                    src="img/eye_open.svg"
                                    alt=""
                                />
                            </div>
                            <label for="Password">Password</label>
                            <input
                                class="form__input"
                                id="email"
                                :type="!passwordVisible ? 'password' : 'text'"
                                name="password"
                                placeholder="••••••••"
                                data-name="password"
                                data-valid="false"
                                v-model="password"
                            /><span class="error-item">Invalid format of password</span>
                        </div>
                        <div class="form__checkbox_wrapper">
                            <div class="form__checkbox">
                                <input
                                    class="checkboxCustom custom-checkbox"
                                    id="checkboxCustom"
                                    type="checkbox"
                                    name="checkboxCustom"
                                    value=""
                                    v-model="remember"
                                />
                                <label for="checkboxCustom">Remember me </label>
                            </div>
                            <router-link to="/forgot" class="form__forget">
                                <span>Forgot password</span>
                            </router-link>

                        </div>
                        <button
                            class="form__login_btn"
                            id="loginBtn"
                            type="submit"
                            :disabled="errors"
                            @click="login"
                        >
                            <span>Sign in</span>
                        </button>
                    </div>
                    <div class="login__sign_up">
                        <span>Don’t have an account?</span>
                        <router-link to="/registration">
                            Sign up
                        </router-link>
                    </div>
                </div>
                <div class="login__form_block hide">
                    <div class="login__form_text_1"><span>Reset your password</span></div>
                    <div class="login__form_text_2">
            <span
            >Enter your email address and we will send you <br/>instructions
              to reset your password.</span
            >
                    </div>
                    <div class="form" id="ResetPassForm" action="#">
                        <div class="form__wrapper">
                            <label for="email">Email</label>
                            <input
                                class="form__input"
                                id="email"
                                type="email"
                                name="email"
                                placeholder="you@company.com"
                                data-name="email"
                                data-valid="false"
                            /><span class="error-item">Invalid format of email</span>
                        </div>
                        <button
                            class="form__login_btn"
                            id="loginBtn"
                            type="submit"
                            disabled
                        >
                            <span>Continue</span>
                        </button>
                    </div>
                    <div class="login__sign_up"><a href="#">Back to Login</a></div>
                </div>
                <div class="login__form_block hide">
                    <div class="login__form_text_1"><span>Check Your Email</span></div>
                    <div class="login__form_text_2">
            <span
            >Please check the email address
              <span class="email">example@gmail.com </span>for instructions to
              reset your password.</span
            >
                    </div>
                    <a class="login__resend_email_btn" href="#"
                    ><span>Resend email </span></a
                    >
                </div>
                <div class="login__form_block hide">
                    <div class="login__form_text_1">
                        <span>Create a new password</span>
                    </div>
                    <div class="login__form_text_2">
            <span
            >Please check the email address <span>example@gmail.com </span>for
              instructions to reset your password.</span
            >
                    </div>
                    <div class="form" id="newPassForm" action="#">
                        <div class="form__wrapper password-wrap">
                            <div class="password-eye" data-status="Показать пароль">
                                <img
                                    class="password-img password_hidden active"
                                    id="password"
                                    src="img/eye.svg"
                                    alt=""
                                /><img
                                class="password-img password_visible"
                                id="password"
                                src="img/eye_open.svg"
                                alt=""
                            />
                            </div>
                            <label for="password">New Password*</label>
                            <input
                                class="form__input input-password"
                                type="password"
                                name="password"
                                placeholder="Create a password"
                                data-valid="false"
                                data-name="password"
                            /><span class="error-item">Invalid format of password</span>
                        </div>
                        <div class="form__pass_list">
                            <ul class="form__pass_list_item">
                                <li class="active">must be at least 8 characters length</li>
                                <li>contains lowercase latin letters</li>
                                <li>contains uppercase latin letters</li>
                                <li>digits</li>
                                <li class="active">
                                    password and password confirmation must be equal
                                </li>
                            </ul>
                        </div>
                        <div class="form__wrapper password-wrap">
                            <div class="password-eye" data-status="Показать пароль">
                                <img
                                    class="password-img password_hidden active"
                                    id="password"
                                    src="img/eye.svg"
                                    alt=""
                                /><img
                                class="password-img password_visible"
                                id="password"
                                src="img/eye_open.svg"
                                alt=""
                            />
                            </div>
                            <label for="reppassword">Repeat new password*</label>
                            <input
                                class="form__input input-password"
                                type="password"
                                name="reppassword"
                                placeholder="Repeat password"
                                data-valid="false"
                                data-name="password"
                            /><span class="error-item">Sorry but password mismatch</span>
                        </div>
                        <button
                            class="form__login_btn"
                            id="loginBtn"
                            type="submit"
                        >
                            <span>Update password</span>
                        </button>
                    </div>
                </div>
                <div class="login__form_block hide">
                    <div class="login__form_text_1">
                        <span>Password changed successfully</span>
                    </div>
                    <div class="login__form_text_2">
                        <span>You can now log in using your new password</span>
                    </div>
                    <a class="login__resend_email_btn blue" href="#"
                    ><span>To login page</span></a
                    >
                </div>
                <div class="login__copyrights"><span>© Dogtraff 2024</span></div>
            </div>
            <div class="login__image_block">
                <img src="img/login/main-image.png" alt=""/>
            </div>
        </section>
    </div>
</template>

<script>
// import axios from "axios";

export default {
    name: 'Login',

    data() {
        return {
            email: "testad",
            password: "123123",

            remember: false,
            passwordVisible: false,
        };
    },

    computed: {
        errors() {
            return this.email.length < 2 || this.password.length < 2
        },
    },

    methods: {
        login() {
            // const url = "https://needmob.com/api/auth";
            const auth = Buffer.from(`${this.email}:${this.password}`).toString("base64");

            localStorage.setItem('auth', auth);

            this.$store.dispatch('login').then(() => {
                this.$router.push('/dashboard');
            });

            //
            // axios
            //     .get(url, {
            //         headers: {
            //             Authorization: `Basic ${auth}`,
            //         },
            //     })
            //     .then((response) => {
            //         if (response.data.status === 'success') {
            //             localStorage.setItem('auth', auth);
            //
            //         }
            //     });
        },
    },
};
</script>
