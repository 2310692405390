<template>
    <div class="date-time-picker__days_numbers">
        <div class="date-time-picker__days">
            <div v-for="(number) in 24"
                v-bind:key="`day-${number}`"
                class="date-time-picker__day"
                @click="$emit('change', number-1)"
            >
               <span>{{number-1}}</span>
            </div>
        </div>

        <div class="date-time-picker__days" v-for="(day) in 7"
             v-bind:key="`day-${day}`"
        >
            <div v-for="(number) in 24"
                v-bind:key="`day-${number}`"
                class="date-time-picker__checkbox"
                @click="select(`${day}-${number-1}`)"
            >
                <Checkbox :checked="isChecked(`${day}-${number-1}`)" />
            </div>
        </div>
    </div>
</template>

<script>
import Checkbox from '@/components/UI/Checkbox.vue'

export default {
    name: 'DTPDayNumbers',

    components: {
        Checkbox
    },

    props: [
        'defaultValues',
        'selectedValuesFilter',
    ],

    data() {
        return {
            selectedValues: []
        }
    },

    methods: {
        isChecked(dayNumber) {
            return this.selectedValues.find(dn => dn === dayNumber) !== undefined;
        },

        select(dayNumber) {
            this.selectedValues = this.selectedValues.find(dn => dn === dayNumber) !== undefined
                ? this.selectedValues.filter(dn => dn !== dayNumber)
                : [...this.selectedValues, dayNumber];

            this.$emit('change', this.selectedValues);
        },

        getArrayFromRange(start, end) {
            return Array.from({ length: end - start + 1 }, (_, i) => start + i);
        },

        addFilter(filterName) {
            const days = this.getArrayFromRange(1, 7);
            const dayNumbers = this.getArrayFromRange(0, 23);

            switch (filterName) {
                case 'all':
                    return days.map(day => dayNumbers.map(dn => `${day}-${dn}`))
                        .flatMap(innerArray => innerArray)
                case 'weekends':
                    return days.filter(day => day === 6 || day === 7)
                        .map(day => dayNumbers.map(dn => `${day}-${dn}`))
                        .flatMap(innerArray => innerArray)
                case 'work':
                    return days.filter(day => day !== 6 && day !== 7)
                        .map(day => dayNumbers.map(dn => `${day}-${dn}`))
                        .flatMap(innerArray => innerArray)
                case 'clear':
                    return []
            }
        },

        addFilterByHours(prevFilterName) {
            return this.addFilter(prevFilterName === '' || prevFilterName === 'clear' ? 'all' : prevFilterName).filter(dayNumber => {
                return dayNumber.split('-')[1] >= 9 && dayNumber.split('-')[1] <= 18;
            })
        }
    },

    watch: {
        selectedValuesFilter(filterName, prevFilterName) {
            this.selectedValues = filterName === 'businessHours'
                ? this.addFilterByHours(prevFilterName)
                : this.addFilter(filterName, prevFilterName);

            this.$emit('change', this.selectedValues);
        },
        defaultValues(value) {
            this.selectedValues = value;
            this.$emit('change', this.selectedValues);
        }
    },
}
</script>

<style lang="scss" scoped>
.date-time-picker {
    &__days_numbers {
        display: flex;
        flex-direction: column;
    }

    &__days {
        display: flex;
        flex-direction: row;
    }

    &__day {
        width: 2.038em;
        padding: .7em .552em;
        background: transparent;
        transition: background .2s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2.4em;
        border-bottom: .1em solid #EAECF0;

        span {
            font-size: 1em;
            color: #344054;
            font-weight: 400;
        }
    }

    &__checkbox {
        width: 2.038em;
        height: 2.4em;
        text-align: left;
        background: transparent;
        transition: background .2s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: .2em;
        cursor: pointer;

        &:hover {
            background: #EAECF0;
        }

        span {
            font-size: 1em;
            color: #344054;
            font-weight: 400;
        }
    }

    @media(max-width: 1024px) and (orientation: portrait) {
        &__days_numbers {
            flex-direction: row;
        }

        &__days {
            flex-direction: column;
        }

        &__day {
            width: 3.87em;
            border-bottom: 0;
            border-right: .1em solid #EAECF0;
        }

        &__checkbox {
            width: 3.6em;
        }
    }
}
</style>
