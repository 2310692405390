<template>
    <div class="body-wrap dashboard-page">
        <section class="dashboard">
            <MainSidebar/>
            <div class="dashboard__white_container">
                <div class="dashboard__white_nav">
                    <div class="dashboard__white_nav_left">
                        <div class="dashboard__white_nav_heading"><span>Сreate a new campaign</span></div>
                    </div>
                </div>
                <div class="dashboard__create_campaign">
                    <div class="dashboard__create_item">
                        <div class="dashboard__create_item_name"><span>Name of Campaign</span></div>
                        <div class="dashboard__create_item_single_input"
                             :class="!formCampValidation['campname'] && 'no-item-error'">
                            <input class="name-campaign" id="nameCampaign" type="text" name="name-campaign"
                                   placeholder="Name of your campaign" v-model="campaignFormValues.campname">

                            <span class="error-item">Required field</span>
                        </div>
                    </div>
                    <div class="dashboard__create_item">
                        <div class="dashboard__create_item_name"><span>Format</span></div>
                        <div class="dashboard__create_item_single_select">
                            <select id="formatCampaign" name="campaignformat" v-model="campaignFormValues.format">
                                <option value="inpagepush">Inpage Push</option>
                            </select>
                        </div>
                    </div>
                    <div class="dashboard__create_item">
                        <div class="dashboard__create_item_name"><span>Type of Traffic</span></div>
                        <div class="dashboard__create_item_single_input">
                            <div class="dashboard__create_item_billing_line_radio_btn">
                                <input class="checkboxCustom custom-checkbox radio-blue" id="mainstream" type="radio"
                                       name="traffic" value="mainstream" v-model="campaignFormValues.themes[0]">
                                <label for="mainstream">Mainstream</label>
                            </div>
                            <div class="dashboard__create_item_billing_line_radio_btn">
                                <input class="checkboxCustom custom-checkbox radio-blue" id="adult" type="radio"
                                       name="traffic" value="adult" v-model="campaignFormValues.themes[0]">
                                <label for="adult">Adult</label>
                            </div>
                        </div>
                    </div>
                    <div class="dashboard__create_item">
                        <div class="dashboard__create_item_name"><span>BID</span></div>
                        <div class="dashboard__create_item_single_input"
                             :class="!formCampValidation['cpc'] && 'no-item-error'">
                            <div class="dashboard__create_item_summ_radio_block">
                                <div class="dashboard__create_item_billing_line_radio_btn cpc">
                                    <input class="checkboxCustom custom-checkbox radio-blue" id="cpc" type="radio"
                                           name="bid" value="" checked>
                                    <label for="cpc">CPC</label>
                                </div>
                                <div class="dashboard__create_item_billing_line_radio_btn disabled">
                                    <input class="checkboxCustom custom-checkbox radio-blue" id="cpm" type="radio"
                                           name="bid" value="">
                                    <label for="cpm">CPM</label>
                                </div>

                            </div>
                            <div class="dashboard__create_item_summ">
                                <span>$</span>
                                <input class="name-campaign" id="nameCampaign" type="text" name="name-campaign"
                                       placeholder="Sum" v-model="campaignFormValues.CPC">
                            </div>
                        </div>
                    </div>
                    <div class="dashboard__create_item">
                        <div class="dashboard__create_item_name"><span>GEO</span></div>
                        <div class="dashboard__create_item_single_select">
                            <select id="geoformat" name="geoformat" v-model="campaignFormValues.targetCountries[0]">
                                <option v-for="(country, key) in campData.target_countries"
                                        :key="key"
                                        :value="key"
                                >
                                    {{ country }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="dashboard__create_item">
                        <div class="dashboard__create_item_name"><span>Devices</span></div>
                        <div class="dashboard__create_item_single_input">
                            <div class="dashboard__create_item_chekbox">
                                <input class="checkboxStat custom-checkbox-stat" id="createMobile" type="checkbox"
                                       name="createMobile" value="mobile" v-model="campaignFormValues.targetDevices">
                                <label for="createMobile">Mobile</label>
                            </div>
                            <div class="dashboard__create_item_chekbox">
                                <input class="checkboxStat custom-checkbox-stat" id="createDesktop" type="checkbox"
                                       name="createDesktop" value="desktop" v-model="campaignFormValues.targetDevices">
                                <label for="createDesktop">Desktop</label>
                            </div>
                            <div class="dashboard__create_item_chekbox">
                                <input class="checkboxStat custom-checkbox-stat" id="createTablet" type="checkbox"
                                       name="createTablet" value="tablet" v-model="campaignFormValues.targetDevices">
                                <label for="createTablet">Tablet</label>
                            </div>
                        </div>
                    </div>
                    <div class="dashboard__create_item">
                        <div class="dashboard__create_item_name"><span>OS</span></div>
                        <div class="dashboard__create_item_single_select">
                            <select id="os" name="os" v-model="campaignFormValues.targetPlatforms" multiple size="3">
                                <option v-for="(platform) in targetPlatforms"
                                        :key="platform"
                                        :value="platform"
                                >
                                    {{ platform }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="dashboard__create_item">
                        <div class="dashboard__create_item_name"><span>Browser language</span></div>
                        <div class="dashboard__create_item_single_select">
                            <select id="browserLanguage" name="browser-language"
                                    v-model="campaignFormValues.targetLanguages[0]">
                                <option v-for="(title, key) in campData.targetLanguages[0]"
                                        :key="key"
                                        :value="key"
                                >
                                    {{ title }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="dashboard__create_item">
                        <div class="dashboard__create_item_name"><span>Type of connection</span></div>
                        <div class="dashboard__create_item_single_input">
                            <div class="dashboard__create_item_chekbox">
                                <input class="checkboxStat custom-checkbox-stat" id="createWifi" type="checkbox"
                                       name="createWifi" value="dsl" v-model="campaignFormValues.targetConnections">
                                <label for="createWifi">Wi-Fi</label>
                            </div>
                            <div class="dashboard__create_item_chekbox">
                                <input class="checkboxStat custom-checkbox-stat" id="createCellular" type="checkbox"
                                       name="createCellular" value="cellular"
                                       v-model="campaignFormValues.targetConnections">
                                <label for="createCellular">Cellular</label>
                            </div>
                        </div>
                    </div>
                    <div class="dashboard__create_item">
                        <div class="dashboard__create_item_name"><span>Browser</span></div>
                        <div class="dashboard__create_item_single_select">
                            <select id="browser" name="browser" v-model="campaignFormValues.targetBrowsers" multiple>
                                <option v-for="(browser) in Object.keys(campData.targetBrowsers[0])"
                                        :key="browser"
                                        :value="browser"
                                >
                                    {{ browser }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="dashboard__create_item">
                        <div class="dashboard__create_item_name"><span>Dates of start and end</span></div>
                        <div class="dashboard__create_item_dates">
                            <div class="dashboard__date_btn">
                                <img src="img/dashboard/calendar.svg" alt="">
                                <span>{{ campaignFormValues.perionFrom }}</span>
                                <DatePicker @change="getDateFrom" class="datePicker"/>
                            </div>

                            <div class="dashboard__create_item_date_line"><span>–</span></div>
                            <div class="dashboard__date_btn">
                                <img src="img/dashboard/calendar.svg" alt="">
                                <span>{{ campaignFormValues.periodTo }}</span>
                                <DatePicker @change="getDateTo" class="datePicker"/>
                            </div>
                        </div>
                    </div>
                    <div class="dashboard__create_item">
                        <div class="dashboard__create_item_name"><span>Time setting</span></div>
                        <div class="dashboard__create_item_dates">

                            <DateTimePicker
                                :open="openDateTimePickerPopup"
                                :defaultValues="campaignFormValues.schedule"
                                @apply="getDateTimePicker"
                                @cancel="openDateTimePickerPopup = false"
                            />

                            <div class="dashboard__date_btn time-btn" @click="openDateTimePickerPopup = true">
                                <img src="img/dashboard/clock.svg" alt="">
                                <span>Configure</span>
                            </div>
                        </div>
                    </div>
                    <div class="dashboard__create_item">
                        <div class="dashboard__create_item_name">
                            <span>Number of clicks</span>
                        </div>
                        <div class="dashboard__create_item_single_input">
                            <input class="number-of-clicks" id="numberOfClicks" type="text" name="numberOfClicks"
                                   placeholder="" v-model="campaignFormValues.limitTotal">
                        </div>
                    </div>
                    <div class="dashboard__create_item">
                        <div class="dashboard__create_item_name"><span>Daily budget</span></div>
                        <div class="dashboard__create_item_single_input">
                            <input class="daily-budget" id="dailyBudget" type="text" name="dailyBudget" placeholder=""
                                   v-model="campaignFormValues.limitDailyBudget">
                        </div>
                    </div>
                    <div class="dashboard__create_item">
                        <div class="dashboard__create_item_name"><span>Total budget</span></div>
                        <div class="dashboard__create_item_single_input">
                            <input class="total-budget" id="totalBudget" type="text" name="totalBudget" placeholder=""
                                   v-model="campaignFormValues.limitTotalBudget">
                        </div>
                    </div>
                    <div class="dashboard__create_item">
                        <div class="dashboard__create_item_name"><span>Capping</span></div>
                        <div class="dashboard__create_item_single_input">
                            <input class="capping" id="capping" type="text" name="capping" placeholder=""
                                   v-model="campaignFormValues.capping">
                            <div class="dashboard__create_item_capping_span"><span>Minimum 1/1</span></div>
                            <div class="dashboard__create_item_capping_radios">
                                <div class="dashboard__create_item_billing_line_radio_btn cpc">
                                    <input class="checkboxCustom custom-checkbox radio-blue" id="clicks" type="radio"
                                           name="minimum" value="clicks" v-model="campaignFormValues.cappingType">
                                    <label for="clicks">Clicks</label>
                                </div>
                                <div class="dashboard__create_item_billing_line_radio_btn cpc">
                                    <input class="checkboxCustom custom-checkbox radio-blue" id="impressions"
                                           type="radio" name="minimum" value="impressions"
                                           v-model="campaignFormValues.cappingType">
                                    <label for="impressions">Impressions</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="dashboard__create_item creative-templates">
                        <div class="dashboard__create_item_name_container">
                            <div class="dashboard__create_item_name">
                                <span>Creative templates</span>
                            </div>
                        </div>
                        <div class="dashboard__create_item_single_select">
                            <select id="creativeTemplates" name="creative-templates"
                                    multiple
                                    v-model="bannerFormValues.templatesChecked">
                                <option value="all">All</option>
                                <option value="Android">Android</option>
                                <option value="Windows">Windows</option>
                                <option value="iOS">iOS</option>
                                <option value="MacOS">MacOS</option>
                                <!--                <option value="Universal">Universal</option>-->
                            </select>
                        </div>
                    </div>
                    <!--          <div class="dashboard__create_item creative-templates">-->
                    <!--            <div class="dashboard__create_item_name_container">-->
                    <!--              <div class="dashboard__create_item_name"> <span>OS:</span></div>-->
                    <!--            </div>-->
                    <!--            <div class="dashboard__create_item_single_select">-->
                    <!--              <select id="creativeTemplatesOs" name="creative-templates-os">-->
                    <!--                <option value="Android">Android</option>-->
                    <!--                <option value="Android">Android</option>-->
                    <!--                <option value="Android">Android</option>-->
                    <!--                <option value="Android">Android</option>-->
                    <!--              </select>-->
                    <!--            </div>-->
                    <!--          </div>-->
                    <!--          <div class="dashboard__create_item">-->
                    <!--            <div class="dashboard__create_item_name_container">-->
                    <!--              <div class="dashboard__create_item_name"> <span>Adult</span></div>-->
                    <!--            </div>-->
                    <!--            <div class="dashboard__create_item_single_select">-->
                    <!--              <select id="videoMess" name="videoMess">-->
                    <!--                <option value="Video message">Video message</option>-->
                    <!--                <option value="Video message">Video message</option>-->
                    <!--                <option value="Video message">Video message</option>-->
                    <!--                <option value="Video message">Video message</option>-->
                    <!--              </select>-->
                    <!--            </div>-->
                    <!--          </div>-->
                    <div class="dashboard__create_item creative-templates">
                        <div class="dashboard__create_item_name_container">
                            <div class="dashboard__create_item_name"><span>Set creative</span></div>
                        </div>
                        <!--            <div class="dashboard__create_item_set_creative">-->
                        <!--              <div class="dashboard__date_btn"><img src="img/dashboard/upload.svg" alt=""><span>Upload file</span></div>-->
                        <!--              <div class="dashboard__create_item_capping_span"> <span>Image (.jpg, .png) with a resolution of 192x192</span></div>-->
                        <!--            </div>-->

                        <div class="creative">
                            <div class="dashboard__create_item_link_block"
                                 :class="!formBannerValidation['creative'] && 'no-item-error'"
                            >
                                <div class="dashboard__create_item_link_prefix">
                                    <span>https://</span>
                                </div>
                                <div class="dashboard__create_item_lint">
                                    <input class="link-text" id="linkText" type="text" name="link-text"
                                           placeholder="www.your-creative.com" v-model="bannerFormValues.mediaFiles['SRC'][0]">
                                    <span class="error-item">Required field</span>
                                </div>
                            </div>

                            <label class="dashboard__date_btn time-btn" @click="modalOpen = true">
                                <img src="img/dashboard/eye.svg" alt="">
                                <span>{{this.creative?.name ?? 'Add file'}}</span>
                            </label>
                        </div>
                    </div>

                    <!--          <div class="dashboard__create_item">-->
                    <!--            <div class="dashboard__create_item_name_container">-->
                    <!--              <div class="dashboard__create_item_name"> <span>Background color</span></div>-->
                    <!--            </div>-->
                    <!--            <div class="dashboard__create_item_set_creative">-->
                    <!--              <div class="dashboard__date_btn">-->
                    <!--                <div></div><span>Change color</span>-->
                    <!--              </div>-->

                    <!--              <ColorPicker pickerType="chrome" v-model:pureColor="pureColor" />-->
                    <!--            </div>-->
                    <!--          </div>-->
                    <div class="dashboard__create_item creative-templates">
                        <div class="dashboard__create_item_name_container">
                            <div class="dashboard__create_item_name">
                                <span>Enter title</span></div>
                        </div>
                        <div class="dashboard__create_emoji_container">
                            <div class="dashboard__create_emoji_block_1">
                                <div class="dashboard__create_emoji_block_1_1">
                                    <div class="dashboard__create_item_single_input"
                                         :class="!formBannerValidation['title'] && 'no-item-error'">
                                        <input class="enter-title" id="enterTitle" type="text" name="enter-title"
                                               placeholder="Enter title" v-model="bannerFormValues.templatesHeaderText">

                                        <span class="error-item">Required field</span>
                                    </div>
                                    <!--                  <div class="dashboard__create_emoji"> <span>🙂</span></div>-->
                                </div>
                                <div class="dashboard__create_item_capping_span">
                                    <span>Must have at most 30 symbols</span></div>
                            </div>
                        </div>
                    </div>
                    <div class="dashboard__create_item">
                        <div class="dashboard__create_item_name_container">
                            <div class="dashboard__create_item_name">
                <span>Size,
                    <!--                  color and -->
                  style of title:</span>
                            </div>
                        </div>
                        <div class="dashboard__create_emoji_text">
                            <div class="dashboard__create_item_single_text_size">
                                <!--                <select id="textSize" name="textSize" @change="e => updateTextStyle('title', 'size', e.target.value, 'templatesHeaderStyle')">-->
                                <select id="textSize" name="textSize"
                                        @change="e => updateTextSize('title', e, 'templatesHeaderStyle')">
                                    <option value="12">12</option>
                                    <option value="14">14</option>
                                    <option value="16">16</option>
                                    <option value="20">20</option>
                                    <option value="22">22</option>
                                    <option value="24">24</option>
                                    <option value="26">26</option>
                                    <option value="28">28</option>
                                </select>
                            </div>
                            <!--              <div class="dashboard__date_btn no-span">-->
                            <!--                <div></div>-->
                            <!--              </div>-->
                            <div class="dashboard__create_item__styled_text">
                                <label class="dashboard__create_item_styled_text_bold"
                                       :class="titleStyles.includes('bold') && 'activeRadioButton'">
                                    <img src="img/dashboard/bold.svg" alt="">
                                    <input type="checkbox" value="bold"
                                           @change="e => updateTextStyle('title', 'style', e.target.value, 'templatesHeaderStyle')">
                                </label>
                                <label class="dashboard__create_item_styled_text_italic"
                                       :class="titleStyles.includes('italic') && 'activeRadioButton'">
                                    <img src="img/dashboard/italic.svg" alt="">
                                    <input type="checkbox" value="italic"
                                           @change="e => updateTextStyle('title', 'style', e.target.value, 'templatesHeaderStyle')">
                                </label>
                                <label class="dashboard__create_item_styled_text_underline"
                                       :class="titleStyles.includes('underline') && 'activeRadioButton'">
                                    <img src="img/dashboard/underline.svg" alt="">
                                    <input type="checkbox" value="underline"
                                           @change="e => updateTextStyle('title', 'style', e.target.value, 'templatesHeaderStyle')">
                                </label>
                            </div>
                            <div class="dashboard__create_item__styled_text">
                                <label class="dashboard__create_item_styled_text_bold"
                                       :class="titleStyles.includes('left') && 'activeRadioButton'">
                                    <img src="img/dashboard/align-left.svg" alt="">
                                    <input type="checkbox" value="left"
                                           @change="e => updateTextAlign('title', 'align', e.target.value, 'templatesHeaderStyle')">
                                </label>
                                <label class="dashboard__create_item_styled_text_italic"
                                       :class="titleStyles.includes('center') && 'activeRadioButton'">
                                    <img src="img/dashboard/align-center.svg" alt="">
                                    <input type="checkbox" value="center"
                                           @change="e => updateTextAlign('title', 'align', e.target.value, 'templatesHeaderStyle')">
                                </label>
                                <label class="dashboard__create_item_styled_text_underline"
                                       :class="titleStyles.includes('right') && 'activeRadioButton'">
                                    <img src="img/dashboard/align-right.svg" alt="">
                                    <input type="checkbox" value="right"
                                           @change="e => updateTextAlign('title', 'align', e.target.value, 'templatesHeaderStyle')">
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="dashboard__create_item creative-templates">
                        <div class="dashboard__create_item_name_container">
                            <div class="dashboard__create_item_name">
                                <span>Enter description</span></div>
                        </div>
                        <div class="dashboard__create_emoji_container">
                            <div class="dashboard__create_emoji_block_1">
                                <div class="dashboard__create_emoji_block_1_1">
                                    <div class="dashboard__create_item_single_input"
                                         :class="!formBannerValidation['desc'] && 'no-item-error'">
                                        <input class="enter-title" id="enterTitle" type="text" name="enter-title"
                                               placeholder="Enter description"
                                               v-model="bannerFormValues.templatesDescText">

                                        <span class="error-item">Required field</span>
                                    </div>
                                    <!--                  <div class="dashboard__create_emoji"> <span>🙂</span></div>-->
                                </div>
                                <!--                <div class="dashboard__create_item_capping_span"> <span>Must have at most 30 symbols</span></div>-->
                            </div>
                        </div>
                    </div>
                    <div class="dashboard__create_item">
                        <div class="dashboard__create_item_name_container">
                            <div class="dashboard__create_item_name">
                <span>Size,
                    <!--                  color and -->
                  style of description:</span>
                            </div>
                        </div>
                        <div class="dashboard__create_emoji_text">
                            <div class="dashboard__create_item_single_text_size">
                                <select id="textSize" name="textSize"
                                        @change="e => updateTextSize('description', e, 'templatesDescStyle')">
                                    <option value="12">12</option>
                                    <option value="14">14</option>
                                    <option value="16">16</option>
                                    <option value="20">20</option>
                                    <option value="22">22</option>
                                    <option value="24">24</option>
                                    <option value="26">26</option>
                                    <option value="28">28</option>
                                </select>
                            </div>
                            <!--              <div class="dashboard__date_btn no-span">-->
                            <!--                <div></div>-->
                            <!--              </div>-->
                            <div class="dashboard__create_item__styled_text">
                                <label class="dashboard__create_item_styled_text_bold"
                                       :class="descriptionStyles.includes('bold') && 'activeRadioButton'">
                                    <img src="img/dashboard/bold.svg" alt="">
                                    <input type="checkbox" value="bold"
                                           @change="e => updateTextStyle('description', 'style', e.target.value, 'templatesDescStyle')">
                                </label>
                                <label class="dashboard__create_item_styled_text_italic"
                                       :class="descriptionStyles.includes('italic') && 'activeRadioButton'">
                                    <img src="img/dashboard/italic.svg" alt="">
                                    <input type="checkbox" value="italic"
                                           @change="e => updateTextStyle('description', 'style', e.target.value, 'templatesDescStyle')">
                                </label>
                                <label class="dashboard__create_item_styled_text_underline"
                                       :class="descriptionStyles.includes('underline') && 'activeRadioButton'">
                                    <img src="img/dashboard/underline.svg" alt="">
                                    <input type="checkbox" value="underline"
                                           @change="e => updateTextStyle('description', 'style', e.target.value, 'templatesDescStyle')">
                                </label>
                            </div>
                            <div class="dashboard__create_item__styled_text">
                                <label class="dashboard__create_item_styled_text_bold"
                                       :class="descriptionStyles.includes('left') && 'activeRadioButton'">
                                    <img src="img/dashboard/align-left.svg" alt="">
                                    <input type="checkbox" value="left"
                                           @change="e => updateTextAlign('description', 'align', e.target.value, 'templatesDescStyle')">
                                </label>
                                <label class="dashboard__create_item_styled_text_italic"
                                       :class="descriptionStyles.includes('center') && 'activeRadioButton'">
                                    <img src="img/dashboard/align-center.svg" alt="">
                                    <input type="checkbox" value="center"
                                           @change="e => updateTextAlign('description', 'align', e.target.value, 'templatesDescStyle')">
                                </label>
                                <label class="dashboard__create_item_styled_text_underline"
                                       :class="descriptionStyles.includes('right') && 'activeRadioButton'">
                                    <img src="img/dashboard/align-right.svg" alt="">
                                    <input type="checkbox" value="right"
                                           @change="e => updateTextAlign('description', 'align', e.target.value, 'templatesDescStyle')">
                                </label>
                            </div>
                        </div>
                    </div>

                    <!--          <div class="dashboard__create_item creative-templates">-->
                    <!--            <div class="dashboard__create_item_name"> <span>Set button</span></div>-->
                    <!--            <div class="dashboard__create_item_single_input">-->
                    <!--              <div class="dashboard__create_item_billing_line_radio_btn">-->
                    <!--                <input class="checkboxCustom custom-checkbox radio-blue" id="setButtonYes" type="radio" name="setButton" value="" checked>-->
                    <!--                <label for="setButtonYes">Yes</label>-->
                    <!--              </div>-->
                    <!--              <div class="dashboard__create_item_billing_line_radio_btn">-->
                    <!--                <input class="checkboxCustom custom-checkbox radio-blue" id="setButtonNo" type="radio" name="setButton" value="">-->
                    <!--                <label for="setButtonNo">No</label>-->
                    <!--              </div>-->
                    <!--            </div>-->
                    <!--          </div>-->
                    <!--          <div class="dashboard__create_item creative-templates">-->
                    <!--            <div class="dashboard__create_item_name_container">-->
                    <!--              <div class="dashboard__create_item_name"> <span>Button color</span></div>-->
                    <!--            </div>-->
                    <!--            <div class="dashboard__create_item_set_creative">-->
                    <!--              <div class="dashboard__date_btn">-->
                    <!--                <div></div><span>Change color</span>-->
                    <!--              </div>-->
                    <!--            </div>-->
                    <!--          </div>-->
                    <!--          <div class="dashboard__create_item creative-templates">-->
                    <!--            <div class="dashboard__create_item_name_container">-->
                    <!--              <div class="dashboard__create_item_name"> <span>Enter the button text</span></div>-->
                    <!--            </div>-->
                    <!--            <div class="dashboard__create_emoji_container">-->
                    <!--              <div class="dashboard__create_emoji_block_1">-->
                    <!--                <div class="dashboard__create_emoji_block_1_1">-->
                    <!--                  <div class="dashboard__create_item_single_input">-->
                    <!--                    <input class="enter-btn-text" id="enterBtnText" type="text" name="enter-btn-text" placeholder="Enter button text">-->
                    <!--                  </div>-->
                    <!--                  <div class="dashboard__create_emoji"> <span>🙂</span></div>-->
                    <!--                </div>-->
                    <!--                <div class="dashboard__create_item_capping_span"> <span>Must have at most 20 symbols</span></div>-->
                    <!--              </div>-->
                    <!--            </div>-->
                    <!--          </div>-->
                    <!--          <div class="dashboard__create_item">-->
                    <!--            <div class="dashboard__create_item_name_container">-->
                    <!--              <div class="dashboard__create_item_name"> <span>Size, color and style of the button text:</span></div>-->
                    <!--            </div>-->
                    <!--            <div class="dashboard__create_emoji_text">-->
                    <!--              <div class="dashboard__create_item_single_text_size">-->
                    <!--                <select id="textSize" name="textSize">-->
                    <!--                  <option value="12">12</option>-->
                    <!--                  <option value="14">14</option>-->
                    <!--                  <option value="16">16</option>-->
                    <!--                  <option value="20">20</option>-->
                    <!--                  <option value="22">22</option>-->
                    <!--                  <option value="24">24</option>-->
                    <!--                  <option value="26">26</option>-->
                    <!--                  <option value="28">28</option>-->
                    <!--                </select>-->
                    <!--              </div>-->
                    <!--              <div class="dashboard__date_btn no-span">-->
                    <!--                <div></div>-->
                    <!--              </div>-->
                    <!--              <div class="dashboard__create_item__styled_text">-->
                    <!--                <div class="dashboard__create_item_styled_text_bold"> <img src="img/dashboard/bold.svg" alt=""></div>-->
                    <!--                <div class="dashboard__create_item_styled_text_italic"> <img src="img/dashboard/italic.svg" alt=""></div>-->
                    <!--                <div class="dashboard__create_item_styled_text_underline"><img src="img/dashboard/underline.svg" alt=""></div>-->
                    <!--              </div>-->
                    <!--              <div class="dashboard__create_item__styled_text">-->
                    <!--                <div class="dashboard__create_item_styled_text_bold"> <img src="img/dashboard/align-left.svg" alt=""></div>-->
                    <!--                <div class="dashboard__create_item_styled_text_italic"> <img src="img/dashboard/align-center.svg" alt=""></div>-->
                    <!--                <div class="dashboard__create_item_styled_text_underline"><img src="img/dashboard/align-right.svg" alt=""></div>-->
                    <!--              </div>-->
                    <!--            </div>-->
                    <!--          </div>-->
                    <div class="dashboard__create_item">
                        <div class="dashboard__create_item_name"><span>Add link</span></div>
                        <div class="dashboard__create_item_link_block"
                             :class="!formBannerValidation['link'] && 'no-item-error'">
                            <div class="dashboard__create_item_link_prefix"><span>https://</span></div>
                            <div class="dashboard__create_item_lint">
                                <input class="link-text" id="linkText" type="text" name="link-text"
                                       placeholder="www.your-site.com" v-model="bannerFormValues.clickurl">

                                <span class="error-item">Required field</span>
                            </div>
                        </div>
                    </div>
                    <div class="dashboard__create_item_btns">

                        <router-link to="/advertising-campaigns" class="dashboard__billing_btn">
                            <span>Cancel</span>
                        </router-link>

                        <button class="dashboard__billing_btn blue" @click="createNewCampaign" :disabled="!formValide">
                            <span>Save</span>
                        </button>
                    </div>
                </div>
            </div>
        </section>

        <AddBannerFileModal :open="modalOpen" @cancel="modalOpen = false" @attachFile="file => this.creative = file" />
    </div>
</template>

<script>
import MainSidebar from '../../components/Sidebar/MainSidebar.vue';
import {mapState} from "vuex";
import {CAMPAIGN} from '@/Constants'
// import { ColorPicker } from "vue3-colorpicker";
// import "vue3-colorpicker/style.css";
import {DatePicker} from "ant-design-vue";
import DateTimePicker from "@/components/UI/DayTimePicker/DayTimePicker.vue";
import AddBannerFileModal from "@/components/Modal/AddBannerFileModal.vue";

export default {
    components: {
        AddBannerFileModal,
        DateTimePicker,
        MainSidebar,
        DatePicker,
        // ColorPicker,
    },

    data() {
        return {
            time: '',

            openDateTimePickerPopup: false,

            modalOpen: false,

            // formChanged: false,

            campaignFormValues: {
                campname: '',
                format: 'inpagepush',
                themes: ['mainstream'],
                targetCountries: ['UA'],
                // targetType: 'cities',
                // targetRegionsSwitcher: 'on',
                // targetRegions: 'UA~698738~Odessa',
                // targetCitiesSwitcher: 'on',
                // targetCities: 'UA~Chernihiv~705720~Kiyenka',
                // CPM: '3',
                CPC: '',
                targetDevices: ['desktop'],
                targetPlatforms: ['Windows'],
                targetBrowsers: ['Yandex'],
                targetLanguages: ['uk'],
                targetConnections: ['dsl'],
                capping: '',
                cappingType: 'clicks',
                perionFrom: '',
                periodTo: '',
                schedule: [],
                limitTotal: '',
                limitDaily: '',
                limitTotalBudget: '',
                limitDailyBudget: '',
                // priority: '',
                // blacklistid: '10001\\n10002',
                // whitelistid: '20002\\n20003',
                // referalBlacklist: 'blackdomain.com\\nbldomain.com',
            },

            bannerFormValues: {
                bannerName: '',
                // owncodeOrWrap: '',
                clickurl: '',
                mediaFiles: {
                    WIDTH: ['192x192'],
                    SRC: [''],
                },
                templatesChecked: [
                    'Android',
                ],
                templatesHeaderText: '',
                templatesHeaderStyle: '',
                templatesDescText: '',
                templatesDescStyle: '',
                periodFrom: '',
                periodTo: '',
                schedule: [],
                capping: '',
                cappingType: '',
                limitTotal: '',
                limitDaily: '',
                limitTotalBudget: '',
                limitDailyBudget: '',
                // priority: '',
                // actionLinkImpression: '--- default to impression ---'
            },

            bannerTextStyles: {
                title: {
                    size: 12,
                    style: '',
                    align: '',
                },
                description: {
                    size: 12,
                    style: '',
                    align: '',
                },
            },

            creative: null
        }
    },

    computed: {
        ...mapState({
            campaignsList: (state) => state.campaigns.campaignsList,
        }),

        campData() {
            return CAMPAIGN;
        },

        formCampValidation() {
            return {
                campname: this.campaignFormValues.campname?.length >= 1,
                cpc: this.campaignFormValues.CPC?.length >= 1,
            }
        },

        formBannerValidation() {
            return {
                creative: this.creative !== null || this.bannerFormValues.mediaFiles['SRC'][0]?.length >= 1,
                title: this.bannerFormValues.templatesHeaderText?.length >= 1,
                desc: this.bannerFormValues.templatesDescText?.length >= 1,
                link: this.bannerFormValues.clickurl?.length >= 1,
            }
        },

        formValide() {
            return Object.values(this.formCampValidation).every(valid => valid)
                && Object.values(this.formBannerValidation).every(valid => valid);
        },

        targetPlatforms() {
            const desctop = Object.keys(this.campData.targetPlatforms[0].desktop);
            const mob = Object.keys(this.campData.targetPlatforms[0].mobile);

            return [...mob, ...desctop];
        },

        titleStyles() {
            return this.bannerTextStyles.title['align']
                + this.bannerTextStyles.title['style']
                + 'size' + this.bannerTextStyles.title['size'];
        },

        descriptionStyles() {
            return this.bannerTextStyles.description['align']
                + this.bannerTextStyles.description['style']
                + 'size' + this.bannerTextStyles.description['size'];
        },
    },

    methods: {
        getDateTimePicker(values) {
            this.campaignFormValues.schedule = values;
            this.bannerFormValues.schedule = values;
        },

        createNewCampaign() {
            this.updatedBannerFields();
            // this.formChanged = true;

            if (this.formValide) {
                this.$store.dispatch('createNewCampaign', {data: this.campaignFormValues}).then((response) => {
                    this.$store.dispatch('createNewBanner', {
                        data: {
                            ...this.bannerFormValues,
                            campid: response.campid
                        }
                    }).then((bannerId) => {
                        if (this.creative !== null) {
                            this.$store.dispatch('getBannerById', {id: bannerId}).then(r => {
                                const uploadFileFormData = new FormData();
                                uploadFileFormData.append("uploadfile", this.creative)

                                this.$store.dispatch('addBannerFile', {
                                    data: {
                                        cdn: true,
                                        sing: r.uploadSing,
                                        adid: response.campid,
                                        bannerId: bannerId,
                                        json: true,
                                    },
                                    uploadFileFormData
                                }).then((fileResponse) => {

                                    this.$store.dispatch('saveBanner', {
                                        data: {
                                            ...this.bannerFormValues,
                                            mediaFiles: {
                                                WIDTH: ['192x192'],
                                                SRC: [fileResponse.message],
                                            },
                                            campid: response.campid
                                        },
                                        bannerId: bannerId
                                    }).then(() => {
                                        this.$router.push('/advertising-campaigns')
                                    })

                                })
                            })
                        } else {
                            this.$router.push('/advertising-campaigns')
                        }
                    });
                })
            }
        },

        getDateFrom(from) {
            this.campaignFormValues.perionFrom = from.format('DD.MM.YYYY')
        },

        getDateTo(to) {
            this.campaignFormValues.periodTo = to.format('DD.MM.YYYY')
        },

        getTimeTo(to) {
            if (!to) return;
            this.time = to.format('HH');
            this.campaignFormValues.schedule = [1, 2, 3, 4, 5, 6, 7].map(s => s + '-' + to.format('HH'));
        },

        updateTextSize(name, e, totalStyle) {
            this.bannerTextStyles[name]['size'] = e.target.value;

            this.bannerFormValues[totalStyle] = this[`${name}Styles`];
        },

        updateTextStyle(name, styleType, styleValue, totalStyle) {
            this.bannerTextStyles[name]['style'] =
                !this.bannerTextStyles[name]['style'].includes(styleValue)
                    ? this.bannerTextStyles[name]['style'] + styleValue
                    : this.bannerTextStyles[name]['style'].replaceAll(styleValue, '');

            this.bannerFormValues[totalStyle] = this[`${name}Styles`];
        },

        updateTextAlign(name, styleType, styleValue, totalStyle) {
            this.bannerTextStyles[name]['align'] = styleValue;
            this.bannerFormValues[totalStyle] = this[`${name}Styles`];
        },

        // set banner fields from campaign fields
        updatedBannerFields() {
            this.bannerFormValues.periodFrom = this.campaignFormValues.perionFrom;
            this.bannerFormValues.periodTo = this.campaignFormValues.periodTo;
            this.bannerFormValues.schedule = this.campaignFormValues.schedule;
            this.bannerFormValues.capping = this.campaignFormValues.capping;
            this.bannerFormValues.cappingType = this.campaignFormValues.cappingType;
            this.bannerFormValues.limitTotal = this.campaignFormValues.limitTotal;
            this.bannerFormValues.limitDaily = this.campaignFormValues.limitDaily;
            this.bannerFormValues.limitTotalBudget = this.campaignFormValues.limitTotalBudget;
            this.bannerFormValues.limitDailyBudget = this.campaignFormValues.limitDailyBudget;
            // this.bannerFormValues.priority = this.campaignFormValues.priority;
        }
    },

    created() {
        this.$store.dispatch('getCampaigns', {data: {}})
    }
};
</script>

<style lang="scss">
.dashboard-page {
    background: #89123e;
}

.datePicker {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100% !important;
    height: 100% !important;
    z-index: 5;
    opacity: 0 !important;

    & div {
        width: 100% !important;
        height: 100% !important;
        margin: 0 !important;
    }
}

.dashboard__create_item__styled_text input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}

.activeRadioButton {
    background: #1570EF;
    border-color: #1570EF;
    transition: .3s ease-in-out;

    img {
        filter: invert(1) brightness(2);
        transition: .3s ease-in-out;
    }
}

//
//.dashboard__create_item_single_input, .dashboard__create_item_lint {
//  &.item-error{
//    .error-item{
//      opacity: 1;
//    }
//    input{
//      border: em(2,20) solid #ff1f1f;
//    }
//  }
//}

.error-item {
    font-family: inherit !important;
}

select {
    /* Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
        width: em(4);
    }

    &::-webkit-scrollbar-track {
        background: #ffffff;
        border-radius: em(10);
    }

    &::-webkit-scrollbar-thumb {
        background: #C01048;
        border-radius: em(10);
        border: 0;
    }
}

.dashboard__create_item_single_select::after {
    display: none;
}

.creative {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 42.625em;

    .dashboard__create_item_link_block {
        width: 75%;
    }

    input {
        max-width: 22em;
    }

    .file-input {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 8;
        opacity: 0;
        cursor: pointer;
        padding: 0;
        //width: 1px;
        //height: 1px;
        &::-webkit-file-upload-button { /* chromes and blink button */
            cursor: pointer;
        }
    }

    .dashboard__date_btn span {
        max-width: 4em;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    @media (orientation: portrait) {
        width: 100%;
        flex-direction: column;

        .dashboard__create_item_link_block {
            width: 100%;
        }

        input {
            max-width: 100%;
        }
    }
}
</style>
