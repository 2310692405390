<template>
    <component :is="componentName"/>
</template>

<script>
import EmptyPage from "../EmptyPage.vue"
import FinanceAdvertiser from "./components/FinanceAdvertiser.vue"
import FinanceWebmaster from "./components/FinanceWebmaster.vue"
import {mapState} from "vuex";

export default {
    components: {
        EmptyPage,
        FinanceAdvertiser,
        FinanceWebmaster,
    },

    computed: {
        ...mapState({
            user: state => state.user.user
        }),

        componentName() {
            if (!this.user?.usertype) return 'EmptyPage';
            // return this.mainRouts[this.user?.usertype];
            return this.user?.usertype === 'advertiser' ? 'FinanceAdvertiser' : 'FinanceWebmaster';
        }
    },
};
</script>

<style scoped lang="scss">
.dashboard-page {
    background: #89123e;
}
</style>
