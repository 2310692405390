import sendRequest from "@/store/sendRequest";

export default {
    state: {
        finances: [],
        paymentsList: [],
        wallets: {},
    },

    mutations: {
        setFinances(state, finances) { state.finances = finances; },
        setPaymentsList(state, paymentsList) { state.paymentsList = paymentsList; },
        setWallets(state, wallets) { state.wallets = wallets; },
    },

    actions: {
        getFinances({commit}) {
            return new Promise((resolve, reject) => {
                sendRequest({method: 'get', endpoint: `/finances`}).then(response => {
                    commit('setFinances', response.data);

                    resolve(response.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        createPaymentRequest() {
            return new Promise((resolve, reject) => {
                sendRequest({method: 'post', endpoint: `/payout`}).then(response => {
                    resolve(response.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        getPaymentsList({commit}) {
            return new Promise((resolve, reject) => {
                sendRequest({method: 'get', endpoint: `/payments`}).then(response => {
                    commit('setPaymentsList', response.data);

                    resolve(response.data)
                }).catch((err) => {
                    commit('setPaymentsList', []);
                    reject(err)
                })
            })
        },

        // wallets
        getWallets({commit}) {
            return new Promise((resolve, reject) => {
                sendRequest({method: 'get', endpoint: `/wallets`}).then(response => {
                    commit('setWallets', response.data);

                    resolve(response.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        setWallet(c, {data}) {
            return new Promise((resolve, reject) => {
                sendRequest({method: 'post', endpoint: `/wallets`, data}).then(response => {
                    resolve(response.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
    }
}
