<template>
    <div class="body-wrap dashboard-page">
        <section class="dashboard">

            <MainSidebar/>

            <div class="dashboard__white_container">
                <div class="dashboard__white_nav">
                    <div class="dashboard__white_nav_left">
                        <div class="dashboard__white_nav_heading">
                            <span>Add a new site</span>
                        </div>
                    </div>
                </div>
                <div class="dashboard__sites_add_new">
                    <div class="dashboard__create_campaign">
                        <div class="dashboard__create_item">
                            <div class="dashboard__create_item_name"><span>Site</span></div>
                            <div class="dashboard__create_item_single_input">
                                <input
                                    class="add-new-site"
                                    id="addNewSite"
                                    type="text"
                                    name="add-new-site"
                                    placeholder="Enter Your link"
                                    v-model="formData.site"
                                >
                            </div>
                        </div>
                        <div class="dashboard__create_item">
                            <div class="dashboard__create_item_name"><span>Adult content</span></div>
                            <div class="dashboard__profile_settings_input_block">
                                <div class="dashboard__profile_settings_switcher">
                                    <label class="switch">
                                        <!--                                               :value="formData.themes[0] === 'adult'"-->
                                        <!--                                               @change="themeChange"-->
                                        <input type="checkbox"
                                               name="adult"
                                               v-model="adultSwitchValue"
                                        >
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="dashboard__create_item">
                            <div class="dashboard__create_item_name"><span>Format</span></div>
                            <div class="dashboard__create_item_single_select">
                                <select id="creativeTemplates" name="add-new-site-format" v-model="formData.format">
                                    <option value="inpagepush">Inpage Push</option>
                                </select>
                            </div>
                        </div>
                        <div class="dashboard__create_item">
                            <div class="dashboard__create_item_name"><span>Default code</span></div>
                            <div class="dashboard__create_item_single_input">
                                <textarea
                                    class="add-new-site"
                                    id="addNewSite"
                                    type="text"
                                    name="add-new-site"
                                    placeholder="<script>/* your default code */</script>"
                                    v-model="formData.default_code"
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="dashboard__create_item_btns">
                        <button class="dashboard__billing_btn" @click="$router.push('/sites')">
                            <span>Cancel</span>
                        </button>
                        <button class="dashboard__billing_btn blue" @click.prevent="createSite" :disabled="!formValide">
                            <ButtonSpinner v-if="requestLoading"/>
                            <span v-else>Save</span>
                        </button>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import MainSidebar from "../../components/Sidebar/MainSidebar.vue";
import ButtonSpinner from "@/components/UI/ButtonSpinner.vue";

export default {
    components: {
        ButtonSpinner,
        MainSidebar,
    },

    data() {
        return {
            requestLoading: false,

            adultSwitchValue: false,

            formData: {
                themes: [
                    "mainstream",
                    // "adult"
                ],
                site: '',
                format: 'inpagepush',
                default_code: ''
            },
        }
    },

    computed: {
        formValues() {
            return {
                ...this.formData,
                themes: [this.adultSwitchValue === true ? 'adult' : 'mainstream']
            }
        },

        formValuesValidation() {
            return {
                site: this.formData.site?.length >= 1,
                default_code: this.formData.default_code?.length >= 1,
            }
        },

        formValide() {
            return Object.values(this.formValuesValidation).every(valid => valid);
        },
    },

    methods: {
        createSite() {
            if (this.formValide) {
                this.requestLoading = true;

                this.$store.dispatch('createNewSite', {
                    data: this.formValues
                }).finally(() => {
                    setTimeout(() => {
                        this.requestLoading = false;
                        this.$router.push('/sites')
                    }, 1000)
                })
            }
        },
    },
};
</script>

<style scoped lang="scss">
.dashboard-page {
    background: #89123e;
}

textarea {
    position: relative;
    width: 100%;
    font-size: em(20);
    height: em(170,20);
    background: #ffffff;
    color: #344054;
    border: em(2,20) solid #D0D5DD;
    padding: em(18,20) em(20,20);
    border-radius: em(10,20);
    &:focus{
        outline: none;
    }
    &::placeholder{
        color: #667085;
    }
    &:hover{
        transition: .3s ease-in-out;
    }
    &.disabled{
        pointer-events: none;
        user-select: none;
        filter: grayscale(1);
    }
    &.erorr{
        border: em(2,20) solid #ff1f1f;
        color: #ff1f1f;
    }
    &:-webkit-autofill{
        color: #344054 !important;
    }
}
</style>
