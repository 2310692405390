import sendRequest from "@/store/sendRequest";

export default {
    state: {
        sitesList: [],
    },

    mutations: {
        setSitesList(state, sitesList) { state.sitesList = sitesList; },
    },

    actions: {
        getSites({commit}, {data}) {
            return new Promise((resolve, reject) => {
                sendRequest({method: 'get', endpoint: `/sites`, data}).then(response => {
                    commit('setSitesList', response.data);
                    resolve(response.data)
                }).catch((err) => {
                    commit('setSitesList', []);
                    reject(err)
                })
            })
        },

        createNewSite(context, {data}) {
            return new Promise((resolve, reject) => {
                sendRequest({method: 'post', endpoint: `/site`, data}).then(response => {
                    resolve(response.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        saveSite(context, {data, id}) {
            return new Promise((resolve, reject) => {
                sendRequest({method: 'post', endpoint: `/site/${id}`, data}).then(response => {
                    resolve(response.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        getSite(context, {data}) {
            return new Promise((resolve, reject) => {
                sendRequest({method: 'get', endpoint: `/site/${data.id}`}).then(response => {
                    resolve(response.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        getSiteCode(context, {data}) {
            return new Promise((resolve, reject) => {
                sendRequest({method: 'get', endpoint: `/sitecode/${data.id}`}).then(response => {
                    resolve(response.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        //
        // getCampaignById({commit}, {id}) {
        //     const url = API_URL+`/campaign/${id}`;
        //
        //     axios.get(url, {
        //         headers: {
        //             Authorization: `Basic ${localStorage.getItem('auth')}`,
        //         },
        //     }).then((response) => {
        //         commit('setCampaign', response.data);
        //     });
        // },
    }
}
