import sendRequest from "@/store/sendRequest";

export default {
    state: {
        listings: {},
    },

    mutations: {
        setListings(state, listings)                { state.listings = listings; },
    },

    actions: {
        getListings({commit}) {
            return new Promise((resolve, reject) => {
                sendRequest({method: 'get', endpoint: `/listings`}).then(response => {
                    commit('setListings', response.data);
                    resolve(response.data)
                }).catch((err) => {
                    commit('setListings', {});
                    reject(err)
                })
            })
        },
    }
}
