<template>
    <loader
        object="#1570EF"
        size="3"
        speed="1"
        name="dots"
        class="loader"
    ></loader>
</template>

<script>
export default {
    name: 'DataLoader',
}
</script>

<style lang="scss">
.loader {
    position: relative;
    width: 100%;
    min-height: 8em;

    #overlay-dots {
        background: transparent;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
}
</style>
