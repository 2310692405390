import sendRequest from "@/store/sendRequest";

export default {
    state: {
        balance: {},
    },

    mutations: {
        setBalance(state, balance) {
            state.balance = balance;
        },
    },

    actions: {
        getBalance({commit}) {
            return new Promise((resolve, reject) => {
                sendRequest({method: 'get', endpoint: `/balance`}).then(response => {
                    commit('setBalance', response.data);

                    resolve(response.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
    }
}
