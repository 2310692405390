<template>
  <div class="body-wrap">
    <section class="section-404">
      <div class="section-404__shadow_top"> <img src="img/404/shadow-top.png" alt=""></div>
      <div class="section-404__text_block">
        <router-link to="/" class="section-404__logo">
          <img src="img/header/logo.svg" alt="">
        </router-link>

        <div class="section-404__text_1"> <span> <span>Hey bro,</span><br>I can't find <br>this page</span></div>
        <div class="section-404__text_2"><span class="link-text">{{currentPage}}</span><span>is not found on this site</span></div>

        <router-link to="/" class="section-404__btn">
          <span>Go back to main page</span>
        </router-link>
      </div>
      <div class="section-404__image_block">
        <div class="section-404__image"><img src="img/404/main-image.png" alt=""></div>
        <div class="section-404__bg_item_1">
          <div class="parallax">
            <div class="dop-block" data-depth="-1.2"> <img src="img/404/bg-item-1.png" alt=""></div>
          </div>
        </div>
        <div class="section-404__bg_item_2">
          <div class="parallax">
            <div class="dop-block" data-depth="1.2"> <img src="img/404/bg-item-2.png" alt=""></div>
          </div>
        </div>
        <div class="section-404__bg_item_3">
          <div class="parallax">
            <div class="dop-block" data-depth="-0.8">  <img src="img/404/bg-item-3.png" alt=""></div>
          </div>
        </div>
      </div>
    </section>
    <div class="modal-window modal-review">
      <div class="modal">
        <div class="modal__close close"></div>
        <div class="modal__heading"> <span>Оставить отзыв</span></div>
        <div class="modal__form_block">
          <form class="form" id="reviewForm" action="">
            <div class="form__doble_input">
              <div class="form__wrapper notwide"><span class="error-item">Maybe You did some mistake</span>
                <input class="form__input form__name" id="name" type="text" name="name" placeholder="Vorname" data-name="name" data-valid="false">
              </div>
              <div class="form__wrapper notwide"><span class="error-item">Maybe You did some mistake</span>
                <input class="form__input form__surname" id="surname" type="text" name="surname" placeholder="Nachname" data-name="name" data-valid="false">
              </div>
            </div>
            <div class="form__wrapper">
              <textarea class="form__message" name="textarea" placeholder="Comment"></textarea>
            </div>
            <div class="form__stars_block">
              <div class="form__stars_heading"> <span>Service level</span></div>
              <div class="form__stars">
                <input id="star5" type="radio" name="star" value="5">
                <label class="star-5" for="star5">
                  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewbox="0 0 50 50" fill="none">
                    <path d="M23.9592 4.92986C24.2878 3.91216 25.7115 3.91216 26.0401 4.92986L29.8333 16.6785C30.2016 17.8192 31.2601 18.5964 32.4598 18.5964H44.7348C45.7843 18.5964 46.2461 19.9639 45.3761 20.6L35.4454 27.8611C34.4781 28.5683 34.0759 29.8191 34.4439 30.9589L38.2371 42.7076C38.5721 43.745 37.3983 44.5642 36.5554 43.9479L26.6247 36.6868C25.6561 35.9786 24.3433 35.9786 23.3747 36.6868L13.4439 43.9479C12.601 44.5642 11.4273 43.745 11.7622 42.7075L15.5554 30.9589C15.9235 29.8191 15.5213 28.5683 14.554 27.8611L14.0621 28.5338L14.554 27.8611L4.62323 20.6C3.75325 19.9639 4.21505 18.5964 5.26451 18.5964H17.5396C18.7392 18.5964 19.7977 17.8192 20.166 16.6785L23.9592 4.92986Z" stroke="#ABABAB" stroke-width="1.66667"></path>
                  </svg>
                </label>
                <input id="star4" type="radio" name="star" value="4">
                <label class="star-4" for="star4">
                  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewbox="0 0 50 50" fill="none">
                    <path d="M23.9592 4.92986C24.2878 3.91216 25.7115 3.91216 26.0401 4.92986L29.8333 16.6785C30.2016 17.8192 31.2601 18.5964 32.4598 18.5964H44.7348C45.7843 18.5964 46.2461 19.9639 45.3761 20.6L35.4454 27.8611C34.4781 28.5683 34.0759 29.8191 34.4439 30.9589L38.2371 42.7076C38.5721 43.745 37.3983 44.5642 36.5554 43.9479L26.6247 36.6868C25.6561 35.9786 24.3433 35.9786 23.3747 36.6868L13.4439 43.9479C12.601 44.5642 11.4273 43.745 11.7622 42.7075L15.5554 30.9589C15.9235 29.8191 15.5213 28.5683 14.554 27.8611L14.0621 28.5338L14.554 27.8611L4.62323 20.6C3.75325 19.9639 4.21505 18.5964 5.26451 18.5964H17.5396C18.7392 18.5964 19.7977 17.8192 20.166 16.6785L23.9592 4.92986Z" stroke="#ABABAB" stroke-width="1.66667"></path>
                  </svg>
                </label>
                <input id="star3" type="radio" name="star" value="3">
                <label class="star-3" for="star3">
                  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewbox="0 0 50 50" fill="none">
                    <path d="M23.9592 4.92986C24.2878 3.91216 25.7115 3.91216 26.0401 4.92986L29.8333 16.6785C30.2016 17.8192 31.2601 18.5964 32.4598 18.5964H44.7348C45.7843 18.5964 46.2461 19.9639 45.3761 20.6L35.4454 27.8611C34.4781 28.5683 34.0759 29.8191 34.4439 30.9589L38.2371 42.7076C38.5721 43.745 37.3983 44.5642 36.5554 43.9479L26.6247 36.6868C25.6561 35.9786 24.3433 35.9786 23.3747 36.6868L13.4439 43.9479C12.601 44.5642 11.4273 43.745 11.7622 42.7075L15.5554 30.9589C15.9235 29.8191 15.5213 28.5683 14.554 27.8611L14.0621 28.5338L14.554 27.8611L4.62323 20.6C3.75325 19.9639 4.21505 18.5964 5.26451 18.5964H17.5396C18.7392 18.5964 19.7977 17.8192 20.166 16.6785L23.9592 4.92986Z" stroke="#ABABAB" stroke-width="1.66667"></path>
                  </svg>
                </label>
                <input id="star2" type="radio" name="star" value="2">
                <label class="star-2" for="star2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewbox="0 0 50 50" fill="none">
                    <path d="M23.9592 4.92986C24.2878 3.91216 25.7115 3.91216 26.0401 4.92986L29.8333 16.6785C30.2016 17.8192 31.2601 18.5964 32.4598 18.5964H44.7348C45.7843 18.5964 46.2461 19.9639 45.3761 20.6L35.4454 27.8611C34.4781 28.5683 34.0759 29.8191 34.4439 30.9589L38.2371 42.7076C38.5721 43.745 37.3983 44.5642 36.5554 43.9479L26.6247 36.6868C25.6561 35.9786 24.3433 35.9786 23.3747 36.6868L13.4439 43.9479C12.601 44.5642 11.4273 43.745 11.7622 42.7075L15.5554 30.9589C15.9235 29.8191 15.5213 28.5683 14.554 27.8611L14.0621 28.5338L14.554 27.8611L4.62323 20.6C3.75325 19.9639 4.21505 18.5964 5.26451 18.5964H17.5396C18.7392 18.5964 19.7977 17.8192 20.166 16.6785L23.9592 4.92986Z" stroke="#ABABAB" stroke-width="1.66667"></path>
                  </svg>
                </label>
                <input id="star1" type="radio" name="star" value="1">
                <label class="star-1" for="star1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewbox="0 0 50 50" fill="none">
                    <path d="M23.9592 4.92986C24.2878 3.91216 25.7115 3.91216 26.0401 4.92986L29.8333 16.6785C30.2016 17.8192 31.2601 18.5964 32.4598 18.5964H44.7348C45.7843 18.5964 46.2461 19.9639 45.3761 20.6L35.4454 27.8611C34.4781 28.5683 34.0759 29.8191 34.4439 30.9589L38.2371 42.7076C38.5721 43.745 37.3983 44.5642 36.5554 43.9479L26.6247 36.6868C25.6561 35.9786 24.3433 35.9786 23.3747 36.6868L13.4439 43.9479C12.601 44.5642 11.4273 43.745 11.7622 42.7075L15.5554 30.9589C15.9235 29.8191 15.5213 28.5683 14.554 27.8611L14.0621 28.5338L14.554 27.8611L4.62323 20.6C3.75325 19.9639 4.21505 18.5964 5.26451 18.5964H17.5396C18.7392 18.5964 19.7977 17.8192 20.166 16.6785L23.9592 4.92986Z" stroke="#ABABAB" stroke-width="1.66667"></path>
                  </svg>
                </label>
              </div>
            </div>
            <button class="form__btn" id="formBtn" type="submit" disabled><span>Оставить отзыв</span></button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Error_404",

  computed: {
    currentPage() {
      return window.location.href
    }
  }
}
</script>
