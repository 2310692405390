<template>
    <div class="body-wrap dashboard-page">
        <section class="dashboard">
            <MainSidebar v-if="user.id !== undefined" />
            <SecondSidebar v-else/>

            <div class="dashboard__white_container">
                <div class="dashboard__white_nav">
                    <div class="dashboard__white_nav_left">
                        <div class="dashboard__white_nav_heading"><span>Privacy policy</span></div>
                    </div>
                </div>
                <div class="dashboard__privacy_block">
                    <p class="blue">Current as of 20 Jan 2022</p>
                    <p>Mi tincidunt elit, id quisque ligula ac diam, amet. Vel etiam suspendisse morbi eleifend faucibus
                        eget vestibulum felis. Dictum quis montes, sit sit. Tellus aliquam enim urna, etiam. Mauris
                        posuere vulputate arcu amet, vitae nisi, tellus tincidunt. At feugiat sapien varius id.</p>
                    <p>Eget quis mi enim, leo lacinia pharetra, semper. Eget in volutpat mollis at volutpat lectus
                        velit, sed auctor. Porttitor fames arcu quis fusce augue enim. Quis at habitant diam at.
                        Suscipit tristique risus, at donec. In turpis vel et quam imperdiet. Ipsum molestie aliquet
                        sodales id est ac volutpat. </p>
                    <h2>What information do we collect?</h2>
                    <p>Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla odio nisl vitae. In aliquet
                        pellentesque aenean hac vestibulum turpis mi bibendum diam. Tempor integer aliquam in vitae
                        malesuada fringilla.</p>
                    <p>Elit nisi in eleifend sed nisi. Pulvinar at orci, proin imperdiet commodo consectetur convallis
                        risus. Sed condimentum enim dignissim adipiscing faucibus consequat, urna. Viverra purus et erat
                        auctor aliquam. Risus, volutpat vulputate posuere purus sit congue convallis aliquet. Arcu id
                        augue ut feugiat donec porttitor neque. Mauris, neque ultricies eu vestibulum, bibendum quam
                        lorem id. Dolor lacus, eget nunc lectus in tellus, pharetra, porttitor.</p>
                    <p>Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris id. Non pellentesque congue
                        eget consectetur turpis. Sapien, dictum molestie sem tempor. Diam elit, orci, tincidunt aenean
                        tempus. Quis velit eget ut tortor tellus. Sed vel, congue felis elit erat nam nibh orci.</p>
                    <p>Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla odio nisl vitae. In aliquet
                        pellentesque aenean hac vestibulum turpis mi bibendum diam. Tempor integer aliquam in vitae
                        malesuada fringilla.</p>
                    <p>Elit nisi in eleifend sed nisi. Pulvinar at orci, proin imperdiet commodo consectetur convallis
                        risus. Sed condimentum enim dignissim adipiscing faucibus consequat, urna. Viverra purus et erat
                        auctor aliquam. Risus, volutpat vulputate posuere purus sit congue convallis aliquet. Arcu id
                        augue ut feugiat donec porttitor neque. Mauris, neque ultricies eu vestibulum, bibendum quam
                        lorem id. Dolor lacus, eget nunc lectus in tellus, pharetra, porttitor.</p>
                    <p>Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris id. Non pellentesque congue
                        eget consectetur turpis. Sapien, dictum molestie sem tempor. Diam elit, orci, tincidunt aenean
                        tempus. Quis velit eget ut tortor tellus. Sed vel, congue felis elit erat nam nibh orci.</p>
                    <h2>How do we use your information?</h2>
                    <p>Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla odio nisl vitae. In aliquet
                        pellentesque aenean hac vestibulum turpis mi bibendum diam. Tempor integer aliquam in vitae
                        malesuada fringilla.</p>
                    <p>Elit nisi in eleifend sed nisi. Pulvinar at orci, proin imperdiet commodo consectetur convallis
                        risus. Sed condimentum enim dignissim adipiscing faucibus consequat, urna. Viverra purus et erat
                        auctor aliquam. Risus, volutpat vulputate posuere purus sit congue convallis aliquet. Arcu id
                        augue ut feugiat donec porttitor neque. Mauris, neque ultricies eu vestibulum, bibendum quam
                        lorem id. Dolor lacus, eget nunc lectus in tellus, pharetra, porttitor.</p>
                    <p>Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris id. Non pellentesque congue
                        eget consectetur turpis. Sapien, dictum molestie sem tempor. Diam elit, orci, tincidunt aenean
                        tempus. Quis velit eget ut tortor tellus. Sed vel, congue felis elit erat nam nibh orci.</p>
                    <h3>Do we use cookies and other tracking technologies?</h3>
                    <p>Pharetra morbi libero id aliquam elit massa integer tellus. Quis felis aliquam ullamcorper
                        porttitor. Pulvinar ullamcorper sit dictumst ut eget a, elementum eu. Maecenas est morbi mattis
                        id in ac pellentesque ac.</p>
                    <h3>How long do we keep your information?</h3>
                    <p>Pharetra morbi libero id aliquam elit massa integer tellus. Quis felis aliquam ullamcorper
                        porttitor. Pulvinar ullamcorper sit dictumst ut eget a, elementum eu. Maecenas est morbi mattis
                        id in ac pellentesque ac.</p>
                    <h3>How do we keep your information safe?</h3>
                    <p>Pharetra morbi libero id aliquam elit massa integer tellus. Quis felis aliquam ullamcorper
                        porttitor. Pulvinar ullamcorper sit dictumst ut eget a, elementum eu. Maecenas est morbi mattis
                        id in ac pellentesque ac.</p>
                    <h3>What are your privacy rights?</h3>
                    <p>Pharetra morbi libero id aliquam elit massa integer tellus. Quis felis aliquam ullamcorper
                        porttitor. Pulvinar ullamcorper sit dictumst ut eget a, elementum eu. Maecenas est morbi mattis
                        id in ac pellentesque ac.</p>
                    <h3>How can you contact us about this policy?</h3>
                    <p>Sagittis et eu at elementum, quis in. Proin praesent volutpat egestas sociis sit lorem nunc nunc
                        sit. Eget diam curabitur mi ac. Auctor rutrum lacus malesuada massa ornare et. Vulputate
                        consectetur ac ultrices at diam dui eget fringilla tincidunt. Arcu sit dignissim massa erat
                        cursus vulputate gravida id. Sed quis auctor vulputate hac elementum gravida cursus dis.</p>
                    <ol>
                        <li>Lectus id duis vitae porttitor enim <a href="#">gravida morbi.</a></li>
                        <li>Eu turpis <a href="#">posuere semper feugiat</a> volutpat elit, ultrices suspendisse. Auctor
                            vel in vitae placerat.
                        </li>
                        <li>Suspendisse maecenas ac <a href="#">donec scelerisque</a> diam sed est duis purus.</li>
                    </ol>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import MainSidebar from '../../components/Sidebar/MainSidebar.vue';
import SecondSidebar from '../../components/Sidebar/SecondSidebar.vue';
import {mapState} from "vuex";

export default {
    components: {
        MainSidebar,
        SecondSidebar
    },
    computed: {
        ...mapState({
            user: state => state.user.user
        }),
    },
};
</script>

<style scoped lang="scss">
.dashboard-page {
    background: #89123e;
}
</style>
