<template>
    <div class="icon">
        <svg v-if="iconName === 'arrowUp'" width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.3334 21.6112V6.05566M13.3334 6.05566L5.55566 13.8334M13.3334 6.05566L21.1112 13.8334" stroke="#12B76A" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <svg v-if="iconName === 'arrowDown'" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.1108 6.05566V21.6112M14.1108 21.6112L21.8886 13.8334M14.1108 21.6112L6.33301 13.8334" stroke="#F04438" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </div>
</template>

<script>
export default {
    name: "SvgIcon",

    props: [
        'iconName'
    ]
}
</script>

<style scoped lang="scss">
.icon {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        width: 1.15em;
        min-width: 1.15em;
        max-width: 1.15em;

        min-height: 1.3em;
        max-height: 1.3em;
    }

    //@media (max-width: 1024px) and (orientation: portrait) {
    //    font-size: 3em;
    //}
}
</style>
