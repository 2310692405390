<template>
    <div class="date-time-picker__days">
        <button class="date-time-picker__days_title"
        >
<!--            @click="$emit('change', 'all')"-->
            <span class="desktop">All days of week</span>
            <span class="mobile">Day</span>
        </button>

        <button v-for="(day, index) in daysList"
            v-bind:key="day"
            class="date-time-picker__day"
            @click="$emit('change', index+1)"
        >
           <span>{{day}}</span>
        </button>
    </div>
</template>

<script>

export default {
    name: 'DTPDays',

    data() {
        return {
            daysList: [
                'Mon',
                'Tue',
                'Wed',
                'Thu',
                'Fri',
                'Sat',
                'Sun'
            ],
            checkedDays: []
        }
    }
}
</script>

<style lang="scss" scoped>
.date-time-picker {
    &__days {
        border-right: .1em solid #EAECF0;
        display: flex;
        flex-direction: column;
        min-width: 11em;

        &_title {
            padding: .7em .2em;
            border-bottom: .1em solid #EAECF0;
            background: transparent;
            transition: background .2s ease-in-out;
            height: 2.4em;
            cursor: auto;

            .mobile {
                display: none;
            }

            //&:hover {
            //    background: #EAECF0;
            //}

            span {
                font-size: 1.1em;
                color: #344054;
                font-weight: 600;
            }
        }

        div:first-child button {
            margin-right: .8em;
        }
    }

    &__day {
        padding: .7em 1.2em;
        text-align: left;
        background: transparent;
        transition: background .2s ease-in-out;
        height: 2.4em;
        cursor: auto;
        //
        //&:hover {
        //    background: #EAECF0;
        //}

        span {
            font-size: 1.1em;
            color: #344054;
            font-weight: 400;
        }
    }

    @media(max-width: 1024px) and (orientation: portrait) {
        &__days {
            flex-direction: row;
            min-width: 100%;

            &_title {
                padding: 0.7em 0.9em;
                border-right: .1em solid #EAECF0;

                .desktop {
                    display: none;
                }

                .mobile {
                    display: block;
                }
            }
        }

        &__day {
            width: 3.6em;
            padding: 0.7em 0.85em;
            border-bottom: .1em solid #EAECF0;
        }
    }
}
</style>
