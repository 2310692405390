import sendRequest from "@/store/sendRequest";

export default {
    actions: {
        login() {
            return new Promise((resolve, reject) => {
                sendRequest({method: 'get', endpoint: `/auth`}).then(response => {
                    if (response.status === 'success') {
                        resolve()
                    }
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        signup(context, {data}) {
            return new Promise((resolve, reject) => {
                sendRequest({method: 'post', endpoint: `/registration`, data}).then(response => {
                    // if (response.status === 'success')
                    resolve(response.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        forgot(context, {data}) {
            return new Promise((resolve, reject) => {
                sendRequest({method: 'post', endpoint: `/forgot`, data}).then(response => {
                    // if (response.status === 'success')
                    resolve(response.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
    }
}
