<template>
    <div class="dashboard__date_btn"><img src="img/dashboard/calendar.svg" alt="">
        <span>{{ dateFrom }} – {{ dateTo }}</span>

<!--        <div class="rangePickerWrapper"-->
<!--            ref="rangePickerWrapper"-->
<!--        >-->
            <AntRangePicker
                v-model="value"
                ref="picker"
                @focus="updateScale"
                @change="onChange"
                class="datePicker"
                dropdownClassName="vueRangePicker"
                :popupStyle="{
                    transform: `scale(${popupScale})`,
                    padding: `1rem 0`
                }"
            />
<!--        </div>-->
    </div>
</template>

<script>
import { RangePicker as AntRangePicker } from "ant-design-vue";
import moment from 'moment';

export default {
    name: 'RangePicker',

    data() {
        return {
            maxWidth: 560,
            value: [],
            popupScale: 1
        }
    },

    computed: {
        dateFrom() {
            return this.value[0] !== undefined && this.value[0].format !== undefined ? this.value[0].format('DD.MM.YYYY') : '01.01.2024';
        },

        dateTo() {
            return this.value[1] !== undefined && this.value[1].format !== undefined ? this.value[1].format('DD.MM.YYYY') : '29.01.2024';
        },
    },

    methods: {
        getOrientation() {
            const orientation = window.screen.orientation.type;

            if (orientation === "portrait-primary") {
                return 'portrait';
            } else if (orientation === "landscape-primary") {
                return 'landscape';
            }
        },

        onChange(data) {
            this.value = data;
            this.$emit('change', data)
        },

        onResize() {
            const isMobile = (window.innerWidth <= 1024) && (window.innerWidth < window.innerHeight)
            const koef = !isMobile ? .1 : .3;

            this.bodyFontSize = +Number(window.getComputedStyle(document.body).fontSize.replace('px', '')).toFixed()*koef;
            this.options.plugins.emptyDoughnut.width = (this.bodyFontSize*30).toFixed();
            this.options.plugins.emptyDoughnut.radiusDecrease = (20*this.bodyFontSize).toFixed();
        },

        updateScale() {
            if (!document.querySelector('.vueRangePicker') || this.getOrientation() === 'portrait') return;
            
            document.querySelector('.vueRangePicker .ant-picker-range-wrapper').style.justifyContent = 'flex-end';
            document.querySelector('.vueRangePicker .ant-picker-range-wrapper').style.zoom = (window.innerWidth*.4 / this.maxWidth).toFixed(1);
        }
    },

    components: {
        AntRangePicker
    },

    mounted() {
        this.onChange([
            moment().date(-30*2), // current date - 2 month
            moment(), // current date
        ])

        this.updateScale();
    }
}
</script>

<style>
.datePicker {
    border: '1px solid #1890ff';
    border-radius: '50%';
    width: 20em;

    input , .ant-picker-separator {
        pointer-events: none;
    }

    @media(orientation: 'portrait') {
        :global .ant-picker-panels {
          flex-direction: column !important;
        }
    }
}
</style>
