<template>
    <div class="dashboard__no_matches">
        <div class="dashboard__no_matches_icon"> <img src="img/statistic/nomatches.svg" alt=""></div>
        <div class="dashboard__no_matches_heading">
            <span>Not found</span>
        </div>
        <div class="dashboard__no_matches_text">
            <span>Your search did not match <br>Please try again</span>
        </div>
        <div class="dashboard__no_matches_btns">
            <button class="dashboard__no_matches_btn clear" @click="$emit('clear')">
                <span>Clear search</span>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'EmptyMessage',
}
</script>

<style lang="scss">
.dashboard__no_matches_btns {
    justify-content: center !important;
    padding-bottom: 2em;
}
</style>
