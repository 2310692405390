import axios from 'axios';
import { API_URL } from '@/api';
import { notification } from 'ant-design-vue';

// function paramsSerializer(params) {
//     // "Hide" the `answer` param
//     return Object.entries(Object.assign({}, params)).
//     map(([key, value]) => `${key}=${value}`).
//     join('&');
// }

export const paramsSerializer = (obj) => {
    let str = [];
    for (let p in obj) {
        if (Array.isArray(obj[p])) {
            console.log(obj[p])
            for (let i = 0; i < obj[p].length; i++) {
                str.push(encodeURIComponent(p) + "[]=" + encodeURIComponent(obj[p][i]));
            }
        } else if (typeof obj[p] === 'object') {
            Object.keys(obj[p]).forEach(key => {
                if (Array.isArray(obj[p][key])) {
                    str.push(encodeURIComponent(p) + `[${key}][]=` + encodeURIComponent(obj[p][key]));
                } else {
                    str.push(encodeURIComponent(p) + `[${key}]=` + encodeURIComponent(obj[p][key]));
                }
            })
        } else {
            if (Object.hasOwnProperty.call(obj, p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        }
    }
    return str.join("&");
}

export const sendRequest = ({method, endpoint, data = {}, headers = {}}) => {
    const url = API_URL+endpoint;

    return new Promise((resolve, reject) => {
        const params = Object.keys(data).length ? `?${paramsSerializer(data)}` : '' ;

        axios[method](url+params, {
            headers: {
                Authorization: `Basic ${localStorage.getItem('auth')}`,
                ...headers
            },
        }).then((response) => {
            if (response.data.status === 'success') {
                if (response.data.message) {
                    notification.success({
                        message: 'Congratulations!',
                        description: response.data.message,
                    })
                }

                resolve(response.data);
            } else {
                reject(response.data)
            }
        }).catch((error) => {
            if (error.response?.data?.message !== 'No sites available'
                || error.response?.data?.message !== 'No statistics, try changing the filter') {
                reject(error);

                return;
            }

            notification.error({
                message: 'Error',
                description: error.response.data.message,
            })

            if (error.response.data.message === "Authorization lost") {
                window.location = '/#/login';
            }

            reject(error)
        })
    })
}

export const sendFileRequest = ({method, endpoint, data = {}, body = {}, headers = {}}) => {
    const url = API_URL+endpoint;

    return new Promise((resolve, reject) => {
        const params = Object.keys(data).length ? `?${paramsSerializer(data)}` : '' ;

        axios[method](url+params, body, {
            headers,
        }).then((response) => {
            if (response.data.status === 'success') {
                // if (response.data.message) {
                //     notification.success({
                //         message: 'Congratulations!',
                //         description: response.data.message,
                //     })
                // }

                resolve(response.data);
            } else {
                reject(response.data)
            }
        }).catch((error) => {
            if (error.response?.data?.message !== 'No sites available'
                || error.response?.data?.message !== 'No statistics, try changing the filter') {
                reject(error);

                return;
            }

            notification.error({
                message: 'Error',
                description: error.response.data.message,
            })

            if (error.response.data.message === "Authorization lost") {
                window.location = '/#/login';
            }

            reject(error)
        })
    })
}

export default sendRequest;
