<template>
    <div class="body-wrap dashboard-page">
        <section class="dashboard">
            <MainSidebar/>

            <div class="dashboard__white_container">
                <div class="dashboard__white_nav">
                    <div class="dashboard__white_nav_left">
                        <div class="dashboard__white_nav_heading">
                            <span>Advertising campaigns</span>
                        </div>
                        <div class="dashboard__white_nav_subheading">
                            <span>Select period and filters to view data</span>
                        </div>
                    </div>
                    <div class="dashboard__white_nav_right">
                        <div class="dashboard__white_nav_price">
                            <span>Your balance:</span>
                            <span>{{ balance?.currentBalance }}$</span>
                        </div>
                        <router-link to="/finance" class="dashboard__white_nav_add_funds">
                            <img src="img/dashboard/plus.svg" alt="">
                            <span>Add funds </span>
                        </router-link>
                    </div>
                </div>

                <div class="dashboard__search_block">
                    <div class="dashboard__search">
                        <input class="search" type="text" name="search" placeholder="Search for ID or name"
                               v-model="searchValue">
                    </div>
                    <div class="dashboard__filter_block">
                        <router-link to="advertising-campaigns/new">
                            <div class="dashboard__date_btn create-campaign"><span>Сreate new campaign</span></div>
                        </router-link>

                        <!--                        <RangePicker @change="filter" />-->
                    </div>
                </div>

                <div class="dashboard__adv_cam_items">
                    <div class="dashboard__adv_cam_item name-item">
                        <div class="dashboard__adv_cam_item_idname"><span>ID/CAMPAIGN NAME</span></div>
                        <div class="dashboard__adv_cam_item_format"><span>FORMAT</span></div>
                        <div class="dashboard__adv_cam_item_status"><span>STATUS</span></div>
                        <div class="dashboard__adv_cam_item_bid">
                            <!--                            <span>BID</span>-->
                        </div>
                        <div class="dashboard__adv_cam_item_spent">
                            <!--                            <span>SPENT</span>-->
                        </div>
                        <div class="dashboard__adv_cam_item_control"><span>CONTROL</span></div>
                    </div>

                    <DataLoader v-if="requestLoading"/>

                    <template v-else>
                        <EmptyMessage v-if="filteredList.length === 0"
                                      @clear="initCampaignList"
                        />

                        <div v-else
                             v-for="campaign in itemsList"
                             :key="campaign.id"
                             class="dashboard__adv_cam_item"
                        >
                            <div class="dashboard__adv_cam_item_idname_text">
                                <span>{{ campaign.id }}</span>
                                <span>{{ campaign.name }}</span></div>
                            <div class="dashboard__adv_cam_item_format_text">
                                <span>{{ campaign.format }}</span>
                            </div>
                            <div class="dashboard__adv_cam_item_status_text" :class="{
                                active: campaign.active === '',
                                pause: campaign.active === 'paused' || campaign.active === 'moderation',
                                stop: campaign.active === 'stop',
                            }">
                                <span>{{ campaign.active }}</span>
                            </div>
                            <div class="dashboard__adv_cam_item_bid_text">
                                <!--              <span>423 523 562</span>-->
                            </div>
                            <div class="dashboard__adv_cam_item_spent_text">
                                <!--              <span>1000</span>-->
                            </div>
                            <div class="dashboard__adv_cam_item_control_text">
<!--                              <template v-if="campaign.active === 'moderation'">-->
                                    <div class="dashboard__pause">
                                        <img :src="`img/advertising-campaigns/play.svg`"
                                             alt=""
                                             :disabled="campaign.active === 'active'"
                                             @click="() => playСampaign(campaign)"
                                        >
                                    </div>
                                    <div class="dashboard__pause">
                                        <img :src="`img/advertising-campaigns/pause.svg`"
                                            alt="play"
                                            :disabled="campaign.active !== 'active'"
                                            @click="() => pauseСampaign(campaign)"
                                        >
                                    </div>
                                <!--                                      <div class="dashboard__stop"-->
                                <!--                                           @click="() => pauseСampaign(campaign)">-->
                                <!--                                          <img src="img/advertising-campaigns/stop.svg" alt="" @click="stopСampaign">-->
                                <!--                                      </div>-->
<!--                              </template>-->
                                <router-link :to="`/advertising-campaign/${campaign.id}`"
                                             class="dashboard__edit"
                                >
                                    <img src="img/advertising-campaigns/edit.svg" alt="">
                                </router-link>
                            </div>
                        </div>
                    </template>

                    <Pagination
                        :currentPage="currentPage"
                        :totalItems="filteredList.length"
                        :pageSize="pageSize"
                        @update="updatePage"
                    />
                </div>

                <Pagination
                    :mobile="true"
                    :currentPage="currentPage"
                    :totalItems="filteredList.length"
                    :pageSize="pageSize"
                    @update="updatePage"
                />
            </div>
        </section>
    </div>
</template>

<script>
import MainSidebar from '../../components/Sidebar/MainSidebar.vue';
import {mapState} from "vuex";
import Pagination from "@/components/Pagination/Pagination.vue";
import EmptyMessage from "@/components/UI/EmptyMessage.vue";
import DataLoader from "@/components/UI/DataLoader.vue";
// import RangePicker from "@/components/UI/RangePicker.vue";

export default {
    components: {
        DataLoader,
        MainSidebar,
        Pagination,
        EmptyMessage,
        // RangePicker,
    },

    data() {
        return {
            requestLoading: false,

            searchValue: '',

            currentPage: 1,
            pageSize: 10,
        }
    },

    computed: {
        ...mapState({
            campaignsList: (state) => state.campaigns.campaignsList,
            // balance
            balance: state => state.balance.balance,
        }),

        filteredList() {
            if (this.searchValue === '') return this.campaignsList;
            return this.campaignsList.filter(c => c.id.toLowerCase().includes(this.searchValue.toLowerCase()) || c.name.toLowerCase().includes(this.searchValue.toLowerCase()));
        },

        itemsList() {
            return this.filteredList.slice(this.pageSize * (this.currentPage - 1 ?? 0), this.pageSize * this.currentPage)
        }
    },

    methods: {
        updatePage(current) {
            this.requestLoading = true;
            this.currentPage = current;

            setTimeout(() => {
                this.requestLoading = false;
            }, 1000)
        },

        initCampaignList() {
            this.searchValue = '';

            this.requestLoading = true;

            this.$store.dispatch('getCampaigns', {data: {}}).finally(() => {
                setTimeout(() => {
                    this.requestLoading = false;
                    this.currentPage = 1;
                }, 1000)
            })
        },
        // filter([from, to]) {
        //     this.requestLoading = true;
        //
        //     this.$store.dispatch('getCampaigns', {
        //             data: {
        //                 from: from.format('DD.MM.YYYY'),
        //                 to: to.format('DD.MM.YYYY')
        //             }
        //         }).finally(() => {
        //         setTimeout(() => {
        //             this.requestLoading = false;
        //             this.currentPage = 1;
        //         }, 1000)
        //     })
        // },

        playСampaign(camp) {
            const data = {
                elementtype: 'campaign',
                elementid: camp.id,
                command: 'toplay',
                sing: camp.pauseSing,
            };

            this.$store.dispatch('pauseCampaign', data).then(() => {
                this.initCampaignList()
            })
        },

        pauseСampaign(camp) {
            const data = {
                elementtype: 'campaign',
                elementid: camp.id,
                command: 'topause',
                sing: camp.pauseSing,
            };

            this.$store.dispatch('pauseCampaign', data).then(() => {
                this.initCampaignList()
            })
        }
    },

    created() {
        this.initCampaignList();
        this.$store.dispatch('getBalance');
    }
};
</script>

<style scoped lang="scss">
.dashboard-page {
    background: #89123e;
}
</style>
