<template>
  <div class="body-wrap full-height-page">
    <section class="login">
      <div class="login__text_block">
        <a class="login__logo" href="#"
          ><img src="img/header/logo.svg" alt=""
        /></a>
        <div class="login__form_block" :class="status === 'sended' && 'hide'">
          <div class="login__form_text_1"><span>Reset your password</span></div>
          <div class="login__form_text_2">
            <span
              >Enter your email address and we will send you<br />instructions
              to reset your password.</span
            >
          </div>
          <div class="form" id="ResetPassForm" action="#">
            <div class="form__wrapper">
              <label for="email">Email</label>
              <input
                class="form__input"
                id="Email"
                type="email"
                name="Email"
                placeholder="you@company.com"
                data-name="Email"
                data-valid="false"
                v-model="formData.login"
              /><span class="error-item">Invalid format of Email</span>
            </div>
            <button
              class="form__login_btn"
              id="loginBtn"
              type="submit"
              :disabled="errors"
              @click="submit"
            >
              <span>Continue</span>
            </button>
          </div>

          <div class="login__sign_up">
            <router-link to="/login">
              Back to Login
            </router-link>
          </div>
        </div>

        <div class="login__form_block" :class="status === 'new' && 'hide'">
          <div class="login__form_text_1"><span>Check Your Email</span></div>
          <div class="login__form_text_2">
            <span
              >Please check the email address
              <span class="email">example@gmail.com </span>for instructions to
              reset your password.</span
            >
          </div>
          <a class="login__resend_email_btn" href="#" @click.prevent="status = 'new'">
            <span>Resend email </span>
          </a>
        </div>

        <div class="login__form_block hide">
          <div class="login__form_text_1">
            <span>Create a new password</span>
          </div>
          <div class="login__form_text_2">
            <span
              >Please check the email address <span>example@gmail.com </span>for
              instructions to reset your password.</span
            >
          </div>
          <div class="form" id="newPassForm" action="#">
            <div class="form__wrapper password-wrap">
              <div class="password-eye" data-status="Показать пароль">
                <img
                  class="password-img password_hidden active"
                  id="password"
                  src="img/eye.svg"
                  alt=""
                /><img
                  class="password-img password_visible"
                  id="password"
                  src="img/eye_open.svg"
                  alt=""
                />
              </div>
              <label for="password">New Password*</label>
              <input
                class="form__input input-password"
                type="password"
                name="password"
                placeholder="Create a password"
                data-valid="false"
                data-name="password"
              /><span class="error-item">Invalid format of password</span>
            </div>
            <div class="form__pass_list">
              <ul class="form__pass_list_item">
                <li class="active">must be at least 8 characters length</li>
                <li>contains lowercase latin letters</li>
                <li>contains uppercase latin letters</li>
                <li>digits</li>
                <li class="active">
                  password and password confirmation must be equal
                </li>
              </ul>
            </div>
            <div class="form__wrapper password-wrap">
              <div class="password-eye" data-status="Показать пароль">
                <img
                  class="password-img password_hidden active"
                  id="password"
                  src="img/eye.svg"
                  alt=""
                /><img
                  class="password-img password_visible"
                  id="password"
                  src="img/eye_open.svg"
                  alt=""
                />
              </div>
              <label for="reppassword">Repeat new password*</label>
              <input
                class="form__input input-password"
                type="password"
                name="reppassword"
                placeholder="Repeat password"
                data-valid="false"
                data-name="password"
              /><span class="error-item">Sorry but password mismatch</span>
            </div>
            <button
              class="form__login_btn"
              id="loginBtn"
              type="submit"
            >
              <span>Update password</span>
            </button>
          </div>
        </div>
        <div class="login__form_block hide">
          <div class="login__form_text_1">
            <span>Password changed successfully</span>
          </div>
          <div class="login__form_text_2">
            <span>You can now log in using your new password</span>
          </div>
          <a class="login__resend_email_btn blue" href="#"
            ><span>To login page</span></a
          >
        </div>
        <div class="login__copyrights"><span>© Dogtraff 2024</span></div>
      </div>
      <div class="login__image_block">
        <img src="img/login/main-image.png" alt="" />
      </div>
    </section>
  </div>
</template>

<script>

export default {
  name: 'Login',

  data() {
    return {
      formData: {
        login: ''
      },
      status: 'new' || 'sended'
    };
  },

  computed: {
    errors() {
      return this.formData.login.length < 2
    },
  },

  methods: {
    submit() {
      this.status = 'sended';

      this.$store.dispatch('forgot', {data: this.formData})
    },
  },
};
</script>
