<template>
    <div class="body-wrap">
        <section class="register">
            <div class="register__text_block">
                <a class="register__logo" href="#"
                ><img src="img/header/logo.svg" alt=""
                /></a>
                <div class="register__form_block">
                    <div class="register__form_text_1">
                        <span>Sign up</span>
                    </div>

                    <div class="register__switcher">
                            <div class="form__wrapper">
                                <label class="switch-label">Choose Your Destiny</label>
                            </div>
                            <div class="register__switcher_name_block">
                                <div
                                    class="register__switcher_name_item tab-name"
                                    :class="formData.usertype === 'advertiser' ? 'active' : ''"
                                    @click="switchUserType('advertiser')"
                                >
                                    <span>ADVERTISER</span>
                                </div>

                                <div
                                    class="register__switcher_name_item tab-name"
                                    :class="formData.usertype === 'webmaster' ? 'active' : ''"
                                    @click="switchUserType('webmaster')"
                                >
                                    <!--                <span>PUBLISHER</span>-->
                                    <span>WEBMASTER</span>
                                </div>
                            </div>


                        <div class="register__switcher_content" v-if="formData.usertype !== ''">
                            <div
                                class="register__switcher_content_item tab-content active"
                                id="tab_1"
                            >
                                <div class="form" id="registerFormAdvertiser" action="#">
                                    <div class="form__wrapper">
                                        <label for="login">Login</label>
                                        <input
                                            class="form__input"
                                            id="login"
                                            type="text"
                                            name="login"
                                            placeholder="Enter your login"
                                            data-name="login"
                                            data-valid="false"
                                            v-model="formData.login"
                                        /><span class="error-item">Invalid format of login</span>
                                    </div>
                                    <div class="form__wrapper">
                                        <label for="login">Messenger</label>
                                        <input
                                            class="form__input"
                                            id="login"
                                            type="text"
                                            name="login"
                                            placeholder="Enter your messenger"
                                            data-name="login"
                                            data-valid="false"
                                            v-model="formData.imes"
                                        /><span class="error-item">Invalid format of messenger</span>
                                    </div>
                                    <div class="form__wrapper">
                                        <label for="email">Email</label>
                                        <input
                                            class="form__input"
                                            id="email"
                                            type="email"
                                            name="email"
                                            placeholder="Enter your email"
                                            data-name="email"
                                            data-valid="false"
                                            v-model="formData.embox"
                                        /><span class="error-item">Invalid format of email</span>
                                    </div>
                                    <div class="form__wrapper password-wrap">
                                        <div class="password-eye" data-status="Показать пароль"
                                             @click="passwordVisible.newPasswordVisible = !passwordVisible.newPasswordVisible"
                                        >
                                            <img
                                                class="password-img password_hidden"
                                                :class="passwordVisible.newPasswordVisible && 'active'"
                                                id="newPassword"
                                                src="img/eye.svg"
                                                alt=""
                                            />
                                            <img
                                                class="password-img password_visible"
                                                :class="!passwordVisible.newPasswordVisible && 'active'"
                                                id="newPassword1"
                                                src="img/eye_open.svg"
                                                alt=""
                                            />
                                        </div>
                                        <label for="password">New Password*</label>
                                        <input
                                            class="form__input input-password"
                                            :type="passwordVisible.newPasswordVisible ? 'password' : 'text'"
                                            name="password"
                                            placeholder="Create a password"
                                            data-valid="false"
                                            data-name="password"
                                            v-model="formData.password1"
                                        /><span class="error-item">Invalid format of password</span>
                                    </div>
                                    <div class="form__pass_list">
                                        <ul class="form__pass_list_item">
                                            <li :class="newPasswordValide.length && 'active'">must be at least 8
                                                characters length
                                            </li>
                                            <li :class="newPasswordValide.lowercase && 'active'">contains lowercase
                                                latin letters
                                            </li>
                                            <li :class="newPasswordValide.uppercase && 'active'">contains uppercase
                                                latin letters
                                            </li>
                                            <li :class="newPasswordValide.digits && 'active'">digits</li>
                                            <li :class="passwordValide && formData.password1 === formData.password2 && 'active'">
                                                password and password confirmation must be equal
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="form__wrapper password-wrap">
                                        <div class="password-eye" data-status="Показать пароль"
                                             @click="passwordVisible.newPasswordConfirmVisible = !passwordVisible.newPasswordConfirmVisible"
                                        >
                                            <img
                                                class="password-img password_hidden"
                                                :class="passwordVisible.newPasswordConfirmVisible && 'active'"
                                                id="newPassword"
                                                src="img/eye.svg"
                                                alt=""
                                            />
                                            <img
                                                class="password-img password_visible"
                                                :class="!passwordVisible.newPasswordConfirmVisible && 'active'"
                                                id="newPassword1"
                                                src="img/eye_open.svg"
                                                alt=""
                                            />
                                        </div>
                                        <label for="password">Repeat password*</label>
                                        <input
                                            class="form__input input-password"
                                            :type="passwordVisible.newPasswordConfirmVisible ? 'password' : 'text'"
                                            name="password"
                                            placeholder="Repeat password"
                                            data-valid="false"
                                            data-name="password"
                                            v-model="formData.password2"
                                        /><span class="error-item">Invalid format of password</span>
                                    </div>
                                    <div class="form__checkbox_wrapper">
                                        <div class="form__checkbox register">
                                            <input
                                                class="checkboxCustom custom-checkbox"
                                                id="checkboxCustom"
                                                type="checkbox"
                                                name="checkboxCustom"
                                                v-model="accept"
                                            />
                                            <label for="checkboxCustom">
                        <span>
                          I accept <router-link to="/terms">terms</router-link> of use and
                            <router-link to="/privacy-policy">privacy policy</router-link> and agree to receive
                          newsletters
                        </span>
                                            </label>
                                        </div>
                                    </div>
                                    <button
                                        class="form__login_btn"
                                        id="loginBtn"
                                        type="submit"
                                        @click="sumbit"
                                        :disabled="hasErrors || !accept"
                                    >
                                        <span>Create account</span>
                                    </button>
                                    <div class="register__sign_up">
                                        <span>Already have an account? </span>
                                        <router-link to="/login">
                                            Log in
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="register__copyrights_block">
                    <div class="register__copyrights"><span>© Dogtraff 2024</span></div>
                    <a class="register__copyrights_help" href="mailto:help@dogtraff.com">
                        <img src="img/register/help.svg" alt=""/>
                        <span>help@dogtraff.com</span>
                    </a>
                </div>
            </div>
            <div class="register__image_block">
                <img
                    class="tab-image"
                    :class="formData.usertype === 'advertiser' || formData.usertype === '' ? 'active' : ''"
                    id="image_1"
                    src="img/register/main-image.png"
                    alt=""
                />
                <img
                    class="tab-image"
                    :class="formData.usertype === 'webmaster' ? 'active' : ''"
                    id="image_2"
                    src="img/register/main-image-1.png"
                    alt=""
                />
            </div>
        </section>
    </div>
</template>

<script>

export default {
    data() {
        return {
            formData: {
                login: '',
                password1: '',
                password2: '',
                imes: '',
                usertype: '',
                embox: '',
            },

            passwordVisible: {
                newPasswordVisible: true,
                newPasswordConfirmVisible: true,
            },

            accept: false
        }
    },

    computed: {
        newPasswordValide() {
            return {
                length: this.formData.password1.length >= 8,
                lowercase: /[a-zа-я]/.test(this.formData.password1),
                uppercase: /[A-ZА-Я]/.test(this.formData.password1),
                digits: /\d/.test(this.formData.password1),
            }
        },

        passwordValide() {
            return Object.values(this.newPasswordValide).every(valid => valid);
        },

        formErrors() {
            const resultErrors = {
                login: false,
                password1: false,
                password2: false,
                embox: false,
                imes: false,
            }

            const {login, password1, password2, embox, imes} = this.formData;

            if (login.length < 3 || login.length >= 258) {
                resultErrors.login = true;
            }
            if (!this.passwordValide) {
                resultErrors.password1 = true;
            }
            if (password1 !== password2) {
                resultErrors.password2 = true;
            }
            if (embox.length < 3 || embox.length >= 258 || !embox.match(/^\S+@\S+\.\S+$/)) {
                resultErrors.embox = true;
            }
            if (imes.length < 3 || imes.length >= 258) {
                resultErrors.imes = true;
            }

            return resultErrors;
        },

        hasErrors() {
            return Object.values(this.formErrors).some(value => value);
        }
    },

    methods: {
        switchUserType(usertype) {
            this.formData.usertype = usertype
        },

        sumbit() {
            this.$store.dispatch('signup', {data: this.formData})
        },
    }
};
</script>

<style scoped lang="scss">

.register__switcher_name_item {
    background: #EAECF0;
}

.switch-label {
    text-align: center;
}
</style>
