import sendRequest from "@/store/sendRequest";

export default {
    state: {
        trafficList: [],
    },

    mutations: {
        setTrafficList(state, trafficList)                { state.trafficList = trafficList; },
    },

    actions: {
        getTrafficList({commit}, {data}) {
            return new Promise((resolve, reject) => {
                sendRequest({method: 'get', endpoint: `/traffic`, data}).then(response => {
                    commit('setTrafficList', response.data);
                    resolve(response.data)
                }).catch((err) => {
                    commit('setTrafficList', []);
                    reject(err)
                })
            })
        },
    }
}
