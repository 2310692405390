<template>
    <div class="modal" :class="open && 'open'">
        <DataLoader v-if="!codeValue" />

        <div v-else class="modal__wrapper">
            <svg class="modal__icon" width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="5.66602" y="5.5" width="64" height="64" rx="32" fill="#D1FADF"/>
                <rect x="5.66602" y="5.5" width="64" height="64" rx="32" stroke="#ECFDF3" stroke-width="10.6667"/>
                <path d="M43.0007 45.5L51.0007 37.5L43.0007 29.5M32.334 29.5L24.334 37.5L32.334 45.5" stroke="#039855" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <div class="modal__title">
                <span>Edit default code</span>
            </div>
            <textarea
                class="modal__textarea"
                ref="refCode"
                :value="codeValue"
                @change="e => newValue =  e.target.value"
            ></textarea>
            <div class="modal__buttons">
                <button class="modal__button" @click="close">
                    <span>Cancel</span>
                </button>
                <button class="modal__button blue" @click="save">
                    <span>Save code</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>

import DataLoader from "@/components/UI/DataLoader.vue";

export default {
    name: 'EditCodeModal',

    components: {
        DataLoader,
    },

    props: [
        'open',
        'codeValue',
    ],

    data() {
        return {
            newValue: ''
        }
    },

    methods: {
        save() {
            this.close();

            this.$emit('saveCode', this.newValue.length > 0 ? this.newValue : this.codeValue )
        },

        close() {
            this.$emit('cancel')
            setTimeout(() => {
                this.newValue = '';
            }, 100)
        },
    },
};
</script>

<style lang="scss">

.modal {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(52, 64, 84, 0.7);
    transition: opacity .15s .1s ease-in-out;
    opacity: 0;
    pointer-events: none;
    z-index: 999;

    &.open {
        pointer-events: auto;
        opacity: 1;
        transition: opacity .15s .1s ease-in-out;
    }

    &__wrapper {
        background: #fff;
        padding: 2em;
        border-radius: 1em;
        width: 100%;
        max-width: 43em;
    }

    // modal icon
    &__icon {
        width: 4em;
        height: 4em;
        margin-bottom: 1.2em;
    }

    // text title
    &__title {
        margin-bottom: .7em;

        span {
            font-size: 1.5em;
            font-weight: 600;
        }
    }

    // text description
    &__description {
        color: #667085;
        margin-bottom: 1.5em;

        span {
            font-size: 1.2em;
        }
    }

    // text field
    &__textarea {
        width: 100%;
        height: 18em;
        color: #667085;
        font-size: 1.3125em;
        font-weight: 400;
        padding-top: 0.61905em;
        padding-bottom: 0.61905em;
        border-radius: 0.47619em;
        border: 0.09524em solid #D0D5DD;
        outline: none !important;
        padding-left: 0.95238em;
        padding-right: 0.95238em;
        margin-bottom: 1.2em;
        line-height: 140%;
    }

    // buttons
    &__buttons {
        display: flex;
        position: relative;
    }

    // button
    &__button {
        width: 50%;
        position: relative;
        background: #ffffff;
        padding-left: em(18);
        padding-right: em(18);
        padding-top: em(10);
        padding-bottom: em(10);
        border: em(2,21) solid #d0d5dd;
        border-radius: em(10);
        box-shadow: em(0) em(1) em(3) em(0) rgba(16, 24, 40, 0.05);
        transition: .3s ease-in-out;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        &:first-child{
            margin-right: em(0);
        }

        svg {
            width: 1.1em;
            margin-right: .6em;
        }

        span{
            color: #344054;
            font-size: em(18);
            font-weight: 500;
            line-height: 1.4;
            transition: .3s ease-in-out;
        }

        &:hover{
            box-shadow: em(0) em(0) em(0) em(4) #eaecf0;
            transition: .3s ease-in-out;
        }

        &.blue{
            background: #1570EF;
            margin-left: em(18);
            border: em(2) solid  #1570EF;
            span{
                color: #ffffff;
            }
            &:hover{
                background: #175cd3;
                box-shadow: em(0) em(0) em(0) em(0) #D1E9FF;
            }
            &:active{
                background: #1570EF;
                transition: .3s ease-in-out;
                box-shadow: em(0) em(0) em(0) em(6) #D1E9FF;
            }
        }
    }

    @media (max-width: 1024px) and (orientation: portrait) {
        &__wrapper {
            font-size: 4em;
            margin-left: 1em;
            margin-right: 1em;
        }

        &__buttons {
            flex-direction: column;
            width: 100%;
        }

        &__button {
            width: 100%;
            margin-left: 0 !important;

            &:first-child {
                margin-bottom: 1em;
            }
        }
    }
}

</style>
