<template>
    <div class="body-wrap dashboard-page">
        <section class="dashboard">
            <MainSidebar/>

            <div class="dashboard__white_container">
                <div class="dashboard__white_nav">
                    <div class="dashboard__white_nav_left">
                        <div class="dashboard__white_nav_heading">
                            <span>Traffic Chart</span>
                        </div>
                        <div class="dashboard__white_nav_subheading">
                            <span>Select period and filters to view data</span>
                        </div>
                    </div>
                    <div class="dashboard__white_nav_right">
                        <div class="dashboard__white_nav_price">
                            <span>Your balance:</span>
                            <span>{{ balance?.currentBalance }}$</span>
                        </div>
                        <router-link to="/finance" class="dashboard__white_nav_add_funds">
                            <img src="img/dashboard/plus.svg" alt="">
                            <span>Add funds </span>
                        </router-link>
                    </div>
                </div>
                <div class="dashboard__search_block">
                    <div class="dashboard__filter_stats traffic-chart">
                        <div class="dashboard__filter_stat_item">
                            <span>Theme:</span>
                            <div class="dashboard__filter_select">
                                <select id="filterCampaign" name="filterCampaign" v-model="formData.theme[0]">
                                    <option value="">All</option>
                                    <option v-for="(theme, key) in themes"
                                            :key="key"
                                            :value="key"
                                    >
                                        {{ theme }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="dashboard__filter_stat_item">
                            <span>GEO:</span>
                            <div class="dashboard__filter_select">
                                <select id="filterGeo" name="filterGeo" v-model="formData.country[0]">
                                    <option value="">All</option>
                                    <option v-for="(country, key) in countries"
                                            :key="key"
                                            :value="key"
                                    >
                                        {{ country }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="dashboard__filter_btns">
                            <button class="dashboard__filter_btn_no" @click.prevent="initTrafficList">
                                <img src="img/statistic/no.svg" alt="">
                            </button>
                            <button class="dashboard__filter_btn_yes" @click.prevent="filter">
                                <img src="img/statistic/yes.svg" alt="">
                            </button>
                        </div>
                    </div>
                </div>
                <div class="dashboard__statistics_items">
                    <div class="dashboard__traffic_item name-item">
                        <div class="dashboard__traffic_item_name">
                            <span>COUNTRY</span>
                        </div>
                        <div class="dashboard__traffic_item_name">
                            <span>FORMAT</span>
                        </div>
                        <div class="dashboard__traffic_item_name">
                            <span>BID (CPC/CPM)</span>
                        </div>
                    </div>

                    <DataLoader v-if="requestLoading" />

                    <template v-else>
                        <EmptyMessage v-if="itemsList.length === 0"
                            @clear="initTrafficList"
                        />

                        <div v-else
                             v-for="trafficItem in itemsList"
                             :key="trafficItem.format+trafficItem.country+trafficItem.cpm"
                             class="dashboard__traffic_item"
                        >
                            <div class="dashboard__traffic_item_content"><span>{{ trafficItem.country }}</span></div>
                            <div class="dashboard__traffic_item_content"><span>{{ trafficItem.format }}</span></div>
                            <div class="dashboard__traffic_item_content"><span>{{ trafficItem.cpm }}</span></div>
                        </div>
                    </template>

                    <Pagination
                        :currentPage="currentPage"
                        :totalItems="trafficList.length"
                        :pageSize="pageSize"
                        @update="updatePage"
                    />
                </div>

                <Pagination
                    :mobile="true"
                    :currentPage="currentPage"
                    :totalItems="trafficList.length"
                    :pageSize="pageSize"
                    @update="updatePage"
                />
            </div>
        </section>
    </div>
</template>

<script>
import {mapState} from "vuex";
import MainSidebar from '../../components/Sidebar/MainSidebar.vue';
import Pagination from '../../components/Pagination/Pagination.vue';
import DataLoader from "@/components/UI/DataLoader.vue";
import EmptyMessage from "@/components/UI/EmptyMessage.vue";

export default {
    components: {
        DataLoader,
        MainSidebar,
        Pagination,
        EmptyMessage,
    },

    data() {
        return {
            requestLoading: false,

            formData: {
                theme: [''],
                country: ['']
            },

            currentPage: 1,
            pageSize: 10,
        }
    },

    computed: {
        ...mapState({
            // traffic
            trafficList: state => state.traffic.trafficList,
            // listings
            themes: state => state.listings.listings.categories,
            countries: state => state.listings.listings.target_countries,
            // balance
            balance: state => state.balance.balance,
        }),

        itemsList() {
            return this.trafficList.slice(this.pageSize * (this.currentPage - 1 ?? 0), this.pageSize * this.currentPage)
        }
    },

    methods: {
        filter() {
            const data = {};

            if (this.formData.theme[0] !== '') data.theme = Array.from([this.formData.theme[0]]);
            if (this.formData.country[0] !== '') data.country = Array.from([this.formData.country[0]]);

            this.requestLoading = true;

            this.$store.dispatch('getTrafficList', {
                data: data
            }).finally(() => {
                setTimeout(() => {
                    this.requestLoading = false;
                    this.currentPage = 1;
                }, 1000)
            })
        },

        initTrafficList() {
            this.formData.theme[0] = '';
            this.formData.country[0] = '';

            this.requestLoading = true;

            this.$store.dispatch('getTrafficList', {data: {}}).finally(() => {
                setTimeout(() => {
                    this.requestLoading = false;
                    this.currentPage = 1;
                }, 1000)
            })
        },

        updatePage(current) {
            this.requestLoading = true;
            this.currentPage = current;

            setTimeout(() => {
                this.requestLoading = false;
            }, 1000)
        },
    },

    created() {
        this.initTrafficList();
        this.$store.dispatch('getListings')
        this.$store.dispatch('getBalance');
    }
};
</script>

<style scoped lang="scss">
.dashboard-page {
    background: #89123e;
}
</style>
