import sendRequest, { sendFileRequest } from "@/store/sendRequest";

export default {
    state: {
        banner: {},
        bannersList: [],
    },

    mutations: {
        setBanner(state, banner) {
            state.banner = banner;
        },

        setBannersList(state, bannersList) {
            state.bannersList = bannersList;
        },
    },

    actions: {
        getBanners({commit}) {
            sendRequest({method: 'get', endpoint: `/banners`}).then(response => {
                commit('setBannersList', response.data);
            })
        },

        createNewBanner(_, {data}) {
            return new Promise((resolve, reject) => {
                sendRequest({method: 'post', endpoint: `/banner`, data}).then(response => {
                    const id = response.data.split('ID: ')[1].split('; ; ')[0];

                    resolve(id)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        saveBanner(_, {bannerId, data}) {
            return new Promise((resolve, reject) => {
                sendRequest({method: 'post', endpoint: `/banner/${bannerId}`, data}).then(response => {
                    resolve(response)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        getBanner(_, {campaignId}) {
            return new Promise((resolve, reject) => {
                sendRequest({method: 'get', endpoint: `/banners/${campaignId}`}).then((response) => {
                    resolve(response.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        getBannerById(_, {id}) {
            return new Promise((resolve, reject) => {
                sendRequest({method: 'get', endpoint: `/banner/${id}`}).then((response) => {
                    resolve(response.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        addBannerFile(_, {data, uploadFileFormData}) {
            return new Promise((resolve, reject) => {
                sendFileRequest({
                    method: 'post',
                    endpoint: `/upload`,
                    data,
                    body: uploadFileFormData,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then((response) => {
                    resolve(response)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        //
        // addBannerFile(_, {sing, campId, bannerId, fileName}) {
        //     return new Promise((resolve, reject) => {
        //         axios.post(`https://reacivepl.club/${fileName}?cdn=true&sing=${sing}&adid=${campId}&banid=${bannerId}&json=true`, {
        //             headers: {
        //                 Authorization: `Basic ${localStorage.getItem('auth')}`,
        //             },
        //         }).then((response) => {
        //             console.log(response)
        //             resolve(response)
        //         }).catch(err => {
        //             reject(err)
        //         })
        //     })
        // },
    }
}
