import sendRequest from "@/store/sendRequest";

export default {
    state: {
        user: {},
    },

    mutations: {
        setUser(state, user) {
            state.user = user;
        },
    },

    actions: {
        getUser({commit}) {
            return new Promise((resolve, reject) => {
                sendRequest({method: 'get', endpoint: `/userinfo`}).then(response => {
                    commit('setUser', response.data);
                    // commit('setUser', {...response, usertype: 'webmaster'});

                    resolve(response.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        updateUser({dispatch}, {data}) {
            return new Promise((resolve, reject) => {
                sendRequest({method: 'post', endpoint: `/userinfo`, data}).then(response => {
                    dispatch('getUser')
                    resolve(response.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
    }
}
