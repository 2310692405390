<template>
    <div class="pagination" v-if="pagesCount">
        <div class="pages-mob" v-if="mobile">
            <div class="dashboard__pages_counts">
                <span>Page </span>
                <span class="current-page">
                    {{ currentPage }}
                </span>
                <span>of </span>
                <span class="al-pages">
                 {{ pagesCount }}
                </span>
            </div>
            <div class="dashboard__pages_btns">
                <button class="dashboard__pages_btn previous" @click.prevent="() => update(-1)">
                    <span>Previous</span>
                </button>
                <button class="dashboard__pages_btn next" @click.prevent="() => update(1)">
                    <span>Next</span>
                </button>
            </div>
        </div>

        <div class="dashboard__statistics_item pages" v-else>
            <div class="dashboard__pages_counts">
                <span>Page </span>
                <span class="current-page">
                    {{ currentPage }}
                </span>
                <span>of </span>
                <span class="al-pages">
                    {{ pagesCount }}
                </span>
            </div>
            <div class="dashboard__pages_btns">
                <button class="dashboard__pages_btn previous" @click.prevent="() => update(-1)">
                    <span>Previous</span>
                </button>
                <button class="dashboard__pages_btn next" @click.prevent="() => update(1)">
                    <span>Next</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Pagination',

    props: [
        'currentPage',
        'totalItems',
        'pageSize',
        'mobile'
    ],

    computed: {
        pagesCount() {
            return Math.ceil((this.totalItems ?? 0) / this.pageSize);
        }
    },

    methods: {
        update(value) {
            const currentPage = this.currentPage + value;
            if (currentPage <= 0 || currentPage > this.pagesCount) return;

            this.$emit('update', currentPage);
        }
    },
};
</script>

<style>
.pagination {
    width: 100%;
}
</style>
