<template>
  <router-view></router-view>
</template>

<script>
import 'ant-design-vue/dist/reset.css';

export default {
  name: "App",
};
</script>

<style lang="scss">
body {
  overflow: auto;
}

#app {
  height: 100%;
}

ul {
  margin-block-start: 1em !important;
  margin-block-end: 1em !important;

  li {
    list-style: disc;
  }
}

ol {
  margin-block-start: 1em !important;
  margin-block-end: 1em !important;

  li {
    list-style: numeric;
  }
}

button {
    border: 0;
    cursor: pointer;

    &[disabled] {
      pointer-events: none;
      user-select: none;
      filter: grayscale(1);
      label{
        color: #D0D5DD;
      }
    }
}

.statistic-label span {
    font-size: 3rem !important;
    word-break: break-all;
    font-weight: 600 !important;


    @media (max-width: 1024px) and (orientation: portrait) {
        font-size: 10rem !important;
    }
}

@media(orientation: 'portrait') {
    .ant-picker-panels {
        flex-direction: column !important;
    }
}
</style>
