<template>
    <div class="checkbox">
        <svg v-if="checked" width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.666667" y="1.4987" width="20" height="20" rx="4.66667" fill="#EFF8FF"/>
            <rect x="0.666667" y="1.4987" width="20" height="20" rx="4.66667" stroke="#1570EF" stroke-width="1.33333"/>
            <path d="M15.9997 7.49609L8.66634 14.8294L5.33301 11.4961" stroke="#1570EF" stroke-width="2.22213" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

        <svg v-else width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.999674" y="1.16276" width="20" height="20" rx="4.66667" fill="white"/>
            <rect x="0.999674" y="1.16276" width="20" height="20" rx="4.66667" stroke="#D0D5DD" stroke-width="1.33333"/>
        </svg>
    </div>
</template>

<script>

export default {
    name: 'DTPDayNumbers',

    props: [
        'checked'
    ]
}
</script>

<style lang="scss" scoped>
.checkbox {
    svg {
        width: 1.2em;
        height: 1.2em;
    }
}
</style>
