<template>
    <div class="date-time-picker__footer">
<!--        <button class="date-time-picker__button date-time-picker__button&#45;&#45;transparent" @click="$emit('reset')">-->
<!--            <span>Reset</span>-->
<!--        </button>-->
        <button class="date-time-picker__button" @click="$emit('cancel')">
            <span>Cancel</span>
        </button>
        <button class="date-time-picker__button date-time-picker__button--blue" @click="$emit('apply')">
            <span>Apply</span>
        </button>
    </div>
</template>

<script>

export default {
    name: 'DTPFooter',
}
</script>

<style lang="scss" scoped>
.date-time-picker {
    &__footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 1.2em;
        //border-top: .1em solid #EAECF0;
    }

    &__button {
        padding: .6em .8em;
        border-radius: .4em;
        border: .1em solid #EAECF0;
        background: #fff;
        transition: background .2s ease-in-out;
        margin-left: .6em;
        color: #344054;

        span {
            font-size: 1.1em;
            font-weight: 600;
            transition: color .2s ease-in-out;
        }

        &--transparent {
            border: 0;
            background: transparent;
            color: #1570EF;
        }

        &--blue {
            background: #1570EF;
            color: #fff;

            &:hover {
                color: #344054;
            }
        }

        &:hover {
            background: #EAECF0;
        }
    }
}
</style>
