import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store'
import i18n from './i18n'
import './assets/scss/main.scss';
import loader from "vue-ui-preloader";

createApp(App)
    .use(router)
    .use(store)
    .use(i18n)
    .use(loader)
    .mount('#app')