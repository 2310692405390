<template>
    <div class="chart">
        <canvas ref="chart"></canvas>
    </div>
</template>

<script>
import Chart from 'chart.js/auto';

export default {
    name: 'CircleGraph',

    props: [
        'graphLabels',
        'graphData',
    ],

    data () {
        return {
            bodyFontSize: 1,
            barName: 'Bar Chart',
            type: 'doughnut',
            barData: {
                labels: [...this.graphLabels],
                datasets: [
                    {
                        label: 'Dataset 1',
                        data: Object.values(this.graphData).every(v => +v === 0) ? [] : [...Object.values(this.graphData)],
                        backgroundColor: [
                            '#475467',
                            '#667085',
                            '#98A2B3',
                            '#D0D5DD',
                            '#EAECF0',
                        ],
                        borderColor: 'rgba(0, 0, 0, 0)',
                        // rotation: -20,
                        // weight: .2,
                        // borderWidth: 1
                    },
                ]
            },

            options: {
                responsive: true,
                maintainAspectRatio: false,

                plugins: {
                    legend: {
                        display: true,
                        position: 'right',
                        labels: {
                            color: "#667085",
                            padding: 12,
                            usePointStyle: true,
                            boxWidth: 20,
                            font: {
                                size: window.innerWidth < 768 ? 9 : 10,
                            }
                        },
                    },
                    // title: {
                    //     // display: false,
                    // },
                    emptyDoughnut: {
                        color: 'rgba(205, 205, 205, 0.1)',
                        width: 30*this.bodyFontSize,
                        radiusDecrease: 20*this.bodyFontSize
                    }
                },
            },
        }
    },

    methods: {
        createGraph() {
            const chrt = this.$refs.chart;
            const chart = new Chart(chrt, {
                type: this.type,
                data: this.barData,
                options: this.options,
                // plugins: [this.plugin]
            });
            chart;
        },
    },

    mounted() {
        this.createGraph();
    },
}
</script>

<style scoped>
.chart {
  height: 11.5em;
  width: 30em;
  //width: 28.5em;

    @media (max-width: 768px) {
        width: 22em;
    }
}
</style>
