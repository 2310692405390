import sendRequest from "@/store/sendRequest";

export default {
    state: {
        billingdetails: {},
    },

    mutations: {
        setBillingdetails(state, billingdetails) {
            state.billingdetails = billingdetails;
        },
    },

    actions: {
        getBillingdetails({commit}) {
            return new Promise((resolve, reject) => {
                sendRequest({method: 'get', endpoint: `/billingdetails`}).then(response => {
                    commit('setBillingdetails', response.data);

                    resolve(response.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        updateBillingdetails(context, {data}) {
            return new Promise((resolve, reject) => {
                sendRequest({method: 'post', endpoint: `/billingdetails`, data}).then(response => {
                    resolve(response.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
    }
}
