// import axios from 'axios';
// import { API_URL } from '@/api';
import sendRequest from "@/store/sendRequest";

export default {
    state: {
        statisticsList: [],

        statisticsStat: [],
        statisticsPlatform: [],
        statisticsGeo: [],
    },

    mutations: {
        setStatisticsList(state, statisticsList)                { state.statisticsList = statisticsList; },
        setStatisticsStat(state, statisticsStat)        { state.statisticsStat = statisticsStat; },
        setStatisticsPlatform(state, statisticsPlatform){ state.statisticsPlatform = statisticsPlatform; },
        setStatisticsGeo(state, statisticsGeo)          { state.statisticsGeo = statisticsGeo; },
    },

    actions: {
        getStatistics({commit}, {data}) {
                // ?from=01.11.2023&to=23.11.2023&daydivide=on&country[]=UA&site[]=21758
            // sendRequest({method: 'get', endpoint: `/statistics`, data}).then(response => {
            //     commit('setStatisticsList', response);
            // }).catch(() => {
            //     commit('setCampaignsList', []);
            // })

            return new Promise((resolve, reject) => {
                sendRequest({method: 'get', endpoint: `/statistics`, data}).then(response => {
                    commit('setStatisticsList', response.data);
                    resolve(response.data)
                }).catch((err) => {
                    commit('setStatisticsList', []);
                    reject(err)
                })
            })
        },

        getStatisticsStat({commit}, {from, to}) {
            return new Promise((resolve, reject) => {
                sendRequest({method: 'get', endpoint: `/statistics`, data: {from, to}}).then(response => {
                    commit('setStatisticsStat', response.data);
                    resolve(response.data)
                }).catch((err) => {
                    commit('setStatisticsStat', []);
                    reject(err)
                })
            })
        },

        getStatisticsPlatform({commit}, {from, to}) {
            return new Promise((resolve, reject) => {
                sendRequest({method: 'get', endpoint: `/statistics`, data: {from, to, showdevice: "on"}}).then(response => {
                    commit('setStatisticsPlatform', response.data);
                    resolve(response.data)
                }).catch((err) => {
                    commit('setStatisticsPlatform', []);
                    reject(err)
                })
            })
        },

        getStatisticsGeo({commit}, {from, to}) {
            return new Promise((resolve, reject) => {
                sendRequest({method: 'get', endpoint: `/statistics`, data: {from, to, bygeo: "on"}}).then(response => {
                    commit('setStatisticsGeo', response.data);
                    resolve(response.data)
                }).catch((err) => {
                    commit('setStatisticsGeo', []);
                    reject(err)
                })
            })
        },

        getStatisticsSPC({dispatch}, {from, to}) {
            return new Promise((resolve, reject) => {
                Promise.all([
                    dispatch('getStatisticsStat', {from, to}),
                    dispatch('getStatisticsPlatform', {from, to}),
                    dispatch('getStatisticsGeo', {from, to}),
                ]).then(() => {
                    resolve()
                }).catch(() => {
                    reject()
                })
            })
        },
    }
}
