import sendRequest from "@/store/sendRequest";

export default {
    state: {
        campaign: {},
        campaignsList: [],
    },

    mutations: {
        setCampaign(state, campaign) {
            state.campaign = campaign;
        },

        setCampaignsList(state, campaignsList) {
            state.campaignsList = campaignsList;
        },
    },

    actions: {
        getCampaigns({commit}, {data}) {
            return new Promise((resolve, reject) => {
                sendRequest({method: 'get', endpoint: `/campaigns`, data}).then(response => {
                    commit('setCampaignsList', response.data);
                    resolve(response.data)
                }).catch((err) => {
                    commit('setCampaignsList', []);
                    reject(err)
                })
            })
        },

        createNewCampaign(context, {data}) {
            return new Promise((resolve, reject) => {
                sendRequest({method: 'post', endpoint: `/campaign`, data}).then((response) => {
                    const campid = response.message?.split('ID: ')[1].replace('; ', '');

                    resolve({campid})
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        getCampaign(_, {id}) {
            return new Promise((resolve, reject) => {
                sendRequest({method: 'get', endpoint: `/campaign/${id}`}).then(response => {
                    resolve(response.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        pauseCampaign(_, data) {
            return new Promise((resolve, reject) => {
                sendRequest({method: 'post', endpoint: `/pause/`, data}).then(response => {
                    resolve(response.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        saveCampaign(_, {data, id}) {
            return new Promise((resolve, reject) => {
                sendRequest({method: 'post', endpoint: `/campaign/${id}`, data}).then(response => {
                    resolve(response.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
    }
}
