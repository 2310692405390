<template>
    <div class="body-wrap dashboard-page">
        <section class="dashboard">
            <MainSidebar/>

            <div class="dashboard__white_container">
                <div class="dashboard__white_nav">
                    <div class="dashboard__white_nav_left">
                        <div class="dashboard__white_nav_heading"><span>Finance</span></div>
                    </div>
                    <div class="dashboard__white_nav_right">
                        <div class="dashboard__white_nav_price">
                            <span>Your balance:</span><span>{{ balance?.currentBalance }}$</span>
                        </div>
                        <router-link to="/finance" class="dashboard__white_nav_add_funds">
                            <img src="img/dashboard/plus.svg" alt="">
                            <span>Add funds </span>
                        </router-link>
                    </div>
                </div>
                <div class="dashboard__finance_tabs_name">
                    <div
                        class="dashboard__finance_tab_item tab-name"
                        :class="currentTab === 'tab_1' ? 'active' : ''"
                        @click="setTab('tab_1')"
                        data-tab="#tab_1"
                    >
                        <span>Payments methods</span>
                    </div>
                    <div
                        class="dashboard__finance_tab_item tab-name"
                        :class="currentTab === 'tab_2' ? 'active' : ''"
                        @click="setTab('tab_2')"
                        data-tab="#tab_2"
                    >
                        <span>Billing details</span>
                    </div>
                    <div
                        class="dashboard__finance_tab_item tab-name"
                        :class="currentTab === 'tab_3' ? 'active' : ''"
                        @click="setTab('tab_3')"
                        data-tab="#tab_3"
                    >
                        <span>Payments history</span>
                    </div>
                </div>
                <div class="dashboard__finance_tabs_content">
                    <div
                        class="dashboard__finance_tabs_conten_item tab-content"
                        :class="currentTab === 'tab_1' ? 'active' : ''"
                        id="tab_1"
                    >
                        <template v-if="Object.keys(balance).length !== 0 && payTypes.length === 0">
                            <div class="dashboard__no_matches">
                                <div class="dashboard__no_matches_icon"><img src="img/statistic/nomatches.svg" alt=""></div>

                                <div class="dashboard__no_matches_heading"><span>No payments methods found</span></div>
                            </div>
                        </template>

                        <template v-if="Object.keys(balance).length !== 0 && payTypes.length !== 0">
                            <div class="dashboard__finance_payment_heading">
                                <span>Payments method:</span>
                            </div>
                            <div class="dashboard__finance_payment_btns">
                                <div class="dashboard__finance_payment_btn"
                                     v-for="payType in payTypes"
                                     v-bind:key="payType?.name"
                                     :class="{active: currentActivePayType === '' && payType.default || currentActivePayType === payType?.name}"
                                     @click="currentActivePayType = payType?.name"
                                >
                                    <div class="dashboard__finance_payment_btn_text_block">
                                        <div class="dashboard__finance_payment_btn_text_1">
                                        <span>{{ payType.ptype_name }}</span
                                        >
                                        </div>

                                        <div class="dashboard__finance_payment_btn_text_2">
                                            <span>Min sum: </span>
                                            <span class="card-date">
                                          {{ payType.minSum }}
                                        </span>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="dashboard__billing_line">
                                <div class="dashboard__billing_line_input">
                                    <input
                                        class="vat-number"
                                        id="vatNumber"
                                        type="text"
                                        name="vat-number"
                                        placeholder="Amount"
                                        v-model="paymentAmount"
                                    />
                                </div>
                            </div>

                            <a :href="`${this.paymentRedirectLink}?payType=${this.currentActivePayType}&amount=${this.paymentAmount}`"
                               class="dashboard__finance_payment_funds_btn" target="_blank"
                               :class="!minSumCorrect && 'disabled'"
                               role="link"
                            >
                                <span>Deposit funds</span>
                            </a>
                        </template>
                    </div>
                    <div
                        class="dashboard__finance_tabs_conten_item tab-content"
                        :class="currentTab === 'tab_2' ? 'active' : ''"
                        id="tab_2"
                    >
                        <div class="dashboard__billing_top">
                            <div class="dashboard__billing_text_block">
                                <div class="dashboard__billing_text_1">
                                    <span>Billing info</span>
                                </div>
                                <div class="dashboard__billing_text_2">
                                    <span>Update billing details here.</span>
                                </div>
                            </div>
                            <div class="dashboard__billing_btns">
                                <button class="dashboard__billing_btn" @click="$router.go()">
                                    <span>Cancel</span>
                                </button>
                                <button class="dashboard__billing_btn blue" @click.prevent="sendFormData">
                                    <ButtonSpinner v-if="requestLoading"/>
                                    <span v-else>Save</span>
                                </button>
                            </div>
                        </div>
                        <div class="dashboard__billing_line">
                            <div class="dashboard__billing_line_text">
                                <span>Fiscal status</span>
                            </div>

                            <div class="dashboard__billing_line_radios">
                                <div class="dashboard__billing_line_radio_btn">
                                    <input
                                        class="checkboxCustom custom-checkbox radio-blue"
                                        id="individual"
                                        type="radio"
                                        name="billing"
                                        value="individual"
                                        v-model="this.formData.type"
                                    />
                                    <label for="individual">Individual</label>
                                </div>
                                <div class="dashboard__billing_line_radio_btn">
                                    <input
                                        class="checkboxCustom custom-checkbox radio-blue"
                                        id="company"
                                        type="radio"
                                        name="billing"
                                        value="company"
                                        v-model="this.formData.type"
                                    />
                                    <label for="company">Company</label>
                                </div>
                            </div>
                        </div>

                        <div class="dashboard__billing_line" v-if="this.formData.type === 'company'">
                            <div class="dashboard__billing_line_text">
                                <span>Company Name</span>
                            </div>
                            <div class="dashboard__billing_line_input">
                                <input
                                    class="your-company"
                                    id="yourCompany"
                                    type="text"
                                    name="your-company"
                                    placeholder="Your company"
                                    v-model="this.formData.companyname"
                                />
<!--                                    disabled-->
                            </div>
                        </div>
                        <div class="dashboard__billing_line" v-if="this.formData.type === 'individual'">
                            <div class="dashboard__billing_line_text"><span>Name</span></div>
                            <div class="dashboard__billing_line_input double">
                                <input
                                    class="first-name"
                                    id="firstName"
                                    type="text"
                                    name="first-name"
                                    placeholder="First Name"
                                    v-model="this.formData.firstname"
                                />
                                <input
                                    class="last-name"
                                    id="LastName"
                                    type="text"
                                    name="last-name"
                                    placeholder="Last Name"
                                    v-model="this.formData.lastname"
                                />
                            </div>
                        </div>

                        <div class="dashboard__billing_line" v-if="this.formData.type === 'company'">
                            <div class="dashboard__billing_line_text">
                                <span>Reg.Number</span>
                            </div>
                            <div class="dashboard__billing_line_input">
                                <input
                                    class="reg-number"
                                    id="regNumber"
                                    type="text"
                                    name="reg-number"
                                    placeholder="Reg.Number"
                                    v-model="this.formData.regnumber"
                                />
                            </div>
                        </div>
                        <div class="dashboard__billing_line" v-if="this.formData.type === 'company'">
                            <div class="dashboard__billing_line_text">
                                <span>VAT Number</span>
                            </div>
                            <div class="dashboard__billing_line_input">
                                <input
                                    class="vat-number"
                                    id="vatNumber"
                                    type="text"
                                    name="vat-number"
                                    placeholder="VAT Number"
                                    v-model="this.formData.vatnumber"
                                />
                            </div>
                        </div>
                        <div class="dashboard__billing_line">
                            <div class="dashboard__billing_line_text">
                                <span>Country</span>
                            </div>
                            <div class="dashboard__billing_line_input">
                                <div class="dashboard__filter_select">
                                    <select id="selectCountry" name="selectCountry" v-model="formData.country">
                                        <option v-for='country in countriesKeys' :key="country" :value="country">
                                            {{ countriesData[country] }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="dashboard__billing_line">
                            <div class="dashboard__billing_line_text">
                                <span>Address</span>
                            </div>
                            <div class="dashboard__billing_line_input">
                                <input
                                    class="reg-number"
                                    id="regNumber"
                                    type="text"
                                    name="reg-number"
                                    placeholder="Reg.Number"
                                    v-model="this.formData.address"
                                />
                            </div>
                        </div>
                        <div class="dashboard__billing_line">
                            <div class="dashboard__billing_line_text">
                                <span>City</span>
                            </div>
                            <div class="dashboard__billing_line_input">
                                <input
                                    class="reg-number"
                                    id="regNumber"
                                    type="text"
                                    name="reg-number"
                                    placeholder="Reg.Number"
                                    v-model="this.formData.city"
                                />
                            </div>
                        </div>
                        <div class="dashboard__billing_line">
                            <div class="dashboard__billing_line_text">
                                <span>Phone</span>
                            </div>
                            <div class="dashboard__billing_line_input">
                                <input
                                    class="reg-number"
                                    id="regNumber"
                                    type="text"
                                    name="reg-number"
                                    placeholder="Reg.Number"
                                    v-model="this.formData.phone"
                                />
                            </div>
                        </div>
                        <div class="dashboard__billing_line">
                            <div class="dashboard__billing_line_text">
                                <span>Email</span>
                            </div>
                            <div class="dashboard__billing_line_input">
                                <input
                                    class="reg-number"
                                    id="regNumber"
                                    type="text"
                                    name="reg-number"
                                    placeholder="Reg.Number"
                                    v-model="this.formData.email"
                                />
                            </div>
                        </div>

                        <!--            <div class="dashboard__billing_line">-->
                        <!--              <div class="dashboard__billing_line_text"><span>Company Name</span></div>-->
                        <!--              <div class="dashboard__billing_line_input double">-->
                        <!--                <input-->
                        <!--                    class="first-name"-->
                        <!--                    id="firstName"-->
                        <!--                    type="text"-->
                        <!--                    name="first-name"-->
                        <!--                    placeholder="First Name"-->
                        <!--                    v-model="this.formData.company_firstname"-->
                        <!--                />-->
                        <!--                <input-->
                        <!--                    class="last-name"-->
                        <!--                    id="LastName"-->
                        <!--                    type="text"-->
                        <!--                    name="last-name"-->
                        <!--                    placeholder="Last Name"-->
                        <!--                    v-model="this.formData.company_lastname"-->
                        <!--                />-->
                        <!--              </div>-->
                        <!--            </div>-->

                        <div class="dashboard__create_item_btns">
                            <button class="dashboard__billing_btn" @click="$router.go()">
                                <span>Cancel</span>
                            </button>
                            <button class="dashboard__billing_btn blue" @click.prevent="sendFormData">
                                <ButtonSpinner v-if="requestLoading"/>
                                <span v-else>Save</span>
                            </button>
                        </div>

                    </div>
                    <div
                        class="dashboard__finance_tabs_conten_item tab-content"
                        :class="currentTab === 'tab_3' ? 'active' : ''"
                        id="tab_3"
                    >

                        <template v-if="finances.length === 0">
                            <div class="dashboard__no_matches">
                                <div class="dashboard__no_matches_icon"><img src="img/statistic/nomatches.svg" alt="">
                                </div>

                                <div class="dashboard__no_matches_heading"><span>No payments found</span></div>
                            </div>
                        </template>

                        <template v-else>
                            <div class="dashboard__finance_payment_heading">
                                <span>Payments history</span>
                            </div>
                            <div class="dashboard__finance_items">
                                <div class="dashboard__finance_item name">
                                    <div class="dashboard__finance_text_name">
                                        <span>DATE</span>
                                    </div>
                                    <div class="dashboard__finance_text_name">
                                        <span>AMOUNT</span>
                                    </div>
                                    <div class="dashboard__finance_text_name">
                                        <span>INFORMATION</span>
                                    </div>
                                </div>

                                <div class="dashboard__finance_item"
                                     v-for="finance in finances"
                                     v-bind:key="finance?.operationId"
                                >
                                    <div class="dashboard__finance_text">
                                        <span>{{ finance?.date }}</span>
                                    </div>
                                    <div class="dashboard__finance_text"><span>{{ finance?.amount }}$</span></div>
                                    <div class="dashboard__finance_text info">
                                        <span>{{ finance?.paymentType }}</span>
                                        <span>{{ finance?.operationId }}</span>
                                    </div>
                                </div>

                            </div>
                        </template>
                        <!--            -->
                        <!--            <div class="pages-mob">-->
                        <!--              <div class="dashboard__pages_counts">-->
                        <!--                <span>Page </span><span class="current-page">1 </span-->
                        <!--                ><span>of </span><span class="al-pages">10 </span>-->
                        <!--              </div>-->
                        <!--              <div class="dashboard__pages_btns">-->
                        <!--                <div class="dashboard__pages_btn previous">-->
                        <!--                  <span>Previous</span>-->
                        <!--                </div>-->
                        <!--                <div class="dashboard__pages_btn next"><span>Next </span></div>-->
                        <!--              </div>-->
                        <!--            </div>-->

                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import MainSidebar from "../../../components/Sidebar/MainSidebar.vue";
import {mapState} from "vuex";
import {COUNTRIES} from "@/Constants";
import ButtonSpinner from "@/components/UI/ButtonSpinner.vue";

export default {
    components: {ButtonSpinner, MainSidebar},

    data() {
        return {
            currentTab: "tab_1",

            currentActivePayType: '',
            paymentAmount: '',

            paymentRedirectLink: 'https://google.com/',

            requestLoading: false,

            formData: {
                type: "",
                companyname: "",
                regnumber: "",
                vatnumber: "",
                country: "",
                address: "",
                city: "",
                email: "",
                phone: "",
                company_firstname: "",
                company_lastname: "",
                firstname: "",
                lastname: "",
            }
        };

    },

    computed: {
        ...mapState({
            balance: state => state.balance.balance,
            //   finances
            finances: state => state.finances.finances,
            // billingdetails
            billingdetails: state => state.billingdetails.billingdetails,
        }),

        countriesData() {
            return COUNTRIES;
        },

        countriesKeys() {
            return Object.keys(COUNTRIES)
        },

        payTypes() {
            return this.balance?.payTypes ? Object.values(this.balance?.payTypes).filter((type) => {
                return this.balance?.allowDeposit?.checked[type.ptype] === 'checked';
            }) : [];
        },

        minSumCorrect() {
            return this.balance?.payTypes ? Number.isInteger(+this.paymentAmount) && +this.paymentAmount >= +this.balance?.payTypes[this.currentActivePayType]?.minSum : false;
        },
    },

    methods: {
        setTab(tabName) {
            this.currentTab = tabName;
        },

        setFormdata(responseData) {
            this.formData = {
                address: responseData?.address,
                city: responseData?.city,
                company_firstname: responseData?.company_firstname,
                company_lastname: responseData?.company_lastname,
                companyname: responseData?.companyname,
                country: responseData?.country,
                email: responseData?.email,
                firstname: responseData?.firstname,
                lastname: responseData?.lastname,
                phone: responseData?.phone,
                regnumber: responseData?.regnumber,
                type: responseData?.type,
                vatnumber: responseData?.vatnumber,
            }
        },

        sendFormData() {
            this.requestLoading = true;

            this.$store.dispatch('updateBillingdetails', {
                data: this.formData
            }).then(() => {
                setTimeout(() => {
                    this.requestLoading = false;
                }, 1000)
            })
        }
    },

    created() {
        this.$store.dispatch('getBalance').then(balanceResponse => {
            this.currentActivePayType = Object.values(balanceResponse?.payTypes).find(p => p.default).ptype;
        });

        this.$store.dispatch('getFinances');
        this.$store.dispatch('getBillingdetails').then(response => {
            this.setFormdata(response)
        });
    }
};
</script>

<style scoped lang="scss">
.dashboard-page {
    background: #89123e;
}

#tab_1 {
    .dashboard__billing_line {
        border-bottom: 0;
        padding-bottom: 0.875em;

        &_input {
            margin-left: 0;
            max-width: 36.375em;
        }
    }
}


.disabled {
    pointer-events: none;
    cursor: auto;
    filter: grayscale(1);
}

.dashboard__create_item_btns {
    margin-top: 2em;
}
</style>
