<template>
    <div class="body-wrap dashboard-page">
        <section class="dashboard">
            <MainSidebar/>

            <div class="dashboard__white_container">
                <div class="dashboard__white_nav">
                    <div class="dashboard__white_nav_left">
                        <div class="dashboard__white_nav_heading">
                            <span>Welcome back, {{ user?.firstName }}</span>
                        </div>
                        <div class="dashboard__white_nav_subheading">
                            <span>Track, manage and create your advertising campaigns.</span>
                        </div>
                    </div>
                    <div class="dashboard__white_nav_right">
                        <div class="dashboard__white_nav_price">
                            <span>Your balance:</span><span>{{ balance?.currentBalance }}$</span>
                        </div>
                        <router-link to="/finance" class="dashboard__white_nav_add_funds">
                            <img src="img/dashboard/plus.svg" alt="">
                            <span>Add funds </span>
                        </router-link>
                    </div>
                </div>
                <div class="dashboard__date_nav">
                    <router-link to="advertising-campaigns/new">
                        <div class="dashboard__date_create_btn">
                            <span>Сreate new campaign</span>
                        </div>
                    </router-link>

                    <RangePicker @change="getStatistics"/>

                </div>
                <div class="dashboard__cards">
                    <div class="dashboard__card_short">
                        <div class="dashboard__card_top">
                            <div class="dashboard__card_heading"><span>Expense</span></div>
                            <!--              <div class="dashboard__card_menu">-->
                            <!--                <img src="img/dashboard/dots.svg" alt="" />-->
                            <!--              </div>-->
                        </div>
                        <div class="dashboard__card_info">
                            <div class="dashboard__card_price">
                                <span class="count">$ {{ stat.debit }}</span>
                            </div>
                            <div class="dashboard__card_price_info" :class="{hidden: +stat.debit === 0}">
                                <div class="dashboard__card_price_info_icon">
                                    <SvgIcon iconName='arrowUp' v-if="stat.debit >= 0"/>
                                    <SvgIcon iconName='arrowDown' v-else/>
                                </div>
                                <div class="dashboard__card_price_info_text">
                                    <span>vs last month</span>
                                </div>
                            </div>
                            <div class="dashboard__card_graph" :class="{hidden: +stat.debit === 0}">
                                <img
                                    :src="`${stat.debit >= 0 ? 'img/dashboard/graph-1.svg' : 'img/dashboard/graph-2.svg' }`"
                                    alt=""/>
                            </div>
                        </div>
                    </div>
                    <div class="dashboard__card_short">
                        <div class="dashboard__card_top">
                            <div class="dashboard__card_heading"><span>Clicks</span></div>
                        </div>
                        <div class="dashboard__card_info">
                            <div class="dashboard__card_price">
                                <span class="count">{{ stat.clicks }}</span>
                            </div>
                            <div class="dashboard__card_price_info" :class="{hidden: +stat.clicks === 0}">
                                <div class="dashboard__card_price_info_icon">
                                    <SvgIcon iconName='arrowUp' v-if="stat.clicks >= 0"/>
                                    <SvgIcon iconName='arrowDown' v-else/>
                                </div>
                                <div class="dashboard__card_price_info_text">
                                    <span>vs last month</span>
                                </div>
                            </div>
                            <div class="dashboard__card_graph" :class="{hidden: +stat.clicks === 0}">
                                <img
                                    :src="`${stat.clicks >= 0 ? 'img/dashboard/graph-1.svg' : 'img/dashboard/graph-2.svg' }`"
                                    alt=""/>
                            </div>
                        </div>
                    </div>
                    <div class="dashboard__card_short">
                        <div class="dashboard__card_top">
                            <div class="dashboard__card_heading"><span>Impressions</span></div>
                        </div>
                        <div class="dashboard__card_info">
                            <div class="dashboard__card_price">
                                <span class="count">{{ stat.impressions }}</span>
                            </div>
                            <div class="dashboard__card_price_info" :class="{hidden: +stat.impressions === 0}">
                                <div class="dashboard__card_price_info_icon">
                                    <SvgIcon iconName='arrowUp' v-if="stat.impressions >= 0"/>
                                    <SvgIcon iconName='arrowDown' v-else/>
                                </div>
                                <div class="dashboard__card_price_info_text">
                                    <span>vs last month</span>
                                </div>
                            </div>
                            <div class="dashboard__card_graph" :class="{hidden: +stat.impressions === 0}">
                                <img
                                    :src="`${stat.impressions >= 0 ? 'img/dashboard/graph-1.svg' : 'img/dashboard/graph-2.svg' }`"
                                    alt=""/>
                            </div>
                        </div>

                    </div>
                    <div class="dashboard__card_long first">
                        <div class="dashboard__card_top">
                            <div class="dashboard__card_heading">
                                <!-- <span>Operating system</span> -->
                                <span>Devices</span>
                            </div>
                            <!--              <div class="dashboard__card_menu">-->
                            <!--                <img src="img/dashboard/dots.svg" alt="" />-->
                            <!--              </div>-->
                        </div>
                        <div class="dashboard__card_circle_block">
                            <div class="dashboard__card_circle">
                                <!--                <img src="img/dashboard/circle-1.svg" alt="" />-->
                                <CircleGraph v-if="!loading && platforms.length"
                                             :graphLabels="platforms ? platforms?.map((v) => v.name) : []"
                                             :graphData="platforms ? platforms?.map((v) => v.percent) : []"/>
                                <!--                  <CircleGraph v-if="platforms.length" :graphLabels="['1', '2', '3']" :graphData="[1, 2, 3]" />-->
                            </div>
                            <div class="dashboard__card_circle_list">
                                <!--                <div class="dashboard__card_circle_list_item"-->
                                <!--                     v-for="platform in platforms"-->
                                <!--                     :key="platform.name"-->
                                <!--                >-->
                                <!--                  <div class="dashboard__card_circle_list_icon grey-1"></div>-->
                                <!--                  <div class="dashboard__card_circle_list_text">-->
                                <!--                    <span>{{platform.name}} – {{platform.percent}}% </span>-->
                                <!--                  </div>-->
                                <!--                </div>-->

                                <!-- <div class="dashboard__card_circle_list_item">
                                  <div class="dashboard__card_circle_list_icon grey-2"></div>
                                  <div class="dashboard__card_circle_list_text">
                                    <span>IOS – 25%</span>
                                  </div>
                                </div>
                                <div class="dashboard__card_circle_list_item">
                                  <div class="dashboard__card_circle_list_icon grey-3"></div>
                                  <div class="dashboard__card_circle_list_text">
                                    <span>Windows – 25%</span>
                                  </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="dashboard__card_long">
                        <div class="dashboard__card_top">
                            <div class="dashboard__card_heading"><span>GEO</span></div>
                            <!--              <div class="dashboard__card_menu">-->
                            <!--                <img src="img/dashboard/dots.svg" alt="" />-->
                            <!--              </div>-->
                        </div>
                        <div class="dashboard__card_circle_block">
                            <div class="dashboard__card_circle">
                                <!--                <img src="img/dashboard/circle-2.svg" alt="" />-->

                                <CircleGraph v-if="!loading && geo.length"
                                             :graphLabels="geo ? geo?.map((v) => v.name) : []"
                                             :graphData="geo ? geo?.map((v) => v.percent) : []"/>
                            </div>
                            <div class="dashboard__card_circle_list">
                                <!--                <div class="dashboard__card_circle_list_item"-->
                                <!--                     v-for="platform in geo"-->
                                <!--                     :key="platform.name"-->
                                <!--                >-->
                                <!--                  <div class="dashboard__card_circle_list_icon grey-1"></div>-->
                                <!--                  <div class="dashboard__card_circle_list_text">-->
                                <!--                    <span>{{platform.name}} – {{platform.percent}}% </span>-->
                                <!--                  </div>-->
                                <!--                </div>-->

                                <!-- <div class="dashboard__card_circle_list_item">
                                  <div class="dashboard__card_circle_list_icon grey-1"></div>
                                  <div class="dashboard__card_circle_list_text">
                                    <span>Ukraine – 20%</span>
                                  </div>
                                </div>
                                <div class="dashboard__card_circle_list_item">
                                  <div class="dashboard__card_circle_list_icon grey-2"></div>
                                  <div class="dashboard__card_circle_list_text">
                                    <span>USA – 23%</span>
                                  </div>
                                </div>
                                <div class="dashboard__card_circle_list_item">
                                  <div class="dashboard__card_circle_list_icon grey-3"></div>
                                  <div class="dashboard__card_circle_list_text">
                                    <span>France – 35%</span>
                                  </div>
                                </div>
                                <div class="dashboard__card_circle_list_item">
                                  <div class="dashboard__card_circle_list_icon grey-4"></div>
                                  <div class="dashboard__card_circle_list_text">
                                    <span>Zanzibar 10%</span>
                                  </div>
                                </div>
                                <div class="dashboard__card_circle_list_item">
                                  <div class="dashboard__card_circle_list_icon grey-5"></div>
                                  <div class="dashboard__card_circle_list_text">
                                    <span>UK 12%</span>
                                  </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import MainSidebar from "@/components/Sidebar/MainSidebar.vue";
import {mapState} from "vuex";
import RangePicker from "@/components/UI/RangePicker.vue"

import SvgIcon from "@/components/UI/SvgIcon.vue";
import CircleGraph from "@/components/UI/CircleGraph.vue";

export default {
    components: {
        CircleGraph,
        MainSidebar,
        RangePicker,
        SvgIcon,
    },

    data() {
        return {
            date: '',
            loading: false
        }
    },

    computed: {
        ...mapState({
            user: (state) => state.user.user,
            // statistics
            statisticsStat: (state) => state.statistics.statisticsStat,
            statisticsPlatform: (state) => state.statistics.statisticsPlatform,
            statisticsGeo: (state) => state.statistics.statisticsGeo,
            // balance
            balance: state => state.balance.balance,
        }),

        stat() {
            return {
                debit: this.statisticsStat[0]?.debit ?? 0,
                clicks: this.statisticsStat[0]?.clicks ?? 0,
                impressions: this.statisticsStat[0]?.impressions ?? 0,
            }
        },

        platforms() {
            const devices = this.statisticsPlatform?.reduce((accum, item) => {
                return accum.find(i => i === item.device) === undefined ? [...accum, item.device] : accum
            }, [])

            const total = this.statisticsPlatform?.length;
            const devicePercent = devices.reduce((accum, device) => {
                const percent = (this.statisticsPlatform.filter(i => i.device === device).length * 100 / total).toFixed();
                return [...accum, {name: `${device} - ${percent}%`, percent: percent}];
            }, [])

            return devicePercent
        },

        geo() {
            const geoS = this.statisticsGeo?.reduce((accum, item) => {
                return accum.find(i => i === item.geo) === undefined ? [...accum, item.geo] : accum
            }, [])

            const total = this.statisticsGeo?.length;
            const geoPercent = geoS.reduce((accum, geo) => {
                const percent = (this.statisticsGeo.filter(i => i.geo === geo).length * 100 / total).toFixed();
                return [...accum, {name: `${geo} - ${percent}%`, percent: percent}];
                // return [...accum, {name: geo, percent: (this.statisticsGeo.filter(i => i.geo === geo).length * 100 / total).toFixed(2)}]
            }, [])

            return geoPercent
        },
    },

    methods: {
        getStatistics([from, to]) {
            this.loading = true;
            this.$store.dispatch("getStatisticsSPC", {
                from: from.format('DD.MM.YYYY'),
                to: to.format('DD.MM.YYYY')
            }).then(() => {
                this.loading = false;
            })
        }
    },

    mounted() {
        this.$store.dispatch('getBalance');
    },
};
</script>

<style scoped lang="scss">
.dashboard-page {
    background: #89123e;
}

.hidden {
    visibility: hidden;
}
</style>
