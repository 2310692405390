<template>
    <div class="body-wrap dashboard-page">
        <section class="dashboard">

            <MainSidebar/>

            <div class="dashboard__white_container">
                <div class="dashboard__white_nav">
                    <div class="dashboard__white_nav_left">
                        <div class="dashboard__white_nav_heading">
                            <span>Statistic</span>
                        </div>
                        <div class="dashboard__white_nav_subheading">
                            <span>Select period and filters to view data</span>
                        </div>
                    </div>
                    <div class="dashboard__white_nav_right">
                        <div class="dashboard__white_nav_price">
                            <span>Your balance:</span>
                            <span>{{ balance?.currentBalance }}$</span>
                        </div>
                        <router-link to="/finance" class="dashboard__white_nav_add_funds" v-if="isAdvertisor">
                            <img src="img/dashboard/plus.svg" alt="">
                            <span>Add funds </span>
                        </router-link>
                    </div>
                </div>
                <div class="dashboard__search_block">
                    <div class="dashboard__search">
                        <input class="search" type="text" name="search" placeholder="Search for ID or name"
                               v-model="searchValue">
                    </div>
                    <div class="dashboard__filter_block">
                        <div class="dashboard__date_btn filter" @click="activeFilter = !activeFilter" v-if="isAdvertisor">
                            <img src="img/statistic/filter.svg" alt="">
                            <span>Filters</span>
                        </div>

                        <RangePicker @change="filter"/>
                    </div>
                    <div class="dashboard__filter_stats" :class="activeFilter && 'show'">
                        <div class="dashboard__filter_stat_item"><span>Campaign:</span>
                            <div class="dashboard__filter_select">
                                <select id="filterCampaign" name="filterCampaign" v-model="filterCampaign[0]">
                                    <option value="">All</option>
                                    <option v-for="(campaign) in campaignsList"
                                            :key="campaign.id"
                                            :value="campaign.id"
                                    >
                                        {{ campaign.name }}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="dashboard__filter_btns">
                            <button class="dashboard__filter_btn_no" @click.prevent="initStatisticList">
                                <img src="img/statistic/no.svg" alt="">
                            </button>
                            <button class="dashboard__filter_btn_yes" @click.prevent="sendFilter">
                                <img src="img/statistic/yes.svg" alt="">
                            </button>
                        </div>
                    </div>
                </div>

                <DataLoader v-if="requestLoading"/>

                <template v-else>
                    <div class="dashboard__no_matches" v-if="searchFilteredList.length === 0">
                        <div class="dashboard__no_matches_icon"><img src="img/statistic/nomatches.svg" alt=""></div>

                        <template v-if="isAdvertisor">
                            <div class="dashboard__no_matches_heading"><span>No campaigns found</span></div>
                            <div class="dashboard__no_matches_text"><span>Your search did not match any campaigns. <br>Please try again or create add a new campaign.</span></div>
                        </template>
                        <template v-else>
                            <div class="dashboard__no_matches_heading"><span>No sites found</span></div>
                            <div class="dashboard__no_matches_text"><span>Your search did not match any sites. <br>Please try again.</span></div>
                        </template>

                        <div class="dashboard__no_matches_btns">
                            <button class="dashboard__no_matches_btn clear" @click="initStatisticList">
                                <span>Clear search</span>
                            </button>
                            <template v-if="isAdvertisor">
                                &nbsp;
                                &nbsp;
                                &nbsp;
                                <button class="dashboard__no_matches_btn add-campaign"
                                    @click="$router.push('/advertising-campaigns/new')">
                                    <img src="img/dashboard/plus.svg" alt="">
                                    <span>Add campaign</span>
                                </button>
                            </template>
                        </div>
                    </div>

                    <div class="dashboard__statistics_items" v-else>
                        <div class="dashboard__statistics_item name-item">
                            <div class="dashboard__statistics_item_checkbox">
                                <input class="checkboxStat custom-checkbox-stat" id="nameCheck" type="checkbox"
                                       name="checkboxCustom" value="">
                                <label for="nameCheck"></label>
                            </div>
                            <div class="dashboard__statistics_item_idname">
                                <span v-if="isAdvertisor">ID/CAMPAIGN NAME</span>
                                <span v-else>ID/SITE NAME</span>
                            </div>
                            <div class="dashboard__statistics_item_date"><span>DATE</span></div>
                            <div class="dashboard__statistics_item_imp"><span>IMPRESSIONS</span></div>
                            <div class="dashboard__statistics_item_clicks"><span>CLICKS</span></div>
                            <div class="dashboard__statistics_item_spent">
                                <span v-if="isAdvertisor">SPENT</span>
                                <span v-else>Profit</span>
                            </div>
                            <div class="dashboard__statistics_item_ecpm"><span>eCPM, $</span></div>
                            <div class="dashboard__statistics_item_cpc"><span>CPC, $</span></div>
                        </div>

                        <div class="dashboard__statistics_item" v-for="statisticsItem in itemsList"
                             :key="statisticsItem.id">
                            <div class="dashboard__statistics_item_checkbox">
                                <input class="checkboxStat custom-checkbox-stat" id="itemCheck1" type="checkbox"
                                       name="checkboxCustom" value="">
                                <label for="itemCheck1"></label>
                            </div>
                            <div class="dashboard__statistics_item_idname">
                                <template v-if="isAdvertisor">
                                    <span class="idname">{{ statisticsItem.campaingId }}</span>
                                    <span>{{ statisticsItem.campaingName }}</span>
                                </template>
                                <template v-else>
                                    <span class="idname">{{ statisticsItem.siteId }}</span>
                                    <span>{{ statisticsItem.siteName }}</span>
                                </template>
                            </div>
                            <div class="dashboard__statistics_item_date">
                                <span>{{ statisticsItem.period }}</span>
                            </div>
                            <div class="dashboard__statistics_item_imp">
                                <span>{{ statisticsItem.impressions }}</span>
                            </div>
                            <div class="dashboard__statistics_item_clicks">
                                <span>{{ statisticsItem.clicks }}</span>
                            </div>
                            <div class="dashboard__statistics_item_spent">
                                <template v-if="isAdvertisor">
                                    <span>{{ statisticsItem.debit }}</span>
                                </template>
                                <template v-else>
                                    <span>{{ statisticsItem?.credit }}</span>
                                </template>
                            </div>
                            <div class="dashboard__statistics_item_ecpm">
                                <span v-if="isAdvertisor">{{ statisticsItem.cmpa }}</span>
                                <span v-else>{{ statisticsItem.cpmw }}</span>
                            </div>
                            <div class="dashboard__statistics_item_cpc">
                                <span v-if="isAdvertisor">{{ statisticsItem.cpca }}</span>
                                <span v-else>{{ statisticsItem.cpcw }}</span>
                            </div>
                        </div>

                        <Pagination
                            :currentPage="currentPage"
                            :totalItems="searchFilteredList.length"
                            :pageSize="pageSize"
                            @update="updatePage"
                        />
                    </div>
                </template>

                <Pagination
                    :mobile="true"
                    :currentPage="currentPage"
                    :totalItems="searchFilteredList.length"
                    :pageSize="pageSize"
                    @update="updatePage"
                />
            </div>
        </section>
    </div>
</template>

<script>
import MainSidebar from '../../components/Sidebar/MainSidebar.vue';
import RangePicker from "@/components/UI/RangePicker.vue";
import DataLoader from "@/components/UI/DataLoader.vue";
import {mapState} from "vuex";
import Pagination from "@/components/Pagination/Pagination.vue";
import moment from 'moment';

export default {
    components: {
        Pagination,
        RangePicker,
        MainSidebar,
        DataLoader,
    },

    data() {
        return {
            activeFilter: false,
            requestLoading: false,

            currentPage: 1,
            pageSize: 10,

            searchValue: '',

            filterData: {
                from: '',
                to: '',
            },

            filterCampaign: ['']
        }
    },

    computed: {
        ...mapState({
            statisticsList: state => state.statistics.statisticsList,
            // user
            user: state => state.user.user,
            // balance
            balance: state => state.balance.balance,
            // campaigns
            campaignsList: (state) => state.campaigns.campaignsList,
        }),

        // searchFilteredList
        searchFilteredList() {
            if (this.searchValue === '') return this.statisticsList;
            return this.statisticsList.filter(c => c.campaingName?.toLowerCase().includes(this.searchValue.toLowerCase()));
        },

        itemsList() {
            return this.searchFilteredList.slice(this.pageSize * (this.currentPage - 1 ?? 0), this.pageSize * this.currentPage)
        },

        isAdvertisor() {
            return Object.keys(this.user).length !== 0 && this.user.usertype === 'advertiser'
        }
    },

    methods: {
        checkUserType() {
            // if (this.isAdvertisor) {
            //     delete this.filterData.bysites;
            //     this.filterData.bycampaigns = 'on';
            // } else {
            //     delete this.filterData.bycampaigns;
            //     this.filterData.bysites = 'on';
            // }
            this.filterData.bysites = 'on';
        },

        initStatisticList() {
            this.searchValue = '';
            this.requestLoading = true;
            this.checkUserType();

            this.$store.dispatch('getStatistics', {
                data: {
                    ...this.filterData,

                    from: moment().date(-30 * 2).format('DD.MM.YYYY'), // current date - 2 month
                    to: moment().format('DD.MM.YYYY'), // current date
                }
            }).finally(() => {
                setTimeout(() => {
                    this.requestLoading = false;
                    this.currentPage = 1;
                }, 1000)
            })
        },

        filter([from, to]) {
            this.searchValue = '';
            this.checkUserType();
            // const data = {};

            this.filterData.from = from.format('DD.MM.YYYY');
            this.filterData.to = to.format('DD.MM.YYYY');
            //
            if (this.filterCampaign[0] !== '') this.filterData.campaign = Array.from([this.filterCampaign[0]]);

            this.requestLoading = true;

            this.$store.dispatch('getStatistics', {
                data: this.filterData
            }).finally(() => {
                setTimeout(() => {
                    this.requestLoading = false;
                    this.currentPage = 1;
                }, 1000)
            })
        },

        sendFilter() {
            this.filter([moment(this.filterData.from, 'DD.MM.YYYY'), moment(this.filterData.to, 'DD.MM.YYYY')])
        },

        updatePage(current) {
            this.requestLoading = true;
            this.currentPage = current;

            setTimeout(() => {
                this.requestLoading = false;
            }, 1000)
        },
    },

    created() {
        this.$store.dispatch('getUser');

        if (this.isAdvertisor) {
            this.$store.dispatch('getCampaigns', {data: {}})
        }

        this.$store.dispatch('getBalance');
    }
};
</script>

<style scoped lang="scss">
.dashboard-page {
    background: #89123e;
}
</style>
