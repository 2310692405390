<template>
    <div class="sidebar">
        <router-link class="sidebar__logo" to="/dashboard">
            <img src="img/dashboard/logo.svg" alt="" />
        </router-link>
        <div class="sidebar__menu_block_1">
        </div>
        <div class="sidebar__menu_block_2">
            <div class="sidebar__menu">
                <router-link
                    v-for="(route, index) in staticRouts"
                    :key="route.path"
                    :to="route.path"
                    class="sidebar__menu_item"
                    :class="{ active: $route.fullPath === route.path }"
                >
                    <img :src="`img/sidebar/item-${index+7}.svg`" alt=""/>
                    <span>{{ route.name }}</span>
                </router-link>
            </div>
            <div class="sidebar__zoom_block">
                <div class="sidebar__zoom_minus" @click="zoomMinus"><img src="img/dashboard/zoom-out.svg" alt=""></div>
                <div class="sidebar__zoom_current_text"><span>UI scale</span><span class="pers">{{ zoom * 100 }}%</span>
                </div>
                <div class="sidebar__zoom_plus" @click="zoomPlus"><img src="img/dashboard/zoom-in.svg" alt=""></div>
            </div>
            <div class="sidebar__logout_block sidebar__bottom">
                <router-link to="/registration" class="sidebar__bottom-link">
                    <span>Register</span>
                </router-link>
                <router-link to="/login" class="sidebar__bottom-link sidebar__bottom-link--bordered">
                    <span>Log in</span>
                </router-link>
            </div>
        </div>
        <div class="sidebar__mob_menu">
            <div id="burger" @click='toggleMenu' :class="menuActive && 'open'">
                <span> </span><span class="ultinate"></span><span> </span>
            </div>
            <nav class="nav" id="nav" :class="menuActive && 'nav-open'">
                <div class="nav__menu_block">
                    <div class="sidebar__menu_block_1_mob">
                        <div class="sidebar__menu">
                            <router-link
                                v-for="(route, index) in routs"
                                :key="route.path"
                                :to="route.path"
                                class="sidebar__menu_item"
                                :class="{ active: $route.fullPath === route.path }"
                            >
                                <img :src="`img/sidebar/item-${index+1}.svg`" alt=""/>
                                <span>{{ route.name }}</span>
                            </router-link>
                        </div>
                    </div>
                    <div class="sidebar__menu_block_2_mob">
                        <div class="sidebar__menu">
                            <router-link
                                v-for="(route, index) in staticRouts"
                                :key="route.path"
                                :to="route.path"
                                class="sidebar__menu_item"
                                :class="{ active: $route.fullPath === route.path }"
                            >
                                <img :src="`img/sidebar/item-${index+7}.svg`" alt=""/>
                                <span>{{ route.name }}</span>
                            </router-link>
                        </div>
                        <div class="sidebar__logout_block sidebar__bottom">
                            <router-link to="/registration" class="sidebar__bottom-link">
                                <span>Register</span>
                            </router-link>
                            <router-link to="/login" class="sidebar__bottom-link sidebar__bottom-link--bordered">
                                <span>Log in</span>
                            </router-link>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
    data() {
        return {
            mainRouts: {
                advertiser: [
                    {
                        name: "Dashboard",
                        path: "/dashboard",
                    },
                    {
                        name: "Statistic",
                        path: "/statistic",
                    },
                    {
                        name: "Finance",
                        path: "/finance",
                    },
                    {
                        name: "Traffic Chart",
                        path: "/traffic-chart",
                    },
                    {
                        name: "Advertising campaigns",
                        path: "/advertising-campaigns",
                    },
                    {
                        name: "Profile settings",
                        path: "/profile-settings",
                    },
                ],
                webmaster: [
                    {
                        name: "Statistic",
                        path: "/dashboard",
                    },
                    {
                        name: "Finance",
                        path: "/finance",
                    },
                    {
                        name: "Sites",
                        path: "/sites",
                    },
                    {
                        name: "Referral program",
                        path: "/referral",
                    },
                    {
                        name: "Profile settings",
                        path: "/profile-settings",
                    },
                ],

            },

            staticRouts: [
                // {
                //   name: "Support",
                //   path: "/support",
                // },
                {
                    name: "Terms and conditions",
                    path: "/terms",
                },
                {
                    name: "Privacy policy",
                    path: "/privacy-policy",
                },
            ],

            zoom: 1,

            menuActive: false
        };
    },

    computed: {
        ...mapState({
            user: state => state.user.user
        }),

        routs() {
            return this.mainRouts[this.user?.usertype];
        }
    },

    methods: {
        exit() {
            localStorage.removeItem('auth');
            this.$router.push('/login')
        },
        zoomPlus() {
            this.zoom = (this.zoom > 1) ? this.zoom : this.zoom + .25;

            localStorage.setItem('zoom', this.zoom);
            window.document.querySelector('html').style.zoom = this.zoom
        },

        zoomMinus() {
            this.zoom = (this.zoom < 1) ? this.zoom : this.zoom - .25;

            localStorage.setItem('zoom', this.zoom);
            window.document.querySelector('html').style.zoom = this.zoom
        },

        checkZoomValue() {
            const zoomValue = JSON.parse(localStorage.getItem('zoom'));

            if (zoomValue !== null) {
                this.zoom = zoomValue;
                window.document.querySelector('html').style.zoom = zoomValue;
            }
        },

        toggleMenu() {
            this.menuActive = !this.menuActive;
        }
    },

    created() {

        this.checkZoomValue();
    }
};
</script>

<style scoped lang="scss">
.sidebar__bottom {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebar__bottom-link {
    padding: .4em .9em;
    border-radius: 1em;
    color: #fff;
    transition: background .2s ease-in-out;
    margin: 0 .3em;

    &:hover {
        background: #fff;

        span {
            color: #89123e;
        }
    }

    &--bordered {
        border: .1em solid #fff;
    }
}

.sidebar__mob_menu .sidebar__bottom {
    font-size: 1.3em;
}
</style>
