<template>
    <component :is="componentName"/>
</template>

<script>
import EmptyPage from "../EmptyPage.vue"
import DashboardAdvertiser from "./components/DashboardAdvertiser.vue"
import DashboardWebmaster from "./components/DashboardWebmaster.vue"
import {mapState} from "vuex";

export default {
    components: {
        EmptyPage,
        DashboardAdvertiser,
        DashboardWebmaster,
    },

    computed: {
        ...mapState({
            user: state => state.user.user
        }),

        componentName() {
            if (!this.user?.usertype) return 'EmptyPage';
            // return this.mainRouts[this.user?.usertype];
            return this.user?.usertype === 'advertiser' ? 'DashboardAdvertiser' : 'DashboardWebmaster';
        }
    },
};
</script>

<style scoped lang="scss">
.dashboard-page {
    background: #89123e;
}
</style>
