import sendRequest from '../../sendRequest'

export default {
    state: {
        countries: [],
        regionsList: [],
    },

    mutations: {
        setCountries(state, countries) { state.countries = countries },
        setRegionsList(state, regionsList) { state.regionsList = regionsList },
    },

    actions: {
        getCountries({commit, dispatch}) {
            sendRequest({method: 'get', endpoint: '/geo?type=country'}).then(data => {
                commit('setCountries', data.countries);

                dispatch('getRegionsList', {country: Object.keys(data.countries)[0]})
            })
        },

        getRegionsList({commit}, {country}) {
            return new Promise((resolve, reject) => {
                sendRequest({method: 'get', endpoint: `/geo?type=region&countries[]=${country}`}).then(response => {
                    commit('setRegionsList', response.data.regionsList.items);
                    resolve(response.data.regionsList.items)
                }).catch((err) => {
                    commit('setRegionsList', []);
                    reject(err)
                })
            })
        },
    }
}
