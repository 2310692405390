<template>
    <div class="copiedModal" :class="active && 'active'">
        <span>{{message}}</span>
    </div>
</template>

<script>
export default {
    name: 'CopiedModal',

    props: ['active', 'message']
}
</script>

<style lang="scss">
.copiedModal {
    background: #101828;
    padding: .73em 1em;
    border-radius: .8em;
    position: absolute;
    right: 0;
    bottom: -2em;
    z-index: 888;
    pointer-events: none;
    transition: opacity .2s ease-in-out;
    opacity: 0;

    &.active {
        opacity: 1;
    }

    span {
        color: #fff;
    }
}
</style>
