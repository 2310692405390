<template>
    <div class="body-wrap">
        <header
            class="header anim-fade"
            ref="heading1"
            :class="{ 'anim-fade': animations.anim1 === true }"
            style="animation-delay: 0.1s"
        >
            <div class="header__container">
                <a class="header__logo" href="/">
                    <img src="img/header/logo.svg" alt=""/>
                </a>
                <div class="header__btns">
                    <router-link class="header__btn_register" to="/registration">
                        <span>Register</span>
                    </router-link>

                    <router-link class="header__btn_login" to="/login">
                        <span>Log in</span>
                    </router-link>
                </div>
            </div>
        </header>
        <div class="bg-item-1"><img src="img/home-1/bg-top.png" alt=""/></div>
        <section class="home-1">
            <div class="home-1__bg_item_1">
                <img src="img/home-1/bg-center.png" alt=""/>
            </div>
            <div class="container">
                <div class="home-1__text_block">
                    <div
                        class="home-1__text_1"
                        :class="{ 'anim-fade': animations.anim1 === true }"
                        style="animation-delay: 0"
                    >
            <span>
              <span>Smart user acquisition </span>for media agencies, brands
              <br/>& affiliates.</span
            >
                    </div>
                    <div
                        class="home-1__text_2"
                        :class="{ 'anim-fade': animations.anim1 === true }"
                        style="animation-delay: 0.1s"
                    >
            <span
            >Multisource advertising platform with 1Bln+ global audience to
              reach new users and grow your business</span
            >
                    </div>
                    <router-link class="home-1__btn"
                                 to="/registration"
                                 :class="{ 'anim-fade': animations.anim1 === true }"
                                 style="animation-delay: .3s"
                    >
                        <span>Launch my first campaign</span>
                    </router-link>

                </div>
                <div class="home-1__image_block">
                    <div class="home-1__image">
                        <img src="img/home-1/main-image.png" alt=""
                             ref="heading2"
                             :class="{ 'anim-fade': animations.anim1 === true }"
                             style="animation-delay: 0s"/>
                    </div>
                    <div class="home-1__image_bg_item_1 home1items"
                         :class="{ 'anim-fade': animations.anim2 === true }"
                         style="animation-delay: 0s">
                        <div class="parallax">
                            <div class="dop-block" data-depth="1.4">
                                <img src="img/home-1/bg-item-1.png" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div class="home-1__image_bg_item_2 home1items"
                         :class="{ 'anim-fade': animations.anim2 === true }"
                         style="animation-delay: 0.1s">
                        <div class="parallax">
                            <div class="dop-block" data-depth="-1.4">
                                <img src="img/home-1/bg-item-2.png" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div class="home-1__image_bg_item_3 home1items"
                         :class="{ 'anim-fade': animations.anim2 === true }"
                         style="animation-delay: 0.2s">
                        <div class="parallax">
                            <div class="dop-block" data-depth="-0.7">
                                <img src="img/home-1/bg-item-3.png" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div class="home-1__image_bg_item_4 home1items"
                         :class="{ 'anim-fade': animations.anim2 === true }"
                         style="animation-delay: 0.3s">
                        <div class="parallax">
                            <div class="dop-block" data-depth="1.4">
                                <img src="img/home-1/bg-item-4.png" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div class="home-1__image_bg_item_5 home1items"
                         :class="{ 'anim-fade': animations.anim2 === true }"
                         style="animation-delay: 0.4s">
                        <div class="parallax">
                            <div class="dop-block" data-depth="-0.4">
                                <img src="img/home-1/bg-item-5.png" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div class="home-1__image_bg_item_6 home1items"
                         :class="{ 'anim-fade': animations.anim2 === true }"
                         style="animation-delay: 0.5s">
                        <div class="parallax">
                            <div class="dop-block" data-depth="0.8">
                                <img src="img/home-1/bg-item-6.png" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="home-2">
            <div class="home-2__bg_item_1" :class="{ 'anim-fade': animations.anim3 === true }"
                 style="animation-delay: 0.4s">
                <div class="parallax">
                    <div class="dop-block" data-depth="-0.4">
                        <img src="img/home-2/bg-item-1.png" alt=""/>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="home-2__items vis_on_scroll" ref="heading3">
                    <div class="home-2__item" :class="{ 'anim-fade': animations.anim3 === true }"
                         style="animation-delay: 0.2s">
                        <div class="home-2__item_img">
                            <img src="img/home-2/item-1.svg" alt=""/>
                        </div>
                        <div class="home-2__item_text">
                            <span>Fast and timely <br/>payments</span>
                        </div>
                    </div>
                    <div class="home-2__item" :class="{ 'anim-fade': animations.anim3 === true }"
                         style="animation-delay: 0.4s">
                        <div class="home-2__item_img">
                            <img src="img/home-2/item-2.svg" alt=""/>
                        </div>
                        <div class="home-2__item_text">
                            <span>24/7 Online <br/>Support</span>
                        </div>
                    </div>
                    <div class="home-2__item" :class="{ 'anim-fade': animations.anim3 === true }"
                         style="animation-delay: 0.6s">
                        <div class="home-2__item_img">
                            <img src="img/home-2/item-3.svg" alt=""/>
                        </div>
                        <div class="home-2__item_text">
                            <span>Responsive<br/>managers</span>
                        </div>
                    </div>
                    <div class="home-2__item" :class="{ 'anim-fade': animations.anim3 === true }"
                         style="animation-delay: 0.8s">
                        <div class="home-2__item_img">
                            <img src="img/home-2/item-4.svg" alt=""/>
                        </div>
                        <div class="home-2__item_text">
                            <span>Format:<br/>InPage Push</span>
                        </div>
                    </div>
                    <div class="home-2__item" :class="{ 'anim-fade': animations.anim3 === true }"
                         style="animation-delay: 1s">
                        <div class="home-2__item_img item-5">
                            <img src="img/home-2/item-5.svg" alt=""/>
                        </div>
                        <div class="home-2__item_text">
                            <span>Smart approach<br/>to webmaster rates</span>
                        </div>
                    </div>
                    <div class="home-2__item" :class="{ 'anim-fade': animations.anim3 === true }"
                         style="animation-delay: 1.2s">
                        <div class="home-2__item_img">
                            <img src="img/home-2/item-6.svg" alt=""/>
                        </div>
                        <div class="home-2__item_text">
                            <span>We monetize<br/>all traffic</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="home-3">
            <div class="home-3__bg_item_2">
                <img src="img/home-3/bg-item-2.png" alt=""/>
            </div>
            <div class="container">
                <div class="home-3__text_block">
                    <div class="home-3__text_1 vis_on_scroll" ref="heading4">
                        <span> <span>InPage </span>push</span>
                    </div>
                    <div class="home-3__text_2" :class="{ 'anim-fade': animations.anim4 === true }"
                         style="animation-delay: 0.2s">
            <span
            >Reach your audience with ads on trusted and verified<br/>websites
              worldwide. Our priority is to deliver safe<br/>and top-performing
              traffic across all Geo’s and Ad formats. <br/>DogTraff’s
              dedicated Ad Operations Team <br/>at every turn monitors the
              audience engagement <br/>and does the most to optimize the cost
              of the TA reach to build <br/>highly effective coverage.</span
            >
                    </div>
                </div>
                <div class="home-3__image_block">
                    <div class="home-3__image" :class="{ 'anim-fade': animations.anim4 === true }"
                         style="animation-delay: 0.4s">
                        <img src="img/home-3/main-image.png" alt=""/>
                    </div>
                    <div class="home-3__bg_item_1" :class="{ 'anim-fade': animations.anim4 === true }"
                         style="animation-delay: 0.6s">
                        <div class="parallax">
                            <div class="dop-block" data-depth="0.8">
                                <img src="img/home-3/bg-item-1.png" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="home-4">
            <div class="container">
                <div class="home-4__items vis_on_scroll" ref="heading5">
                    <div class="home-4__item" :class="{ 'anim-fade': animations.anim5 === true }"
                         style="animation-delay: 0.2s">
                        <div class="home-4__item_text_1 home-item-4" :class="{ 'anim-fade': animations.anim5 === true }"
                             style="animation-delay: 0.2s">
                            <span>For <span>Advertisers</span></span>
                        </div>
                        <div class="home-4__item_text_2 home-item-4" :class="{ 'anim-fade': animations.anim5 === true }"
                             style="animation-delay: 0.4s">
              <span
              >Our 24/7 customer support, every minute is ready to help you
                and optimize your advertising campaign.</span
              ><span
                        >However, if you prefer to do everything yourself, with your own
                hands, we will not disturb you. <br/>But we will be there for
                you.
              </span>
                        </div>
                    </div>
                    <div class="home-4__item grey" :class="{ 'anim-fade': animations.anim5 === true }" ref="advertiser"
                         style="animation-delay: 0.6s">
                        <div class="home-4__cup">
                            <img class="home-item-4" src="img/home-4/cup.png" alt=""
                                 :class="{ 'anim-fade': animations.anim5 === true }" style="animation-delay: 0.6s"/>
                        </div>
                        <router-link
                            to="/registration"
                            class="home-4__btn home-item-4" :class="{ 'anim-fade': animations.anim5 === true }"
                            style="animation-delay: 0.8s"
                        >
                            <span>Become an advertiser</span>
                        </router-link>

                    </div>
                    <div class="home-4__item grey" :class="{ 'anim-fade': animations.anim5 === true }" ref="publisher"
                         style="animation-delay: 0.8s">
                        <div class="home-4__box">
                            <img class="home-item-4" src="img/home-4/box.png" alt=""
                                 :class="{ 'anim-fade': animations.anim5 === true }" style="animation-delay: 1s"/>
                        </div>
                        <router-link to="/registration" class="home-4__btn home-item-4"
                                     :class="{ 'anim-fade': animations.anim5 === true }" style="animation-delay: 1.2s"
                        >
                            <span>Become a Publisher</span>
                        </router-link>
                    </div>
                    <div class="home-4__item" :class="{ 'anim-fade': animations.anim5 === true }"
                         style="animation-delay: 1s">
                        <div class="home-4__mark">
                            <img class="home-item-4" src="img/home-4/mark.png" alt=""
                                 :class="{ 'anim-fade': animations.anim5 === true }" style="animation-delay: 1.4s"/>
                        </div>
                        <div class="home-4__item_text_1 home-item-4" :class="{ 'anim-fade': animations.anim5 === true }"
                             style="animation-delay: 1.2s">
                            <span>For <span>Publishers</span></span>
                        </div>
                        <div class="home-4__item_text_2 home-item-4" :class="{ 'anim-fade': animations.anim5 === true }"
                             style="animation-delay: 1.4s">
              <span
              >DogTraff. We will buy traffic from you, teach you how to cook
                it properly.<br/>We pay you quickly. We buy expensive. </span
              ><span
                        >But we'll sniff out if you have rogue traffic and take a bite
                out of it. <br/>We care about the safety of our
                customers.</span
                        >
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="home-5">
            <div class="container">
                <div class="home-5__image" :class="{ 'anim-fade': animations.anim6 === true }"
                     style="animation-delay: 0s">
                    <img src="img/home-5/main-image.png" alt=""/>
                </div>
                <div class="home-5__text_block">
                    <div class="home-5__text_1 vis_on_scroll" ref="heading6">
                        <span> <span>About </span>agency</span>
                    </div>
                    <div class="home-5__text_2" :class="{ 'anim-fade': animations.anim6 === true }"
                         style="animation-delay: 0s">
            <span
            >We'll sniff out your new client!<br/>The era of old-fashioned
              customer acquisition is a thing of the past. <br/>Trust your
              budget to a team of professional analytics sniffers.<br/>For
              years, we've been protecting our clients' interests without going
              public.As special agents, we know everything, see everything, and
              smell money a mile away. Now we are ready to make our knowledge
              and capabilities available to everyone. <br/>Woof! Woof!</span
            >
                    </div>
                </div>
            </div>
        </section>

        <section class="form-section" id="contacts">
            <div class="form-section__container" ref="heading7">
                <div class="form-section__image vis_on_scroll " :class="{ 'visible': animations.anim7 === true }">
                    <img src="img/form-section/form-image.jpg" alt="" srcset="">
                </div>
                <div class="form-section__form_block vis_on_scroll" ref="heading8">
                    <div class="form-section__text_1" :class="{ 'anim-fade': animations.anim7 === true }"
                         style="animation-delay: 0s"><span>Get in touch</span></div>
                    <div class="form-section__text_2" :class="{ 'anim-fade': animations.anim7 === true }"
                         style="animation-delay: .2s">
            <span
            >You can reach us anytime via
              <a href="mailto:office@dogtraff.agency"
              >office@dogtraff.agency</a
              ></span
            >
                    </div>
                    <div class="form-section__form" :class="{ 'anim-fade': animations.anim7 === true }"
                         style="animation-delay: .4s">
                        <form class="form" id="contactForm" action="">
                            <div class="form__wrapper">
                                <label for="name">Name</label>
                                <input
                                    class="form__input"
                                    id="name"
                                    type="text"
                                    name="name"
                                    placeholder="Your name"
                                    data-name="name"
                                    data-valid="false"
                                    v-model="formValues.username"
                                /><span class="error-item">Invalid format of name</span>
                            </div>
                            <div class="form__wrapper">
                                <label for="email">Email</label>
                                <input
                                    class="form__input"
                                    id="email"
                                    type="email"
                                    name="email"
                                    placeholder="you@company.com"
                                    data-name="email"
                                    data-valid="false"
                                    v-model="formValues.mail"
                                /><span class="error-item">Invalid format of email</span>
                            </div>
                            <div class="form__wrapper comp">
                                <label for="company">Company</label>
                                <input
                                    class="form__input"
                                    id="company"
                                    type="text"
                                    name="company"
                                    placeholder="Your company"
                                    data-name="company"
                                    data-valid="false"
                                    v-model="formValues.company"
                                /><span class="error-item">Invalid format of name</span>
                            </div>
                            <div class="form__wrapper mess">
                                <label for="message">How can we help?</label>
                                <textarea
                                    class="form__message"
                                    name="message"
                                    placeholder="Tell us a little about the project..."
                                    v-model="formValues.message"
                                ></textarea>
                            </div>
                            <button class="form__btn" id="formBtn" type="button" :disabled="hasErrors"
                                    @click="sendFeedback">
                                <span>Get started</span>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </section>

        <footer class="footer vis_on_scroll">
            <div class="container">
                <a class="footer__logo" href="/" ref="heading9" :class="{ 'anim-fade': animations.anim7 === true }"
                   style="animation-delay: 0s"
                ><img src="img/footer/logo.svg" alt=""
                /></a>
                <div class="footer__menu">
                    <div @click="scrollTo($refs.advertiser)"
                         class="footer__menu_item footer-item"
                         :class="{ 'anim-fade': animations.anim7 === true }" style="animation-delay: .2s"
                    >
                        <span>Advertiser</span>
                    </div>
                    <div @click="scrollTo($refs.publisher)"
                        class="footer__menu_item footer-item" :class="{ 'anim-fade': animations.anim7 === true }"
                       style="animation-delay: .4s"
                    >
                        <span>Publisher</span>
                    </div>
                    <!--            <a class="footer__menu_item footer-item" :class="{ 'anim-fade': animations.anim7 === true }" style="animation-delay: .6s" href="#"-->
                    <!--            ><span>Agency</span></a-->
                    <!--          >-->

<!--                    <a class="footer__menu_item footer-item" :class="{ 'anim-fade': animations.anim7 === true }"-->
<!--                       style="animation-delay: .8s" href="#contacts"-->
<!--                    ><span>Contacts</span></a-->
<!--                    >-->
                    <router-link to="/privacy-policy" class="footer__menu_item footer-item" :class="{ 'anim-fade': animations.anim7 === true }"
                        style="animation-delay: 1s"
                    >
                        <span>Privacy</span>
                    </router-link>
                <router-link to="/terms" class="footer__menu_item footer-item" :class="{ 'anim-fade': animations.anim7 === true }"
                    style="animation-delay: 1.2s"
                >
                    <span>Terms and conditions</span>
                </router-link>
                </div>
                <div class="footer__copyrights footer-item" :class="{ 'anim-fade': animations.anim7 === true }"
                     style="animation-delay: 1.4s">
                    <span>© 2023 DogTraff</span>
                </div>
            </div>
        </footer>
        <div class="modal-window modal-review">
            <div class="modal">
                <div class="modal__close close"></div>
                <div class="modal__heading"><span>Оставить отзыв</span></div>
                <div class="modal__form_block">
                    <form class="form" id="reviewForm" action="">
                        <div class="form__doble_input">
                            <div class="form__wrapper notwide">
                                <span class="error-item">Maybe You did some mistake</span>
                                <input
                                    class="form__input form__name"
                                    id="name"
                                    type="text"
                                    name="name"
                                    placeholder="Vorname"
                                    data-name="name"
                                    data-valid="false"
                                />
                            </div>
                            <div class="form__wrapper notwide">
                                <span class="error-item">Maybe You did some mistake</span>
                                <input
                                    class="form__input form__surname"
                                    id="surname"
                                    type="text"
                                    name="surname"
                                    placeholder="Nachname"
                                    data-name="name"
                                    data-valid="false"
                                />
                            </div>
                        </div>
                        <div class="form__wrapper">
              <textarea
                  class="form__message"
                  name="textarea"
                  placeholder="Comment"
              ></textarea>
                        </div>
                        <div class="form__stars_block">
                            <div class="form__stars_heading"><span>Service level</span></div>
                            <div class="form__stars">
                                <input id="star5" type="radio" name="star" value="5"/>
                                <label class="star-5" for="star5">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="50"
                                        height="50"
                                        viewbox="0 0 50 50"
                                        fill="none"
                                    >
                                        <path
                                            d="M23.9592 4.92986C24.2878 3.91216 25.7115 3.91216 26.0401 4.92986L29.8333 16.6785C30.2016 17.8192 31.2601 18.5964 32.4598 18.5964H44.7348C45.7843 18.5964 46.2461 19.9639 45.3761 20.6L35.4454 27.8611C34.4781 28.5683 34.0759 29.8191 34.4439 30.9589L38.2371 42.7076C38.5721 43.745 37.3983 44.5642 36.5554 43.9479L26.6247 36.6868C25.6561 35.9786 24.3433 35.9786 23.3747 36.6868L13.4439 43.9479C12.601 44.5642 11.4273 43.745 11.7622 42.7075L15.5554 30.9589C15.9235 29.8191 15.5213 28.5683 14.554 27.8611L14.0621 28.5338L14.554 27.8611L4.62323 20.6C3.75325 19.9639 4.21505 18.5964 5.26451 18.5964H17.5396C18.7392 18.5964 19.7977 17.8192 20.166 16.6785L23.9592 4.92986Z"
                                            stroke="#ABABAB"
                                            stroke-width="1.66667"
                                        ></path>
                                    </svg>
                                </label>
                                <input id="star4" type="radio" name="star" value="4"/>
                                <label class="star-4" for="star4">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="50"
                                        height="50"
                                        viewbox="0 0 50 50"
                                        fill="none"
                                    >
                                        <path
                                            d="M23.9592 4.92986C24.2878 3.91216 25.7115 3.91216 26.0401 4.92986L29.8333 16.6785C30.2016 17.8192 31.2601 18.5964 32.4598 18.5964H44.7348C45.7843 18.5964 46.2461 19.9639 45.3761 20.6L35.4454 27.8611C34.4781 28.5683 34.0759 29.8191 34.4439 30.9589L38.2371 42.7076C38.5721 43.745 37.3983 44.5642 36.5554 43.9479L26.6247 36.6868C25.6561 35.9786 24.3433 35.9786 23.3747 36.6868L13.4439 43.9479C12.601 44.5642 11.4273 43.745 11.7622 42.7075L15.5554 30.9589C15.9235 29.8191 15.5213 28.5683 14.554 27.8611L14.0621 28.5338L14.554 27.8611L4.62323 20.6C3.75325 19.9639 4.21505 18.5964 5.26451 18.5964H17.5396C18.7392 18.5964 19.7977 17.8192 20.166 16.6785L23.9592 4.92986Z"
                                            stroke="#ABABAB"
                                            stroke-width="1.66667"
                                        ></path>
                                    </svg>
                                </label>
                                <input id="star3" type="radio" name="star" value="3"/>
                                <label class="star-3" for="star3">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="50"
                                        height="50"
                                        viewbox="0 0 50 50"
                                        fill="none"
                                    >
                                        <path
                                            d="M23.9592 4.92986C24.2878 3.91216 25.7115 3.91216 26.0401 4.92986L29.8333 16.6785C30.2016 17.8192 31.2601 18.5964 32.4598 18.5964H44.7348C45.7843 18.5964 46.2461 19.9639 45.3761 20.6L35.4454 27.8611C34.4781 28.5683 34.0759 29.8191 34.4439 30.9589L38.2371 42.7076C38.5721 43.745 37.3983 44.5642 36.5554 43.9479L26.6247 36.6868C25.6561 35.9786 24.3433 35.9786 23.3747 36.6868L13.4439 43.9479C12.601 44.5642 11.4273 43.745 11.7622 42.7075L15.5554 30.9589C15.9235 29.8191 15.5213 28.5683 14.554 27.8611L14.0621 28.5338L14.554 27.8611L4.62323 20.6C3.75325 19.9639 4.21505 18.5964 5.26451 18.5964H17.5396C18.7392 18.5964 19.7977 17.8192 20.166 16.6785L23.9592 4.92986Z"
                                            stroke="#ABABAB"
                                            stroke-width="1.66667"
                                        ></path>
                                    </svg>
                                </label>
                                <input id="star2" type="radio" name="star" value="2"/>
                                <label class="star-2" for="star2">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="50"
                                        height="50"
                                        viewbox="0 0 50 50"
                                        fill="none"
                                    >
                                        <path
                                            d="M23.9592 4.92986C24.2878 3.91216 25.7115 3.91216 26.0401 4.92986L29.8333 16.6785C30.2016 17.8192 31.2601 18.5964 32.4598 18.5964H44.7348C45.7843 18.5964 46.2461 19.9639 45.3761 20.6L35.4454 27.8611C34.4781 28.5683 34.0759 29.8191 34.4439 30.9589L38.2371 42.7076C38.5721 43.745 37.3983 44.5642 36.5554 43.9479L26.6247 36.6868C25.6561 35.9786 24.3433 35.9786 23.3747 36.6868L13.4439 43.9479C12.601 44.5642 11.4273 43.745 11.7622 42.7075L15.5554 30.9589C15.9235 29.8191 15.5213 28.5683 14.554 27.8611L14.0621 28.5338L14.554 27.8611L4.62323 20.6C3.75325 19.9639 4.21505 18.5964 5.26451 18.5964H17.5396C18.7392 18.5964 19.7977 17.8192 20.166 16.6785L23.9592 4.92986Z"
                                            stroke="#ABABAB"
                                            stroke-width="1.66667"
                                        ></path>
                                    </svg>
                                </label>
                                <input id="star1" type="radio" name="star" value="1"/>
                                <label class="star-1" for="star1">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="50"
                                        height="50"
                                        viewbox="0 0 50 50"
                                        fill="none"
                                    >
                                        <path
                                            d="M23.9592 4.92986C24.2878 3.91216 25.7115 3.91216 26.0401 4.92986L29.8333 16.6785C30.2016 17.8192 31.2601 18.5964 32.4598 18.5964H44.7348C45.7843 18.5964 46.2461 19.9639 45.3761 20.6L35.4454 27.8611C34.4781 28.5683 34.0759 29.8191 34.4439 30.9589L38.2371 42.7076C38.5721 43.745 37.3983 44.5642 36.5554 43.9479L26.6247 36.6868C25.6561 35.9786 24.3433 35.9786 23.3747 36.6868L13.4439 43.9479C12.601 44.5642 11.4273 43.745 11.7622 42.7075L15.5554 30.9589C15.9235 29.8191 15.5213 28.5683 14.554 27.8611L14.0621 28.5338L14.554 27.8611L4.62323 20.6C3.75325 19.9639 4.21505 18.5964 5.26451 18.5964H17.5396C18.7392 18.5964 19.7977 17.8192 20.166 16.6785L23.9592 4.92986Z"
                                            stroke="#ABABAB"
                                            stroke-width="1.66667"
                                        ></path>
                                    </svg>
                                </label>
                            </div>
                        </div>
                        <button class="form__btn" id="formBtn" type="submit" disabled>
                            <span>Оставить отзыв</span>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {useI18n} from "vue-i18n";
import VueScrollTo from "vue-scrollto";

export default {
    setup() {
        const {t} = useI18n({
            inheritLocale: true,
            useScope: "local",
        });

        return {t};
    },

    data() {
        return {
            animations: {},
            elements: {},
            isMobileScreen: true,

            formValues: {
                username: '',
                mail: '',
                company: '',
                message: '',
                typeform: 'feedback_homepage',
            },

        };
    },

    computed: {
        formErrors() {
            const resultErrors = {
                username: false,
                mail: false,
                company: false,
                message: false,
            }

            const {username, mail, company, message} = this.formValues;

            if (username.length < 3 || username.length >= 258) {
                resultErrors.username = true;
            } else if (company.length < 3 || company.length >= 258) {
                resultErrors.company = true;
            } else if (message.length < 3 || message.length >= 258) {
                resultErrors.message = true;
            } else if (mail.length < 3 || mail.length >= 258 || !mail.match(/^\S+@\S+\.\S+$/)) {
                resultErrors.mail = true;
            }

            return resultErrors;
        },

        hasErrors() {
            return Object.values(this.formErrors).some(value => value);
        }
    },

    methods: {
        redirect() {
            window.location = "https://airdrop.tokenza.io/#/";
        },

        handleScroll() {
            Object.values(this.elements).forEach((element) => {
                const t = window.innerHeight - window.innerHeight / 6;
                const rect = element?.getBoundingClientRect();

                if (!element?.classList.contains("visible") && t > rect?.top) {
                    element.classList.add("visible");
                }
            });
        },

        animateElements(elementKeys) {
            elementKeys.forEach((elementKey) => {
                if (this.animations[elementKey] === false)
                    this.animations[elementKey] = true;
            });
        },

        animateAfterAnimationEnd(eventElement, animateElements) {
            eventElement.addEventListener("animationend", () => {
                this.animateElements(animateElements); // array
            });
        },

        afterHeadingAnim() {
            Object.keys(this.elements).forEach((key, index) => {
                const element = this.$refs[`heading${index + 1}`];
                if (element === undefined) return;

                this.animateAfterAnimationEnd(element, [`anim${index + 1}`]);
            });
        },

        elementsInit() {
            for (let i = 1; i <= 11; i++) {
                this.elements[`heading${i}`] = this.$refs[`heading${i}`];
                this.animations[`anim${i}`] = false;
            }
        },

        updateScreenStyles() {
            this.$store.dispatch("updateMobileScreen", this.isMobileScreen);
            localStorage.setItem("mobileScreen", this.isMobileScreen);
        },

        checkLoginBuyRoute() {
            if (this.$route.query.login === null) {
                this.$emit("openPopup", {name: "PopupAuth", register: true});
            }
        },

        scrollBehavior(to) {
            VueScrollTo.scrollTo(to, 700);
        },

        stylesInit() {
            if (window.document.querySelector('html').classList.contains('dashboard')) {
                window.document.querySelector('html').classList.remove('dashboard')
                window.document.querySelector('html').style = '';
            }
        },

        sendFeedback() {
            this.$store.dispatch("sendFeedback", {data: this.formValues});
        },

        scrollTo(element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    },

    created() {
        this.checkLoginBuyRoute();
        this.stylesInit();

        window.addEventListener("scroll", this.handleScroll);
    },

    mounted() {
        this.elementsInit();
        this.afterHeadingAnim();
        this.$emit("langingPage", true);
        // this.updateScreenStyles();
    },

    unmounted() {
        window.removeEventListener("scroll", this.handleScroll);
    },
};
</script>

<style scoped lang="scss">
</style>
