<template>

    <div class="body-wrap dashboard-page">
        <section class="dashboard">
            <MainSidebar/>

            <div class="dashboard__white_container">
            </div>
        </section>
    </div>
</template>

<script>

import MainSidebar from "@/components/Sidebar/MainSidebar.vue";

export default {
    name: 'EmptyPage',
    components: {MainSidebar}
};
</script>
