<template>
  <Statistic />
</template>

<script>
import Statistic from '@/views/Statistic/Statistic'

export default {
  components: {
    Statistic,
  },
};
</script>

<style scoped lang="scss">
.dashboard-page {
  background: #89123e;
}
</style>
