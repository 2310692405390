<template>
    <loader
        object="#ffffff"
        size="2"
        speed="1"
        name="dots"
        class="loader"
    ></loader>
</template>

<script>
export default {
    name: 'ButtonSpinner',
}
</script>

<style scoped lang="scss">
.loader {
    position: relative;
    display: inline-block;
    min-height: unset;
    top: -0.5em;
    width: 2.7em;
    height: 1.45em;

    #overlay-dots, .loader-dots {
      position: static;
      transform: unset;
      height: auto;
    }
}
</style>