<template>
  <div class="body-wrap dashboard-page">
    <section class="dashboard">
      <MainSidebar />

      <div class="dashboard__white_container">
        <div class="dashboard__white_nav">
          <div class="dashboard__white_nav_left">
            <div class="dashboard__white_nav_heading"><span>Support</span></div>
          </div>
        </div>
        <div class="dashboard__support_tabs">
          <div
            class="dashboard__finance_tab_support_item tab-name"
            data-tab="#tab_1"
            :class="currentTab === 'tab_1' ? 'active' : ''"
            @click="setTab('tab_1')"
          >
            <span>New request</span>
          </div>
          <div
            class="dashboard__finance_tab_support_item tab-name"
            data-tab="#tab_2"
            :class="currentTab === 'tab_2' ? 'active' : ''"
            @click="setTab('tab_2')"
          >
            <span>Messages</span>
          </div>
          <div
            class="dashboard__finance_tab_support_item tab-name"
            data-tab="#tab_3"
            :class="currentTab === 'tab_3' ? 'active' : ''"
            @click="setTab('tab_3')"
          >
            <span>Request history</span>
          </div>
        </div>
        <div class="dashboard__support_tabs_content">
          <div
            class="dashboard__support_tabs_conten_item tab-content"
            id="tab_1"
            :class="currentTab === 'tab_1' ? 'active' : ''"
          >
            <form class="form" id="supportForm" action="">
              <div class="dashboard__support_request_block">
                <div class="dashboard__support_request_name">
                  <span>Subject</span>
                </div>
                <div class="dashboard__support_request_select">
                  <select id="areaPerSettings" name="subjects">
                    <option value="" disabled selected>
                      Select the subject
                    </option>
                    <option value="subject">subject</option>
                    <option value="subject">subject</option>
                    <option value="subject">subject</option>
                  </select>
                </div>
              </div>
              <div class="dashboard__support_request_block">
                <div class="dashboard__support_request_name">
                  <span>Theme</span>
                </div>
                <div class="dashboard__support_request_input">
                  <input
                    class="support-theme"
                    id="supportTheme"
                    type="text"
                    name="support-theme"
                    placeholder="Enter request theme"
                    value=""
                    required
                  />
                </div>
              </div>
              <div class="dashboard__support_request_block">
                <div class="dashboard__support_request_name">
                  <span>Describe your problem</span>
                </div>
                <div class="dashboard__support_request_input">
                  <textarea
                    class="support-question"
                    id="supportQuestion"
                    type="text"
                    name="support-question"
                    placeholder="Enter your question..."
                    value=""
                    required
                  ></textarea>
                </div>
              </div>
              <div class="dashboard__support_request_block">
                <div class="dashboard__support_request_name"></div>
                <div class="dashboard__support_request_input">
                  <button id="supportSend" type="submit">
                    <span>Send</span><img src="img/dashboard/send.svg" alt="" />
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div
            class="dashboard__support_tabs_conten_item tab-content"
            id="tab_2"
            :class="currentTab === 'tab_2' ? 'active' : ''"
          ></div>
          <div
            class="dashboard__support_tabs_conten_item tab-content"
            id="tab_3"
            :class="currentTab === 'tab_3' ? 'active' : ''"
          >
            <div class="dashboard__support_history">
              <div class="dashboard__support_history_item">
                <div class="dashboard__support_history_theme name">
                  <span>Theme</span>
                </div>
                <div class="dashboard__support_history_subject name">
                  <span>Subject</span>
                </div>
                <div class="dashboard__support_history_date name">
                  <span>DATE</span>
                </div>
                <div class="dashboard__support_history_status name">
                  <span>Status</span>
                </div>
              </div>
              <div class="dashboard__support_history_item">
                <div class="dashboard__support_history_theme">
                  <span>Ads are not displayed correctly</span>
                </div>
                <div class="dashboard__support_history_subject">
                  <span>Technical problem</span>
                </div>
                <div class="dashboard__support_history_date">
                  <span>Jan 4, 2023</span>
                </div>
                <div class="dashboard__support_history_status active">
                  <span>Active</span>
                </div>
              </div>
              <div class="dashboard__support_history_item">
                <div class="dashboard__support_history_theme">
                  <span>Problem with payment via bank card</span>
                </div>
                <div class="dashboard__support_history_subject">
                  <span>Financial</span>
                </div>
                <div class="dashboard__support_history_date">
                  <span>Jan 2, 2022</span>
                </div>
                <div class="dashboard__support_history_status active">
                  <span>Active</span>
                </div>
              </div>
              <div class="dashboard__support_history_item">
                <div class="dashboard__support_history_theme">
                  <span>Monthly report sheet not sent</span>
                </div>
                <div class="dashboard__support_history_subject">
                  <span>Documentary</span>
                </div>
                <div class="dashboard__support_history_date">
                  <span>Jan 2, 2022</span>
                </div>
                <div class="dashboard__support_history_status resolved">
                  <span>Resolved</span>
                </div>
              </div>
              <div class="dashboard__support_history_item">
                <div class="dashboard__support_history_theme">
                  <span>Ads are not displayed correctly</span>
                </div>
                <div class="dashboard__support_history_subject">
                  <span>Technical problem</span>
                </div>
                <div class="dashboard__support_history_date">
                  <span>Jan 6, 2022</span>
                </div>
                <div class="dashboard__support_history_status resolved">
                  <span>Resolved</span>
                </div>
              </div>
              <div class="dashboard__support_history_item">
                <div class="dashboard__support_history_theme">
                  <span>Monthly report sheet not sent</span>
                </div>
                <div class="dashboard__support_history_subject">
                  <span>Documentary</span>
                </div>
                <div class="dashboard__support_history_date">
                  <span>Jan 8, 2022</span>
                </div>
                <div class="dashboard__support_history_status resolved">
                  <span>Resolved</span>
                </div>
              </div>
              <div class="dashboard__support_history_item">
                <div class="dashboard__support_history_theme">
                  <span>Monthly report sheet not sent</span>
                </div>
                <div class="dashboard__support_history_subject">
                  <span>Documentary</span>
                </div>
                <div class="dashboard__support_history_date">
                  <span>Jan 6, 2022</span>
                </div>
                <div class="dashboard__support_history_status resolved">
                  <span>Resolved</span>
                </div>
              </div>
              <div class="dashboard__support_history_item">
                <div class="dashboard__support_history_theme">
                  <span>Problem with payment via bank card</span>
                </div>
                <div class="dashboard__support_history_subject">
                  <span>Financial</span>
                </div>
                <div class="dashboard__support_history_date">
                  <span>Jan 4, 2022</span>
                </div>
                <div class="dashboard__support_history_status resolved">
                  <span>Resolved</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import MainSidebar from "../../components/Sidebar/MainSidebar.vue";

export default {
  components: { MainSidebar },

  data() {
    return {
      currentTab: "tab_1",
    };
  },

  methods: {
    setTab(tabName) {
      this.currentTab = tabName;
    },
  },
};
</script>

<style scoped lang="scss">
.dashboard-page {
  background: #89123e;
}
</style>
