import { createStore } from 'vuex';
import auth from './modules/auth';
import feedback from './modules/feedback';
import user from './modules/user';
import geo from './modules/geo';
import campaigns from './modules/campaigns';
import banner from './modules/banner';
import statistics from './modules/statistics';
import balance from './modules/balance';
import finances from './modules/finances';
import billingdetails from './modules/billingdetails';
import traffic from './modules/traffic';
import listings from './modules/listings';
import sites from './modules/sites';

export default createStore({
  modules: {
    auth,
    feedback,
    user,
    geo,
    campaigns,
    banner,
    statistics,
    balance,
    finances,
    billingdetails,
    traffic,
    listings,
    sites,
  }
})
