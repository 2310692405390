<template>
    <div class="date-time-picker__header">
        <div class="desktop">
            <div>
                <button class="date-time-picker__button" @click="$emit('all')">
                    <span>All</span>
                </button>
                <button class="date-time-picker__button" @click="$emit('weekends')">
                    <span>Weekends</span>
                </button>
                <button class="date-time-picker__button" @click="$emit('work')">
                    <span>Work days</span>
                </button>
                <button class="date-time-picker__button" @click="$emit('business')">
                    <span>Business hours</span>
                </button>
            </div>
            <div>
                <button class="date-time-picker__button" @click="$emit('clear')">
                    <span>Clear all</span>
                </button>
            </div>
        </div>
        <div class="mobile">
            <button class="date-time-picker__button" @click="$emit('all')">
                <span>All</span>
            </button>
            <button class="date-time-picker__button" @click="$emit('weekends')">
                <span>Weekends</span>
            </button>
            <button class="date-time-picker__button" @click="$emit('work')">
                <span>Work days</span>
            </button>
            <button class="date-time-picker__button" @click="$emit('business')">
                <span>Business hours</span>
            </button>
            <button class="date-time-picker__button" @click="$emit('clear')">
                <span>Clear all</span>
            </button>
        </div>
    </div>
</template>

<script>

export default {
    name: 'DTPHeader',
}
</script>

<style lang="scss" scoped>
.date-time-picker {
    &__header > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: .8em 1.2em;
        border-bottom: .1em solid #EAECF0;

        div:first-child button {
            margin-right: .8em;
        }
    }

    .mobile {
        display: none;
    }

    &__button {
        padding: .6em .8em;
        border-radius: .4em;
        border: .1em solid #EAECF0;
        background: #fff;
        transition: background .2s ease-in-out;

        &:hover {
            background: #EAECF0;
        }

        span {
            font-size: 1.1em;
            color: #344054;
            font-weight: 600;
        }
    }

    @media(max-width: 1024px) and (orientation: portrait) {
        &__header > div {
            flex-wrap: wrap;
            justify-content: flex-start;
            padding-bottom: 0;
        }

        .desktop {
            display: none;
        }

        .mobile {
            display: flex;
        }

        &__button {
            margin-bottom: .6em;
            margin-right: .6em;
        }
    }
}
</style>
