<template>
    <div class="body-wrap dashboard-page">
        <section class="dashboard">
            <MainSidebar/>

            <div class="dashboard__white_container">
                <div class="dashboard__white_nav">
                    <div class="dashboard__white_nav_left">
                        <div class="dashboard__white_nav_heading">
                            <span>Profile settings</span>
                        </div>
                    </div>
                </div>

                <DataLoader v-if="userDataLoading" />

                <template v-else>
                    <div class="dashboard__finance_tabs_name">
                        <div
                            class="dashboard__finance_tab_item tab-name"
                            data-tab="#tab_1"
                            :class="currentTab === 'tab_1' ? 'active' : ''"
                            @click="setTab('tab_1')"
                        >
                            <span>Account</span>
                        </div>
                        <div
                            class="dashboard__finance_tab_item tab-name"
                            data-tab="#tab_2"
                            :class="currentTab === 'tab_2' ? 'active' : ''"
                            @click="setTab('tab_2')"
                        >
                            <span>Security</span>
                        </div>
                        <div
                            class="dashboard__finance_tab_item tab-name"
                            data-tab="#tab_3"
                            :class="currentTab === 'tab_3' ? 'active' : ''"
                            @click="setTab('tab_3')"
                        >
                            <span>Communication</span>
                        </div>
                    </div>
                    <div class="dashboard__finance_tabs_content">
                        <div
                            class="dashboard__finance_tabs_conten_item tab-content"
                            id="tab_1"
                            :class="currentTab === 'tab_1' ? 'active' : ''"
                        >
                            <div class="dashboard__billing_top">
                                <div class="dashboard__billing_text_block">
                                    <div class="dashboard__billing_text_1">
                                        <span>Personal info</span>
                                    </div>
                                    <div class="dashboard__billing_text_2">
                                        <span>Update personal details here.</span>
                                    </div>
                                </div>
                                <div class="dashboard__billing_btns">
                                    <button class="dashboard__billing_btn" @click="$router.go()">
                                        <span>Cancel</span>
                                    </button>
                                    <button class="dashboard__billing_btn blue" @click.prevent="sendFormData">
                                        <ButtonSpinner v-if="requestLoading"/>
                                        <span v-else>Save</span>
                                    </button>
                                </div>
                            </div>
                            <div class="dashboard__create_campaign">
                                <div class="dashboard__create_item">
                                    <div class="dashboard__create_item_name">
                                        <span>Login</span>
                                    </div>
                                    <div class="dashboard__create_item_single_input">
                                        <input
                                            class="prof-settings-login"
                                            id="profSettingsLogin"
                                            type="text"
                                            name="prof-settings-login"
                                            placeholder="Enter your Login"
                                            :value="user.login"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div class="dashboard__create_item">
                                    <div class="dashboard__create_item_name"><span>Name</span></div>
                                    <div class="dashboard__profile_settings_items">
                                        <div class="dashboard__profile_settings_item">
                                            <input
                                                class="prof-settings-firstname"
                                                id="profSettingsFirstName"
                                                type="text"
                                                name="prof-settings-firstname"
                                                placeholder="Enter your First Name"
                                                v-model="formData.firstName"
                                            />
                                        </div>
                                        <div class="dashboard__profile_settings_item">
                                            <input
                                                class="prof-settings-lastname"
                                                id="profSettingsLastName"
                                                type="text"
                                                name="prof-settings-lastname"
                                                placeholder="Enter your Last Name"
                                                v-model="formData.lastName"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="dashboard__create_item">
                                    <div class="dashboard__create_item_name">
                                        <span>Email address</span>
                                    </div>
                                    <div class="dashboard__profile_settings_email">
                                        <div class="dashboard__profile_settings_help">
                                            <img src="img/dashboard/help-circle.svg" alt=""/>
                                            <div class="dashboard__profile_settings_help_text">
                                                <span>To change your email, please contact support</span>
                                            </div>
                                        </div>
                                        <input
                                            class="prof-settings-email"
                                            id="profSettingsEmail"
                                            type="email"
                                            name="prof-settings-email"
                                            placeholder="yourmail@email.com"
                                            :value="user.email"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div class="dashboard__create_item">
                                    <div class="dashboard__create_item_name">
                                        <span>Country</span>
                                    </div>
                                    <div class="dashboard__create_item_single_select">
                                        <select id="countryPerSettings" name="countryPerSettings" @change='getRegionsList'
                                                v-model="formData.country">
                                            <option v-for='country in countriesKeys' :key="country" :value="country">
                                                {{ countriesData[country] }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="dashboard__create_item">
                                    <div class="dashboard__create_item_name"><span>Area</span></div>
                                    <div class="dashboard__create_item_single_select">
                                        <select id="areaPerSettings" name="areaPerSettings" v-model="formData.area">
                                            <option v-for='region in regionsList' :key="region" :value="region?.locid">
                                                {{ region?.locname }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="dashboard__create_item">
                                    <div class="dashboard__create_item_name">
                                        <span>City/town</span>
                                    </div>
                                    <div class="dashboard__create_item_single_input">
                                        <input
                                            class="prof-settings-city"
                                            id="profSettingsCity"
                                            type="text"
                                            name="city"
                                            placeholder="Your city"
                                            v-model="formData.city"
                                        />
                                    </div>
                                </div>
                                <div class="dashboard__create_item" v-if="isAdvertisor">
                                    <div class="dashboard__create_item_name">
                                        <span>Postback link</span>
                                    </div>
                                    <div class="dashboard__profile_settings_link" @click.prevent="copyLink">
                                        <input
                                            class="prof-settings-link"
                                            id="profSettingsLink"
                                            type="text"
                                            name="postback"
                                            placeholder="Enter your link"
                                            :value="user.postback ?? 'https://dogstraff.com'"
                                            disabled
                                            ref="refPostback"
                                        />

                                        <CopiedModal
                                            :active="copiedLink"
                                            message="Link copied"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="dashboard__create_item_btns">
                                <button class="dashboard__billing_btn" @click="$router.go()">
                                    <span>Cancel</span>
                                </button>
                                <button class="dashboard__billing_btn blue" @click.prevent="sendFormData">
                                    <ButtonSpinner v-if="requestLoading"/>
                                    <span v-else>Save</span>
                                </button>
                            </div>
                        </div>
                        <div
                            class="dashboard__finance_tabs_conten_item tab-content"
                            id="tab_2"
                            :class="currentTab === 'tab_2' ? 'active' : ''"
                        >
                            <div class="dashboard__billing_top">
                                <div class="dashboard__billing_text_block">
                                    <div class="dashboard__billing_text_1">
                                        <span>Security info</span>
                                    </div>
                                    <div class="dashboard__billing_text_2">
                                        <span>You can change your password here</span>
                                    </div>
                                </div>
                                <div class="dashboard__billing_btns">
                                    <button class="dashboard__billing_btn" @click="$router.go()">
                                        <span>Cancel</span>
                                    </button>
                                    <button class="dashboard__billing_btn blue" @click.prevent="sendFormData">
                                        <ButtonSpinner v-if="requestLoading"/>
                                        <span v-else>Save</span>
                                    </button>
                                </div>
                            </div>
                            <div class="dashboard__create_campaign">
                                <div class="dashboard__create_item">
                                    <div class="dashboard__create_item_name">
                                        <span>Old Password</span>
                                    </div>
                                    <div class="dashboard__profile_settings_password password-wrap">
                                        <div class="password-eye" data-status="Показать пароль"
                                             @click="passwordVisible.oldPasswordVisible = !passwordVisible.oldPasswordVisible"
                                        >
                                            <img
                                                class="password-img password_hidden"
                                                :class="passwordVisible.oldPasswordVisible && 'active'"
                                                id="password"
                                                src="img/eye.svg"
                                                alt=""
                                            /><img
                                            class="password-img password_visible"
                                            :class="!passwordVisible.oldPasswordVisible && 'active'"
                                            id="password1"
                                            src="img/eye_open.svg"
                                            alt=""
                                        />
                                        </div>
                                        <input
                                            class="form__input input-password"
                                            :type="passwordVisible.oldPasswordVisible ? 'password' : 'text'"
                                            name="password"
                                            placeholder="Enter your password"
                                            data-valid="false"
                                            data-name="password"
                                            v-model="formData.oldPassword"
                                        />
                                    </div>
                                </div>
                                <div class="dashboard__create_item">
                                    <div class="dashboard__create_item_name">
                                        <span>New password</span>
                                    </div>
                                    <div class="dashboard__profile_settings_password password-wrap">
                                        <div class="password-eye" data-status="Показать пароль"
                                             @click="passwordVisible.newPasswordVisible = !passwordVisible.newPasswordVisible"
                                        >
                                            <img
                                                class="password-img password_hidden"
                                                :class="passwordVisible.newPasswordVisible && 'active'"
                                                id="newPassword"
                                                src="img/eye.svg"
                                                alt=""
                                            /><img
                                            class="password-img password_visible"
                                            :class="!passwordVisible.newPasswordVisible && 'active'"
                                            id="newPassword1"
                                            src="img/eye_open.svg"
                                            alt=""
                                        />
                                        </div>
                                        <input
                                            class="form__input input-password"
                                            :type="passwordVisible.newPasswordVisible ? 'password' : 'text'"
                                            name="newpassword"
                                            placeholder="Enter your new password"
                                            data-valid="false"
                                            data-name="password"
                                            v-model="formData.newPassword"
                                        />
                                        <div class="dashboard__profile_settings_password_text">
                                            <ul>
                                                <li :class="passwordValide.length && 'active'">must be at least 8 characters
                                                    length
                                                </li>
                                                <li :class="passwordValide.lowercase && 'active'">contains lowercase latin
                                                    letters
                                                </li>
                                                <li :class="passwordValide.uppercase && 'active'">contains uppercase latin
                                                    letters
                                                </li>
                                                <li :class="passwordValide.digits && 'active'">digits</li>
                                                <li :class="passwordValide.confirmation && 'active'">password and password
                                                    confirmation must be equal
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="dashboard__create_item">
                                    <div class="dashboard__create_item_name">
                                        <span>Confirm password</span>
                                    </div>
                                    <div class="dashboard__profile_settings_password password-wrap">
                                        <div class="password-eye" data-status="Показать пароль"
                                             @click="passwordVisible.newPasswordConfirmVisible = !passwordVisible.newPasswordConfirmVisible"
                                        >
                                            <img
                                                class="password-img password_hidden"
                                                :class="passwordVisible.newPasswordConfirmVisible && 'active'"
                                                id="confirmPassword"
                                                src="img/eye.svg"
                                                alt=""
                                            /><img
                                            class="password-img password_visible"
                                            :class="!passwordVisible.newPasswordConfirmVisible && 'active'"
                                            id="confirmPassword1"
                                            src="img/eye_open.svg"
                                            alt=""
                                        />
                                        </div>
                                        <input
                                            class="form__input input-password"
                                            :type="passwordVisible.newPasswordConfirmVisible ? 'password' : 'text'"
                                            name="password"
                                            placeholder="Confirm your password"
                                            data-valid="false"
                                            data-name="password"
                                            v-model="formData.newPasswordConfirm"
                                            :disabled="!passwordValid"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="dashboard__create_item_btns">
                                <button class="dashboard__billing_btn" @click="$router.go()">
                                    <span>Cancel</span>
                                </button>
                                <button class="dashboard__billing_btn blue" @click.prevent="sendFormData">
                                    <ButtonSpinner v-if="requestLoading"/>
                                    <span v-else>Save</span>
                                </button>
                            </div>
                        </div>
                        <div
                            class="dashboard__finance_tabs_conten_item tab-content"
                            id="tab_3"
                            :class="currentTab === 'tab_3' ? 'active' : ''"
                        >
                            <div class="dashboard__billing_top">
                                <div class="dashboard__billing_text_block">
                                    <div class="dashboard__billing_text_1">
                                        <span>Communication methods</span>
                                    </div>
                                    <div class="dashboard__billing_text_2">
                                        <span>You can choose convenient communication methods</span>
                                    </div>
                                </div>
                                <div class="dashboard__billing_btns">
                                    <button class="dashboard__billing_btn" @click="$router.go()">
                                        <span>Cancel</span>
                                    </button>
                                    <button class="dashboard__billing_btn blue" @click.prevent="sendFormData">
                                        <ButtonSpinner v-if="requestLoading"/>
                                        <span v-else>Save</span>
                                    </button>
                                </div>
                            </div>
                            <div class="dashboard__create_campaign">
                                <div class="dashboard__create_item">
                                    <div class="dashboard__create_item_name">
                                        <span>Skype</span>
                                    </div>
                                    <div class="dashboard__profile_settings_input_block">
                                        <!--                  <div class="dashboard__profile_settings_switcher">-->
                                        <!--                    <label class="switch">-->
                                        <!--                      <input type="checkbox" /><span-->
                                        <!--                        class="slider round"-->
                                        <!--                      ></span>-->
                                        <!--                    </label>-->
                                        <!--                  </div>-->
                                        <div class="dashboard__profile_settings_input">
                                            <input
                                                class="prof-settings-skype"
                                                id="profSettingsSkype"
                                                type="text"
                                                name="prof-settings-skype"
                                                placeholder="Enter your Skype name"
                                                v-model="formData.messSkype"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="dashboard__create_item">
                                    <div class="dashboard__create_item_name">
                                        <span>Telegram</span>
                                    </div>
                                    <div class="dashboard__profile_settings_input_block">
                                        <!--                  <div class="dashboard__profile_settings_switcher">-->
                                        <!--                    <label class="switch">-->
                                        <!--                      <input type="checkbox" checked /><span-->
                                        <!--                        class="slider round"-->
                                        <!--                      ></span>-->
                                        <!--                    </label>-->
                                        <!--                  </div>-->
                                        <div class="dashboard__profile_settings_input">
                                            <input
                                                class="prof-settings-telegram"
                                                id="profSettingsTelegram"
                                                type="text"
                                                name="prof-settings-telegram"
                                                placeholder="Enter your Telegramm name"
                                                v-model="formData.messTelegram"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="dashboard__create_item">
                                    <div class="dashboard__create_item_name">
                                        <span>WeChat</span>
                                    </div>
                                    <div class="dashboard__profile_settings_input_block">
                                        <!--                  <div class="dashboard__profile_settings_switcher">-->
                                        <!--                    <label class="switch">-->
                                        <!--                      <input type="checkbox" /><span-->
                                        <!--                        class="slider round"-->
                                        <!--                      ></span>-->
                                        <!--                    </label>-->
                                        <!--                  </div>-->
                                        <div class="dashboard__profile_settings_input">
                                            <input
                                                class="prof-settings-wechat"
                                                id="profSettingsWechat"
                                                type="text"
                                                name="prof-settings-wechat"
                                                placeholder="Enter your WeChat name"
                                                v-model="formData.messWeChat"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="dashboard__create_item">
                                    <div class="dashboard__create_item_name">
                                        <span>Whatsapp</span>
                                    </div>
                                    <div class="dashboard__profile_settings_input_block">
                                        <!--                  <div class="dashboard__profile_settings_switcher">-->
                                        <!--                    <label class="switch">-->
                                        <!--                      <input type="checkbox" /><span-->
                                        <!--                        class="slider round"-->
                                        <!--                      ></span>-->
                                        <!--                    </label>-->
                                        <!--                  </div>-->
                                        <div class="dashboard__profile_settings_input">
                                            <input
                                                class="prof-settings-whatsapp"
                                                id="profSettingsWhatsapp"
                                                type="text"
                                                name="prof-settings-whatsapp"
                                                placeholder="Enter your Whatsapp name"
                                                v-model="formData.messWhatsapp"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="dashboard__create_item">
                                    <div class="dashboard__create_item_name">
                                        <span>Facebook</span>
                                    </div>
                                    <div class="dashboard__profile_settings_input_block">
                                        <!--                  <div class="dashboard__profile_settings_switcher">-->
                                        <!--                    <label class="switch">-->
                                        <!--                      <input type="checkbox" /><span-->
                                        <!--                        class="slider round"-->
                                        <!--                      ></span>-->
                                        <!--                    </label>-->
                                        <!--                  </div>-->
                                        <div class="dashboard__profile_settings_input">
                                            <input
                                                class="prof-settings-facebook"
                                                id="profSettingsFacebook"
                                                type="text"
                                                name="prof-settings-facebook"
                                                placeholder="Enter your Facebook name"
                                                v-model="formData.messFacebook"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="dashboard__create_item">
                                    <div class="dashboard__create_item_name"><span>Icq</span></div>
                                    <div class="dashboard__profile_settings_input_block">
                                        <!--                  <div class="dashboard__profile_settings_switcher">-->
                                        <!--                    <label class="switch">-->
                                        <!--                      <input type="checkbox" /><span-->
                                        <!--                        class="slider round"-->
                                        <!--                      ></span>-->
                                        <!--                    </label>-->
                                        <!--                  </div>-->
                                        <div class="dashboard__profile_settings_input">
                                            <input
                                                class="prof-settings-icq"
                                                id="profSettingsIcq"
                                                type="text"
                                                name="prof-settings-icq"
                                                placeholder="Enter your Icq number"
                                                v-model="formData.messIcq"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="dashboard__create_item">
                                    <div class="dashboard__create_item_name"><span>Line</span></div>
                                    <div class="dashboard__profile_settings_input_block">
                                        <!--                  <div class="dashboard__profile_settings_switcher">-->
                                        <!--                    <label class="switch">-->
                                        <!--                      <input type="checkbox" /><span-->
                                        <!--                        class="slider round"-->
                                        <!--                      ></span>-->
                                        <!--                    </label>-->
                                        <!--                  </div>-->
                                        <div class="dashboard__profile_settings_input">
                                            <input
                                                class="prof-settings-line"
                                                id="profSettingsLine"
                                                type="text"
                                                name="prof-settings-line"
                                                placeholder="Enter your Line name"
                                                v-model="formData.messLine"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="dashboard__create_item">
                                    <div class="dashboard__create_item_name"><span>QQ</span></div>
                                    <div class="dashboard__profile_settings_input_block">
                                        <!--                  <div class="dashboard__profile_settings_switcher">-->
                                        <!--                    <label class="switch">-->
                                        <!--                      <input type="checkbox" /><span-->
                                        <!--                        class="slider round"-->
                                        <!--                      ></span>-->
                                        <!--                    </label>-->
                                        <!--                  </div>-->
                                        <div class="dashboard__profile_settings_input">
                                            <input
                                                class="prof-settings-qq"
                                                id="profSettingsQQ"
                                                type="text"
                                                name="prof-settings-qq"
                                                placeholder="Enter your QQ name"
                                                v-model="formData.messQQ"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="dashboard__create_item">
                                    <div class="dashboard__create_item_name">
                                        <span>Email</span>
                                    </div>
                                    <div class="dashboard__profile_settings_input_block">
                                        <!--                  <div class="dashboard__profile_settings_switcher">-->
                                        <!--                    <label class="switch">-->
                                        <!--                      <input type="checkbox" /><span-->
                                        <!--                        class="slider round"-->
                                        <!--                      ></span>-->
                                        <!--                    </label>-->
                                        <!--                  </div>-->
                                        <div class="dashboard__profile_settings_input">
                                            <input
                                                class="prof-settings-email"
                                                id="profSettingsEmail"
                                                type="text"
                                                name="prof-settings-email"
                                                placeholder="Enter your Email"
                                                v-model="formData.messEmail"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="dashboard__create_item_btns">
                                <button class="dashboard__billing_btn" @click="$router.go()">
                                    <span>Cancel</span>
                                </button>
                                <button class="dashboard__billing_btn blue" @click.prevent="sendFormData">
                                    <ButtonSpinner v-if="requestLoading"/>
                                    <span v-else>Save</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </section>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import MainSidebar from "../../components/Sidebar/MainSidebar.vue";
import {COUNTRIES} from '@/Constants'
import ButtonSpinner from "@/components/UI/ButtonSpinner.vue";
import CopiedModal from "@/components/UI/CopiedModal.vue";
import DataLoader from "@/components/UI/DataLoader.vue";

export default {
    components: {
        DataLoader,
        ButtonSpinner,
        MainSidebar,
        CopiedModal
    },

    data() {
        return {
            currentTab: "tab_1",

            userDataLoading: true,
            requestLoading: false,

            formData: {
                // login: '',
                // username: '',
                firstName: '',
                lastName: '',
                // email: '',
                country: 'AD',
                area: 'AD~3041203~Canillo',
                city: '',
                oldPassword: '',
                newPassword: '',
                newPasswordConfirm: '',

                messSkype: '',
                messTelegram: '@Dogymirtraffovich',
                messWeChat: '',
                messWhatsapp: '',
                messFacebook: '',
                messIcq: '',
                messLine: '',
                messQQ: '',
                messEmail: '',
                // postback: '',
            },

            passwordVisible: {
                oldPasswordVisible: true,
                newPasswordVisible: true,
                newPasswordConfirmVisible: true,
            },

            copiedLink: false,
        };
    },

    computed: {
        ...mapState({
            regionsList: state => state.geo.regionsList,
            user: state => state.user.user
        }),

        countriesData() {
            return COUNTRIES;
        },

        countriesKeys() {
            return Object.keys(COUNTRIES)
        },

        newPasswordValide() {
            return {
                length: this.formData.newPassword?.length >= 8,
                lowercase: this.formData.newPassword?.length >= 1 && /[a-zа-я]/.test(this.formData.newPassword),
                uppercase: this.formData.newPassword?.length >= 1 && /[A-ZА-Я]/.test(this.formData.newPassword),
                digits: this.formData.newPassword?.length >= 1 && /\d/.test(this.formData.newPassword),
            }
        },

        passwordValide() {
            return {
                length: this.formData.newPassword?.length >= 8,
                lowercase: this.formData.newPassword?.length >= 1 && /[a-zа-я]/.test(this.formData.newPassword),
                uppercase: this.formData.newPassword?.length >= 1 && /[A-ZА-Я]/.test(this.formData.newPassword),
                digits: this.formData.newPassword?.length >= 1 && /\d/.test(this.formData.newPassword),
                confirmation: this.formData.newPassword?.length >= 1 && this.formData.newPassword === this.formData.newPasswordConfirm,
            }
        },

        passwordValid() {
            return Object.values(this.newPasswordValide).every(valid => valid);
        },

        isAdvertisor() {
            return Object.keys(this.user).length !== 0 && this.user.usertype === 'advertiser'
        }
    },

    methods: {
        setTab(tabName) {
            this.currentTab = tabName;
        },

        getRegions(country) {
            this.$store.dispatch('getRegionsList', {country: country}).then((r) => {
                this.formData.area = r[0].locid
                console.log(this.formData.area)
            })
        },

        getRegionsList(e) {
            this.formData.country = e.target.value;
            this.getRegions(e.target.value)
        },

        setFormDataParams(formData) {
            this.formData = formData;
        },

        copyLink() {
            let copyText = this.$refs.refPostback;

            // Select the text field
            copyText.select();
            copyText.setSelectionRange(0, 99999); // For mobile devices

            // Copy the text inside the text field
            navigator.clipboard.writeText(copyText.value);

            this.copiedLink = true;

            setTimeout(() => {
                this.copiedLink = false;
            }, 1000)
        },

        getUserData() {
            return new Promise((resolve, reject) => {
                this.$store.dispatch('getUser').then((response) => {
                    this.setFormDataParams({
                        firstName: response?.firstName ?? '',
                        lastName: response?.lastName ?? '',
                        country: response?.country ?? this.countriesKeys[0],
                        area: response?.area ?? this.regionsList[0]?.locid,
                        city: response?.city ?? '',
                        messSkype: response?.messSkype ?? '',
                        messTelegram: response?.messTelegram ?? '',
                        messWeChat: response?.messWeChat ?? '',
                        messWhatsapp: response?.messWhatsapp ?? '',
                        messFacebook: response?.messFacebook ?? '',
                        messIcq: response?.messIcq ?? '',
                        messLine: response?.messLine ?? '',
                        messQQ: response?.messQQ ?? '',
                        messEmail: response?.messEmail ?? '',
                    })
                    this.$store.dispatch('getRegionsList', {country: response?.country ?? 'AD'}).then((r) => {
                        this.formData.area = response.area ?? r[0].locid;

                        resolve()
                    }).catch((err) => {
                        reject(err)
                    })
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        sendFormData() {
            this.requestLoading = true;

            this.$store.dispatch('updateUser', {
                data: this.formData
            }).then(() => {
                setTimeout(() => {
                    this.requestLoading = false;
                }, 1000)
            })
        }
    },

    created() {
        this.getUserData().then(() => {
            setTimeout(() => {
                this.userDataLoading = false;
            }, 1000)
        })
    },
};
</script>

<style scoped lang="scss">
.dashboard-page {
    background: #89123e;
}

.dashboard__profile_settings_password_text {
    li.active {
        color: #28c700;
    }
}

.dashboard__billing_btn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.dashboard__profile_settings_link {
    cursor: pointer;

    &::after {
        will-change: transform;
        transform: scale(.9);
        transition: opacity .2s ease-in-out, transform .2s ease-in-out;
    }

    &:hover::after {
        opacity: .8;
        transform: scale(1);
    }

    input {
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
    }
}

.copiedModal {
    background: #101828;
    padding: .73em 1em;
    border-radius: .8em;
    position: absolute;
    right: 0;
    bottom: -2em;
    z-index: 888;
    pointer-events: none;
    transition: opacity .2s ease-in-out;
    opacity: 0;

    &.active {
        opacity: 1;
    }

    span {
        color: #fff;
    }
}
</style>
