export const CAMPAIGN = {
    "formats": {
        "inpagepush": "In page push"
    },
    "categories": {
        "mainstream": "Mainstream",
        "adult": "Adult"
    },
    "targetConnections": [
        {
            "all": "All",
            "dsl": "WIFI (Cable)",
            "cellular": "3g / 4g (mobile)"
        }
    ],
    "targetDevices": [
        {
            "desktop": "Desktop",
            "mobile": "Mobile"
        }
    ],
    "targetPlatforms": [
        {
            "desktop": {
                "Windows": [
                    "Windows",
                    "Windows CE"
                ],
                "MacOS": [
                    "Apple"
                ],
                "Linux": [
                    "Linux"
                ],
                "ChromeOS": [
                    "Chrome OS"
                ],
                "Other(desktop)": {
                    "1": "BeOS",
                    "10": "Chrome OS",
                    "2": "FreeBSD",
                    "12": "Iframely",
                    "4": "NetBSD",
                    "0": "OS / 2",
                    "3": "OpenBSD",
                    "6": "OpenSolaris",
                    "11": "Postman",
                    "8": "Roku",
                    "7": "Sony PlayStation",
                    "5": "SunOS",
                    "9": "Terminal"
                }
            },
            "mobile": {
                "Android": [
                    "Android",
                    "Java / Android"
                ],
                "iOS": [
                    "iPhone",
                    "iPod",
                    "iPad",
                    "Apple TV"
                ],
                "Other(mobile)": [
                    "BlackBerry",
                    "Fire OS",
                    "Nokia",
                    "SMART-TV"
                ]
            }
        }
    ],
    "targetBrowsers": [
        {
            "SamsungBrowser": [
                "SamsungBrowser"
            ],
            "Yandex": [
                "Yandex"
            ],
            "Chrome": [
                "Chrome",
                "Android"
            ],
            "Firefox": [
                "Firefox",
                "Firefox-99"
            ],
            "Opera": [
                "Opera",
                "Opera Mini"
            ],
            "Safari": [
                "Safari",
                "iPhone",
                "iPod",
                "iPad"
            ],
            "Other": [
                "WebTV",
                "Edge",
                "Internet Explorer",
                "Pocket Internet Explorer",
                "Konqueror",
                "iCab",
                "Firebird",
                "OmniWeb",
                "Brave",
                "Palemoon",
                "Iceweasel",
                "Shiretoko",
                "Mozilla",
                "Amaya",
                "Lynx",
                "GoogleBot",
                "cURL",
                "Wget",
                "UCBrowser",
                "YandexBot",
                "YandexImageResizer",
                "YandexImages",
                "YandexVideo",
                "YandexMedia",
                "YandexBlogs",
                "YandexFavicons",
                "YandexWebmaster",
                "YandexDirect",
                "YandexMetrika",
                "YandexNews",
                "YandexCatalog",
                "Yahoo! Slurp",
                "W3C Validator",
                "BlackBerry",
                "IceCat",
                "Nokia S60 OSS Browser",
                "Nokia Browser",
                "MSN Browser",
                "MSN Bot",
                "Bing Bot",
                "Vivaldi",
                "Netscape Navigator",
                "Galeon",
                "NetPositive",
                "Phoenix",
                ""
            ]
        }
    ],
    "targetLanguages": [
        {
            "af": "Afrikaans",
            "am": "Amharic",
            "an": "Aragonese",
            "ar": "Arabic",
            "as": "Assamese",
            "av": "Avaric",
            "az": "Azerbaijani",
            "ba": "Bashkir",
            "be": "Belarusian",
            "bg": "Bulgarian",
            "bh": "Bihari",
            "bn": "Bengali",
            "br": "Breton",
            "bs": "Bosnian",
            "ca": "Catalan",
            "ce": "Chechen",
            "co": "Corsican",
            "cs": "Czech",
            "cy": "Welsh",
            "da": "Danish",
            "de": "German",
            "el": "Greek",
            "en": "English",
            "eo": "Esperanto",
            "es": "Spanish",
            "et": "Estonian",
            "eu": "Basque",
            "fa": "Persian",
            "fi": "Finnish",
            "fo": "Faroese",
            "fr": "French",
            "fy": "Western Frisian",
            "ga": "Irish",
            "gd": "Scottish Gaelic",
            "gl": "Galician",
            "gu": "Gujarati",
            "ha": "Hausa",
            "he": "Hebrew",
            "hi": "Hindi",
            "hr": "Croatian",
            "ht": "Haitian",
            "hu": "Hungarian",
            "hy": "Armenian",
            "id": "Indonesian",
            "ig": "Igbo",
            "is": "Icelandic",
            "it": "Italian",
            "ja": "Japanese",
            "jv": "Javanese",
            "ka": "Georgian",
            "kk": "Kazakh",
            "km": "Khmer",
            "kn": "Kannada",
            "ko": "Korean",
            "ku": "Kurdish",
            "ky": "Kyrgyz",
            "la": "Latin",
            "lb": "Luxembourgish",
            "lo": "Lao",
            "lt": "Lithuanian",
            "lv": "Latvian",
            "mg": "Malagasy",
            "mk": "Macedonian",
            "ml": "Malayalam",
            "mn": "Mongolian",
            "mr": "Marathi",
            "ms": "Malay",
            "mt": "Maltese",
            "my": "Burmese",
            "nb": "Norwegian Bokmal",
            "ne": "Nepali",
            "nl": "Dutch",
            "nn": "Norwegian Nynorsk",
            "no": "Norwegian",
            "ny": "Chichewa",
            "or": "Oriya",
            "os": "Ossetian",
            "pa": "Panjabi",
            "pl": "Polish",
            "ps": "Pashto",
            "pt": "Portuguese",
            "rm": "Romansh",
            "ro": "Romanian",
            "ru": "Russian",
            "sg": "Sango",
            "si": "Sinhala",
            "sk": "Slovak",
            "sl": "Slovenian",
            "sn": "Shona",
            "so": "Somali",
            "sq": "Albanian",
            "sr": "Serbian",
            "st": "Southern Sotho",
            "su": "Sundanese",
            "sv": "Swedish",
            "sw": "Swahili",
            "ta": "Tamil",
            "te": "Telugu",
            "tg": "Tajik",
            "th": "Thai",
            "tk": "Turkmen",
            "tr": "Turkish",
            "tt": "Tatar",
            "ug": "Uyghur",
            "uk": "Ukrainian",
            "ur": "Urdu",
            "uz": "Uzbek",
            "vi": "Vietnamese",
            "xh": "Xhosa",
            "yi": "Yiddish",
            "yo": "Yoruba",
            "zh": "Chinese",
            "zu": "Zulu"
        }
    ],
    "target_countries": {
        "AD": "Andorra (AD)",
        "AE": "UAE (AE)",
        "AF": "Afghanistan (AF)",
        "AG": "Antigua and Barbuda (AG)",
        "AI": "Anguilla (AI)",
        "AL": "Albania (AL)",
        "AM": "Armenia (AM)",
        "AO": "Angola (AO)",
        "AQ": "Antarctica (AQ)",
        "AR": "Argentina (AR)",
        "AS": "American Samoa (AS)",
        "AT": "Austria (AT)",
        "AU": "Australia (AU)",
        "AW": "Aruba (AW)",
        "AX": "Aland Islands (AX)",
        "AZ": "Azerbaijan (AZ)",
        "BA": "Bosnia and Herzegovina (BA)",
        "BB": "Barbados (BB)",
        "BD": "Bangladesh (BD)",
        "BE": "Belgium (BE)",
        "BF": "Burkina Faso (BF)",
        "BG": "Bulgaria (BG)",
        "BH": "Bahrain (BH)",
        "BI": "Burundi (BI)",
        "BJ": "Benin (BJ)",
        "BL": "Saint Barthelemy (BL)",
        "BM": "Bermuda (BM)",
        "BN": "Brunei (BN)",
        "BO": "Bolivia (BO)",
        "BQ": "Bonaire, Sint Eustatius and Saba (BQ)",
        "BR": "Brazil (BR)",
        "BS": "Bahamas (BS)",
        "BT": "Bhutan (BT)",
        "BV": "Bouvet Island (BV)",
        "BW": "Botswana (BW)",
        "BY": "Belarus (BY)",
        "BZ": "Belize (BZ)",
        "CA": "Canada (CA)",
        "CC": "Cocos Islands (CC)",
        "CD": "DR Congo (CD)",
        "CF": "CF (CF)",
        "CG": "Republic of the Congo (CG)",
        "CH": "Switzerland (CH)",
        "CI": "Ivory Coast (CI)",
        "CK": "Cook Islands (CK)",
        "CL": "Chile (CL)",
        "CM": "Cameroon (CM)",
        "CN": "China (People's Republic of China) (CN)",
        "CO": "Colombia (CO)",
        "CR": "Costa Rica (CR)",
        "CU": "Cuba (CU)",
        "CV": "Cape Verde (CV)",
        "CW": "Curacao (CW)",
        "CX": "Christmas Island (CX)",
        "CY": "Cyprus (CY)",
        "CZ": "CZ (CZ)",
        "DE": "Germany (DE)",
        "DJ": "Djibouti (DJ)",
        "DK": "Denmark (DK)",
        "DM": "Dominica (DM)",
        "DO": "Dominican Republic (DO)",
        "DZ": "Algeria (DZ)",
        "EC": "Ecuador (EC)",
        "EE": "Estonia (EE)",
        "EG": "Egypt (EG)",
        "EH": "SADR (EH)",
        "ER": "Eritrea (ER)",
        "ES": "Spain (ES)",
        "ET": "Ethiopia (ET)",
        "FI": "Finland (FI)",
        "FJ": "Fiji (FJ)",
        "FK": "Falkland Islands (FK)",
        "FM": "Micronesia (FM)",
        "FO": "Faroes (FO)",
        "FR": "France (FR)",
        "GA": "Gabon (GA)",
        "GB": "Great Britain (GB)",
        "GD": "Grenada (GD)",
        "GE": "Georgia (GE)",
        "GF": "Guiana (GF)",
        "GG": "Guernsey (GG)",
        "GH": "Ghana (GH)",
        "GI": "Gibraltar (GI)",
        "GL": "Greenland (GL)",
        "GM": "Gambia (GM)",
        "GN": "Guinea (GN)",
        "GP": "Guadeloupe (GP)",
        "GQ": "Equatorial Guinea (GQ)",
        "GR": "Greece (GR)",
        "GS": "South Georgia and the South Sandwich Islands (GS)",
        "GT": "Guatemala (GT)",
        "GU": "Guam (GU)",
        "GW": "Guinea-Bissau (GW)",
        "GY": "Guyana (GY)",
        "HK": "Hong Kong (HK)",
        "HM": "Heard and McDonald (HM)",
        "HN": "Honduras (HN)",
        "HR": "Croatia (HR)",
        "HT": "Haiti (HT)",
        "HU": "Hungary (HU)",
        "ID": "Indonesia (ID)",
        "IE": "Flag of Ireland Ireland (IE)",
        "IL": "Israel (IL)",
        "IM": "Isle of Man (IM)",
        "IN": "India India (IN)",
        "IO": "British Indian Ocean Territory (IO)",
        "IQ": "Iraq (IQ)",
        "IR": "Iran (IR)",
        "IS": "Iceland (IS)",
        "IT": "Italy (IT)",
        "JE": "Jersey (JE)",
        "JM": "Jamaica (JM)",
        "JO": "Jordan (JO)",
        "JP": "Japan (JP)",
        "KE": "Kenya (KE)",
        "KG": "Kyrgyzstan (KG)",
        "KH": "Cambodia (KH)",
        "KI": "Kiribati (KI)",
        "KM": "Comoros (KM)",
        "KN": "Saint Kitts and Nevis (KN)",
        "KP": "DPRK (Democratic People's Republic of Korea) (KP)",
        "KR": "Republic of Korea (KR)",
        "KW": "Kuwait (KW)",
        "KY": "Cayman Islands (KY)",
        "KZ": "Kazakhstan (KZ)",
        "LA": "Laos (LA)",
        "LB": "Lebanon (LB)",
        "LC": "Saint Lucia (LC)",
        "LI": "Liechtenstein (LI)",
        "LK": "Sri Lanka (LK)",
        "LR": "Liberia (LR)",
        "LS": "Lesotho (LS)",
        "LT": "Lithuania (LT)",
        "LU": "Luxembourg (LU)",
        "LV": "Latvia (LV)",
        "LY": "Libya (LY)",
        "MA": "Morocco (MA)",
        "MC": "Monaco (MC)",
        "MD": "Moldova (MD)",
        "ME": "Montenegro (ME)",
        "MF": "Saint Martin (MF)",
        "MG": "Madagascar (MG)",
        "MH": "Marshall Islands (MH)",
        "MK": "North Macedonia (MK)",
        "ML": "Mali (ML)",
        "MM": "Myanmar (MM)",
        "MN": "Mongolia (MN)",
        "MO": "Macau (MO)",
        "MP": "Northern Mariana Islands (MP)",
        "MQ": "Martinique (MQ)",
        "MR": "Mauritania (MR)",
        "MS": "Montserrat (MS)",
        "MT": "Malta (MT)",
        "MU": "Mauritius (MU)",
        "MV": "Maldives (MV)",
        "MW": "Malawi (MW)",
        "MX": "Mexico (MX)",
        "MY": "Malaysia (MY)",
        "MZ": "Mozambique (MZ)",
        "NA": "Namibia (NA)",
        "NC": "New Caledonia (NC)",
        "NE": "Niger (NE)",
        "NF": "Norfolk Island (NF)",
        "NG": "Nigeria (NG)",
        "NI": "Nicaragua (NI)",
        "NL": "Netherlands (NL)",
        "NO": "Norway (NO)",
        "NP": "Nepal (NP)",
        "NR": "Nauru (NR)",
        "NU": "Niue (NU)",
        "NZ": "New Zealand (NZ)",
        "OM": "Oman (OM)",
        "PA": "Panama (PA)",
        "PE": "Peru (PE)",
        "PF": "French Polynesia (PF)",
        "PG": "Papua New Guinea (PG)",
        "PH": "Philippines (PH)",
        "PK": "Pakistan (PK)",
        "PL": "Poland (PL)",
        "PM": "Saint Pierre and Miquelon (PM)",
        "PN": "Pitcairn (PN)",
        "PR": "Puerto Rico (PR)",
        "PS": "State of Palestine (PS)",
        "PT": "Portugal (PT)",
        "PW": "Palau (PW)",
        "PY": "Paraguay (PY)",
        "QA": "Qatar (QA)",
        "RE": "Reunion (RE)",
        "RO": "Romania (RO)",
        "RS": "Serbia (RS)",
        "RU": "Russia (RU)",
        "RW": "Rwanda (RW)",
        "SA": "Saudi Arabia (SA)",
        "SB": "Solomon Islands (SB)",
        "SC": "Seychelles (SC)",
        "SD": "Sudan (SD)",
        "SE": "Sweden (SE)",
        "SG": "Singapore (SG)",
        "SH": "Saint Helena, Ascension and Tristan da Cunha (SH)",
        "SI": "Slovenia (SI)",
        "SJ": "Flag of Svalbard and Jan Mayen Svalbard and Jan Mayen (SJ)",
        "SK": "Slovakia (SK)",
        "SL": "Sierra Leone (SL)",
        "SM": "San Marino (SM)",
        "SN": "Senegal (SN)",
        "SO": "Somalia (SO)",
        "SR": "Suriname (SR)",
        "SS": "South Sudan (SS)",
        "ST": "Sao Tome and Principe (ST)",
        "SV": "El Salvador (SV)",
        "SX": "Sint Maarten (SX)",
        "SY": "Syria (SY)",
        "SZ": "Eswatini (SZ)",
        "TC": "Turks and Caicos (TC)",
        "TD": "Chad (TD)",
        "TF": "French Southern and Antarctic Territories (TF)",
        "TG": "Togo (TG)",
        "TH": "Thailand (TH)",
        "TJ": "Tajikistan (TJ)",
        "TK": "Tokelau (TK)",
        "TL": "East Timor (TL)",
        "TM": "Turkmenistan (TM)",
        "TN": "Tunisia (TN)",
        "TO": "Tonga (TO)",
        "TR": "Turkey (TR)",
        "TT": "Trinidad and Tobago (TT)",
        "TV": "Tuvalu (TV)",
        "TW": "Republic of China (TW)",
        "TZ": "Tanzania (TZ)",
        "UA": "Ukraine (UA)",
        "UG": "Uganda (UG)",
        "UM": "Minor Outlying Islands (US) (UM)",
        "US": "US (US)",
        "UY": "Uruguay (UY)",
        "UZ": "Uzbekistan (UZ)",
        "VA": "Vatican City (VA)",
        "VC": "Saint Vincent and the Grenadines (VC)",
        "VE": "Venezuela (VE)",
        "VG": "Virgin Islands (UK) (VG)",
        "VI": "U.S. Virgin Islands (VI)",
        "VN": "Vietnam (VN)",
        "VU": "Vanuatu (VU)",
        "WF": "Wallis and Futuna (WF)",
        "WS": "Samoa (WS)",
        "XK": "1 (XK)",
        "YE": "Yemen (YE)",
        "YT": "Mayotte (YT)",
        "ZA": "South Africa (ZA)",
        "ZM": "Zambia (ZM)",
        "ZW": "Zimbabwe (ZW)",
        "EU": "EU Flag European Union (EU)"
    }
}

export const COUNTRIES =  {
    "AD": "Andorra (AD)",
    "AE": "UAE (AE)",
    "AF": "Afghanistan (AF)",
    "AG": "Antigua and Barbuda (AG)",
    "AI": "Anguilla (AI)",
    "AL": "Albania (AL)",
    "AM": "Armenia (AM)",
    "AO": "Angola (AO)",
    "AQ": "Antarctica (AQ)",
    "AR": "Argentina (AR)",
    "AS": "American Samoa (AS)",
    "AT": "Austria (AT)",
    "AU": "Australia (AU)",
    "AW": "Aruba (AW)",
    "AX": "Aland Islands (AX)",
    "AZ": "Azerbaijan (AZ)",
    "BA": "Bosnia and Herzegovina (BA)",
    "BB": "Barbados (BB)",
    "BD": "Bangladesh (BD)",
    "BE": "Belgium (BE)",
    "BF": "Burkina Faso (BF)",
    "BG": "Bulgaria (BG)",
    "BH": "Bahrain (BH)",
    "BI": "Burundi (BI)",
    "BJ": "Benin (BJ)",
    "BL": "Saint Barthelemy (BL)",
    "BM": "Bermuda (BM)",
    "BN": "Brunei (BN)",
    "BO": "Bolivia (BO)",
    "BQ": "Bonaire, Sint Eustatius and Saba (BQ)",
    "BR": "Brazil (BR)",
    "BS": "Bahamas (BS)",
    "BT": "Bhutan (BT)",
    "BV": "Bouvet Island (BV)",
    "BW": "Botswana (BW)",
    "BY": "Belarus (BY)",
    "BZ": "Belize (BZ)",
    "CA": "Canada (CA)",
    "CC": "Cocos Islands (CC)",
    "CD": "DR Congo (CD)",
    "CF": "CF (CF)",
    "CG": "Republic of the Congo (CG)",
    "CH": "Switzerland (CH)",
    "CI": "Ivory Coast (CI)",
    "CK": "Cook Islands (CK)",
    "CL": "Chile (CL)",
    "CM": "Cameroon (CM)",
    "CN": "China (People's Republic of China) (CN)",
    "CO": "Colombia (CO)",
    "CR": "Costa Rica (CR)",
    "CU": "Cuba (CU)",
    "CV": "Cape Verde (CV)",
    "CW": "Cura\u00e7ao (CW)",
    "CX": "Christmas Island (CX)",
    "CY": "Cyprus (CY)",
    "CZ": "CZ (CZ)",
    "DE": "Germany (DE)",
    "DJ": "Djibouti (DJ)",
    "DK": "Denmark (DK)",
    "DM": "Dominica (DM)",
    "DO": "Dominican Republic (DO)",
    "DZ": "Algeria (DZ)",
    "EC": "Ecuador (EC)",
    "EE": "Estonia (EE)",
    "EG": "Egypt (EG)",
    "EH": "SADR (EH)",
    "ER": "Eritrea (ER)",
    "ES": "Spain (ES)",
    "ET": "Ethiopia (ET)",
    "FI": "Finland (FI)",
    "FJ": "Fiji (FJ)",
    "FK": "Falkland Islands (FK)",
    "FM": "Micronesia (FM)",
    "FO": "Faroes (FO)",
    "FR": "France (FR)",
    "GA": "Gabon (GA)",
    "GB": "Great Britain (GB)",
    "GD": "Grenada (GD)",
    "GE": "Georgia (GE)",
    "GF": "Guiana (GF)",
    "GG": "Guernsey (GG)",
    "GH": "Ghana (GH)",
    "GI": "Gibraltar (GI)",
    "GL": "Greenland (GL)",
    "GM": "Gambia (GM)",
    "GN": "Guinea (GN)",
    "GP": "Guadeloupe (GP)",
    "GQ": "Equatorial Guinea (GQ)",
    "GR": "Greece (GR)",
    "GS": "South Georgia and the South Sandwich Islands (GS)",
    "GT": "Guatemala (GT)",
    "GU": "Guam (GU)",
    "GW": "Guinea-Bissau (GW)",
    "GY": "Guyana (GY)",
    "HK": "Hong Kong (HK)",
    "HM": "Heard and McDonald (HM)",
    "HN": "Honduras (HN)",
    "HR": "Croatia (HR)",
    "HT": "Haiti (HT)",
    "HU": "Hungary (HU)",
    "ID": "Indonesia (ID)",
    "IE": "Flag of Ireland Ireland (IE)",
    "IL": "Israel (IL)",
    "IM": "Isle of Man (IM)",
    "IN": "India India (IN)",
    "IO": "British Indian Ocean Territory (IO)",
    "IQ": "Iraq (IQ)",
    "IR": "Iran (IR)",
    "IS": "Iceland (IS)",
    "IT": "Italy (IT)",
    "JE": "Jersey (JE)",
    "JM": "Jamaica (JM)",
    "JO": "Jordan (JO)",
    "JP": "Japan (JP)",
    "KE": "Kenya (KE)",
    "KG": "Kyrgyzstan (KG)",
    "KH": "Cambodia (KH)",
    "KI": "Kiribati (KI)",
    "KM": "Comoros (KM)",
    "KN": "Saint Kitts and Nevis (KN)",
    "KP": "DPRK (Democratic People's Republic of Korea) (KP)",
    "KR": "Republic of Korea (KR)",
    "KW": "Kuwait (KW)",
    "KY": "Cayman Islands (KY)",
    "KZ": "Kazakhstan (KZ)",
    "LA": "Laos (LA)",
    "LB": "Lebanon (LB)",
    "LC": "Saint Lucia (LC)",
    "LI": "Liechtenstein (LI)",
    "LK": "Sri Lanka (LK)",
    "LR": "Liberia (LR)",
    "LS": "Lesotho (LS)",
    "LT": "Lithuania (LT)",
    "LU": "Luxembourg (LU)",
    "LV": "Latvia (LV)",
    "LY": "Libya (LY)",
    "MA": "Morocco (MA)",
    "MC": "Monaco (MC)",
    "MD": "Moldova (MD)",
    "ME": "Montenegro (ME)",
    "MF": "Saint Martin (MF)",
    "MG": "Madagascar (MG)",
    "MH": "Marshall Islands (MH)",
    "MK": "North Macedonia (MK)",
    "ML": "Mali (ML)",
    "MM": "Myanmar (MM)",
    "MN": "Mongolia (MN)",
    "MO": "Macau (MO)",
    "MP": "Northern Mariana Islands (MP)",
    "MQ": "Martinique (MQ)",
    "MR": "Mauritania (MR)",
    "MS": "Montserrat (MS)",
    "MT": "Malta (MT)",
    "MU": "Mauritius (MU)",
    "MV": "Maldives (MV)",
    "MW": "Malawi (MW)",
    "MX": "Mexico (MX)",
    "MY": "Malaysia (MY)",
    "MZ": "Mozambique (MZ)",
    "NA": "Namibia (NA)",
    "NC": "New Caledonia (NC)",
    "NE": "Niger (NE)",
    "NF": "Norfolk Island (NF)",
    "NG": "Nigeria (NG)",
    "NI": "Nicaragua (NI)",
    "NL": "Netherlands (NL)",
    "NO": "Norway (NO)",
    "NP": "Nepal (NP)",
    "NR": "Nauru (NR)",
    "NU": "Niue (NU)",
    "NZ": "New Zealand (NZ)",
    "OM": "Oman (OM)",
    "PA": "Panama (PA)",
    "PE": "Peru (PE)",
    "PF": "French Polynesia (PF)",
    "PG": "Papua New Guinea (PG)",
    "PH": "Philippines (PH)",
    "PK": "Pakistan (PK)",
    "PL": "Poland (PL)",
    "PM": "Saint Pierre and Miquelon (PM)",
    "PN": "Pitcairn (PN)",
    "PR": "Puerto Rico (PR)",
    "PS": "State of Palestine (PS)",
    "PT": "Portugal (PT)",
    "PW": "Palau (PW)",
    "PY": "Paraguay (PY)",
    "QA": "Qatar (QA)",
    "RE": "Reunion (RE)",
    "RO": "Romania (RO)",
    "RS": "Serbia (RS)",
    "RU": "Russia (RU)",
    "RW": "Rwanda (RW)",
    "SA": "Saudi Arabia (SA)",
    "SB": "Solomon Islands (SB)",
    "SC": "Seychelles (SC)",
    "SD": "Sudan (SD)",
    "SE": "Sweden (SE)",
    "SG": "Singapore (SG)",
    "SH": "Saint Helena, Ascension and Tristan da Cunha (SH)",
    "SI": "Slovenia (SI)",
    "SJ": "Flag of Svalbard and Jan Mayen Svalbard and Jan Mayen (SJ)",
    "SK": "Slovakia (SK)",
    "SL": "Sierra Leone (SL)",
    "SM": "San Marino (SM)",
    "SN": "Senegal (SN)",
    "SO": "Somalia (SO)",
    "SR": "Suriname (SR)",
    "SS": "South Sudan (SS)",
    "ST": "Sao Tome and Principe (ST)",
    "SV": "El Salvador (SV)",
    "SX": "Sint Maarten (SX)",
    "SY": "Syria (SY)",
    "SZ": "Eswatini (SZ)",
    "TC": "Turks and Caicos (TC)",
    "TD": "Chad (TD)",
    "TF": "French Southern and Antarctic Territories (TF)",
    "TG": "Togo (TG)",
    "TH": "Thailand (TH)",
    "TJ": "Tajikistan (TJ)",
    "TK": "Tokelau (TK)",
    "TL": "East Timor (TL)",
    "TM": "Turkmenistan (TM)",
    "TN": "Tunisia (TN)",
    "TO": "Tonga (TO)",
    "TR": "Turkey (TR)",
    "TT": "Trinidad and Tobago (TT)",
    "TV": "Tuvalu (TV)",
    "TW": "Republic of China (TW)",
    "TZ": "Tanzania (TZ)",
    "UA": "Ukraine (UA)",
    "UG": "Uganda (UG)",
    "UM": "Minor Outlying Islands (US) (UM)",
    "US": "US (US)",
    "UY": "Uruguay (UY)",
    "UZ": "Uzbekistan (UZ)",
    "VA": "Vatican City (VA)",
    "VC": "Saint Vincent and the Grenadines (VC)",
    "VE": "Venezuela (VE)",
    "VG": "Virgin Islands (UK) (VG)",
    "VI": "U.S. Virgin Islands (VI)",
    "VN": "Vietnam (VN)",
    "VU": "Vanuatu (VU)",
    "WF": "Wallis and Futuna (WF)",
    "WS": "Samoa (WS)",
    "XK": "1 (XK)",
    "YE": "Yemen (YE)",
    "YT": "Mayotte (YT)",
    "ZA": "South Africa (ZA)",
    "ZM": "Zambia (ZM)",
    "ZW": "Zimbabwe (ZW)",
    "EU": "EU Flag European Union (EU)"
}